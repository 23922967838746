import * as React from 'react';
import Sheet from 'react-modal-sheet';
import { IBottomSheetProps } from './types';
import styles from './styles.module.scss';
import classnames from 'classnames';
import {
  setBaseColorsById,
  setCustomColors,
  setIllustrationColors,
  setTextColor,
} from '@/utils/cssVars';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { makeSelectEventById } from '@/models/event';
import { useParams } from 'react-router-dom';
import { CloseIcon } from './icons';
import useLockBodyScroll from '@/hooks/use-lock-body-scroll';

const BottomSheet = React.forwardRef((props: IBottomSheetProps, ref) => {
  const {
    onOpenStart,
    onOpenEnd,
    className,
    isOpen,
    onSnap,
    onClose,
    initialSnap,
    snapPoints,
    showCloseButton,
    onCloseClick,
    children,
    backdropStyle,
    onBackdropTouch,
    showDragIndicator = true,
    currentSnapPoint,
    disableDrag = true,
    id,
    sheetContentStyle = {},
  } = props;

  const { eventId = '' } = useParams<{ eventId: string }>();

  const event = useMemoizedSelector(makeSelectEventById, eventId);

  useLockBodyScroll(isOpen);

  const bottomSheetId = React.useMemo(() => {
    if (!event || event?.colorTheme === 'DARK') return 'bottomSheetWrapperDark';
    if (event?.colorTheme === 'LIGHT') return 'bottomSheetWrapperLight';
    return id;
  }, [event]);
  React.useEffect(() => {
    if (ref?.current) {
      const { primaryColor, secondaryColor, colorTheme } = event;
      if (colorTheme === 'CUSTOM') {
        setCustomColors(primaryColor, secondaryColor, id);
        setTextColor(primaryColor, id);
        //TODO
        setIllustrationColors(colorTheme, primaryColor, bottomSheetId);
      } else {
        setBaseColorsById(primaryColor, secondaryColor, bottomSheetId);
        setIllustrationColors(colorTheme, primaryColor, bottomSheetId);
      }
    }
  }, [ref]);

  return (
    <Sheet
      id={bottomSheetId}
      onOpenStart={onOpenStart}
      onOpenEnd={onOpenEnd}
      className={classnames(className, styles.sheetWrapper)}
      ref={ref}
      isOpen={isOpen}
      onSnap={onSnap}
      onClose={onClose}
      initialSnap={initialSnap}
      snapPoints={snapPoints}
      disableDrag={disableDrag}
    >
      <Sheet.Container>
        <div
          className={styles.sheetContainer}
          style={{
            '--primary-base': document
              .getElementById('bottomSheetWrapper')
              ?.style.getPropertyValue('--primary-base'),
            '--secondary-base': document
              .getElementById('bottomSheetWrapper')
              ?.style.getPropertyValue('--secondary-base'),
          }}
        >
          {showCloseButton && currentSnapPoint === 0 && (
            <div className={styles.floatingCloseButtonWrapper}>
              <button
                className={styles.floatingCloseButton}
                type="button"
                onClick={onCloseClick && onCloseClick}
                title="close"
              >
                <CloseIcon />
              </button>
            </div>
          )}
          {showDragIndicator && (
            <Sheet.Header>
              <div className={styles.bottomSheetHeader}>
                {!disableDrag && (
                  <div tabIndex={0} className={styles.dragIndicator}>
                    <div className={styles.leftArr} />
                    <div className={styles.rightArr} />
                  </div>
                )}
                {showCloseButton && (
                  <div className={styles.closeButtonWrapper}>
                    <button
                      type="button"
                      onClick={onCloseClick && onCloseClick}
                      title="close"
                    >
                      <div>
                        <CloseIcon />
                      </div>
                    </button>
                  </div>
                )}
              </div>
            </Sheet.Header>
          )}
          <Sheet.Content style={{ marginTop: 10, minHeight: "0px", ...sheetContentStyle }}>
            {children}
            </Sheet.Content>
        </div>
      </Sheet.Container>
      <Sheet.Backdrop onTap={onBackdropTouch} style={backdropStyle} />
    </Sheet>
  );
});

export default React.memo(BottomSheet);
