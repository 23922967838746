import { IFormFieldTypes, IFormFieldRow } from '@/types/form';
import validations from '@/validations';
import { MediaWidgetTypes } from './types';

const { required } = validations;

const MEDIA_TYPE_OPTIONS = [
  {
    label: 'Image',
    value: MediaWidgetTypes.IMAGE,
  },
  {
    label: 'Video',
    value: MediaWidgetTypes.VIDEO,
  },
  {
    label: 'GIF',
    value: MediaWidgetTypes.GIF,
  },
];

export const MEDIA_WIDGET_SETTINGS_FORM_FIELD = [
  {
    fields: [
      {
        name: 'type',
        label: 'Type',
        fieldType: IFormFieldTypes.SELECT,
        widthRatio: 1,
        validate: required,
        data: MEDIA_TYPE_OPTIONS,
      },
    ],
  },
  {
    fields: [
      {
        name: 'src',
        label: 'Url',
        fieldType: IFormFieldTypes.INPUT,
        widthRatio: 1,
        validate: required,
      },
    ],
  },
] as IFormFieldRow[];
