import React, { useEffect, useMemo } from 'react';
import TextWidgetSettings from './settings';
// styles
import styles from './styles.module.scss';
import Parser from 'html-react-parser';

const TextWidget = ({ displayText, sizeStyles }) => {
  const configValues = displayText?.reduce((final: any = {}, current: any) => ({
    ...final,
    ...current,
  }));
  useEffect(() => {
    if (!configValues?.fontFamily) {
      return;
    }
    // Downloading selected font family
    const fontFamilyUrl = configValues.fontFamily.split(' ').join('+');
    const link = document.getElementById('embeddable-font-link');
    if (link) link.remove();
    const newLink = document.createElement('link');
    newLink.rel = 'stylesheet';
    newLink.id = 'google-font-link';
    newLink.href = `https://fonts.googleapis.com/css?family=${fontFamilyUrl}&display=swap`;
    document.head.appendChild(newLink);
  }, [configValues]);

  const fontWeight = useMemo(() => {
    if (!configValues) {
      return 'normal';
    }
    if (configValues?.fontWeight === 'regular') {
      return 'normal';
    }
    if (configValues?.fontWeight === 'medium') {
      return '500';
    }
    if (configValues?.fontWeight === 'semibold') {
      return '600';
    }
    return configValues?.fontWeight;
  }, [configValues]);

  if (!configValues) {
    return null;
  }

  return (
    <div
      className={styles.textContainer}
      style={{
        backgroundColor: `${configValues?.backgroundColour}`,
        justifyContent: `${configValues?.textAlign}`,
        textAlign: configValues?.textAlign || 'left', // left is the default value
      }}
    >
      <label
        style={{
          color: `${configValues?.textColour}`,
          fontSize: `calc(${configValues?.fontSize}px * ${sizeStyles.widthScaleFactor})`,
          fontFamily: `${configValues?.fontFamily}`,
          fontWeight: `${fontWeight}`,
          '--min-height-scaled': `calc(22px * ${sizeStyles.widthScaleFactor})`,
        }}
        className={styles.widgetText}
      >
        {Parser(configValues?.text)}
      </label>
    </div>
  );
};

TextWidget.Settings = TextWidgetSettings;
export default TextWidget;
