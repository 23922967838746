// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__hotspot___34fqF{width:100%;height:100%;cursor:pointer}.styles-module__imageButton___2XcGD{object-fit:contain}\n", ""]);
// Exports
exports.locals = {
	"hotspot": "styles-module__hotspot___34fqF",
	"imageButton": "styles-module__imageButton___2XcGD"
};
module.exports = exports;
