import React, { useState, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import {
  ChevronLeft,
  ChevronRight,
  MoreVertical,
  Calendar,
} from 'react-feather';
import { useSelector, useDispatch } from 'react-redux';
// components
import NewLabel, {
  INewLabelColors,
  INewLabelSizes,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import { IAttendeeMessageProps, IChatBoxParent } from './types';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import Image from '@/components/ui/media/Image';
import Button, {
  IButtonTypes,
  IButtonMargins,
} from '@/components/ui/buttons/Button';
import ProfilePicture from '@/components/ui/ProfilePicture';
import EngagementEmptyState from '@/components/EngagementEmptyState';
import ConversationMessage from '@/components/chat/ConversationMessage';
// config + styles + types
import styles from './styles.module.scss';
// helpers + hooks + models
import { _isEmpty } from '@/utils/jsHelpers/array';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import BusinessCard from '@/components/BusinessCard';
import api from '@/api';
import { useParams } from 'react-router';
import { monthList3Letter, prefixPicUrlIfNeeded } from '@/utils/helpers';
import { makeSelectEventById, makeSelectIsHost } from '@/models/event';
import { makeSelectUserAccountId, selectCurrentUser } from '@/models/account';
import { IContentColors, IGeneralSizeTypes } from '@/types';
import { getMessageTime } from '../ChatMessageV2/config';
import SelectFieldExternal from 'react-select';
import { usePusherChannelState } from '@/services/messaging/pusher/pusher-channel-context';
import {
  scheduledDateOptionsList,
  scheduledTimeOptionsList,
} from '@/pages/live-event/DiscussionListPage/ScheduleMeetingRoom/config';
import { getCssVar } from '@/utils/cssVars';
import { useUserChannelState } from '@/services/messaging/pusher/user-channel-context';
import ReactTooltip from 'react-tooltip';
import { selectUserPrivacySetting } from '@/models/global';
import { IUserPrivacyErrors } from '@/types/userPrivacySetting';
import { useUserAccessGroups } from '@/hooks/use-user-access-groups';
import ProfileModal, {
  EModalViews,
  TDefaultModalView,
} from '@/pages/live-event/SmartNetworkingPage/ProfileModal';
import useReactionListener from '../ChatMessageV2/hooks/useReactionListener';
import useWindowUnloadEffect from '@/hooks/use-window-unload-effect';
import useMessaging from '@/services/use-messaging';
import { useAtomValue } from 'jotai';
import { messageIdAtom } from '../ChatMessageV2/atoms';
import { AttendeeChannelEvent } from '@/types/messaging';

const selectFieldStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: getCssVar(IContentColors.NEUTRAL_MID_3),
  }),
  control: provided => ({
    ...provided,
    backgroundColor: getCssVar(IContentColors.NEUTRAL_BASE_1),
    border: `1px solid ${getCssVar(IContentColors.NEUTRAL_MID_3)}`,
  }),
  singleValue: provided => ({
    ...provided,
    color: getCssVar(IContentColors.MONOCHROME_CONTRAST),
  }),
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: '150px',
    overflow: 'auto',
  }),
};

const AttendeeMessage = (props: IAttendeeMessageProps) => {
  const {
    attendeeDetails,
    setChatSelected,
    messages,
    requestMeeting,
    setRequestMeeting,
    addAttendeeMessage,
    parent = IChatBoxParent.SIDE_PANEL,
    meetingsSettingsAndIntervalTime,
    showEmojiPicker,
    showGifPicker,
    ticketsDayWiseAccessList,
    meetingsShownMode = false,
    setMeetingSetupOpen,
    updateReactions,
  } = props;

  let ChatMessageComponent;
  const dispatch = useDispatch();
  const { eventId } = useParams<{ eventId: string }>();
  const [dateOptions, setDateOptions] = useState([]);
  const [timeOptions, setTimeOptions] = useState([]);
  const [selectedMeetingDate, setSelectedMeetingDate] = useState(null) as any;
  const [selectedMeetingTime, setSelectedMeetingTime] = useState(null) as any;
  const [allowedSlots, setAllowedSlots] = useState({}) as any;
  const userPrivacySetting = useSelector(selectUserPrivacySetting);
  const { isPrivateMeetingEnabled } = useUserAccessGroups();
  const [setupMeetingModalView, setSetupMeetingModalView] = useState<
    TDefaultModalView
  >();
  const { eventAttendeeChannel } = usePusherChannelState();
  const isHost = useMemoizedSelector(makeSelectIsHost, eventId);
  const [allowMessageReactions, setAllowMessageReactions] = useState(false);
  const currentMessageId = useAtomValue(messageIdAtom);
  const currentUserAccountId = useMemoizedSelector(makeSelectUserAccountId);

  const chatConversationChannelName = useMemo(() => {
    const firstMessage = messages[0];
    return firstMessage ? firstMessage.channelName : '';
  }, [messages]);

  const [
    _,
    chatConversationChannel,
    unSubscribeChatConversationChannel,
  ] = useMessaging(
    {
      messagingChannel: {
        attendeeChannel: chatConversationChannelName,
      },
    },
    isHost,
  );

  useEffect(() => {
    if (!selectedMeetingDate || !attendeeDetails?.accountId) {
      return;
    }
    api.segment
      .getAttendeeTimeSlots(
        eventId,
        attendeeDetails.accountId,
        Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        new Date(selectedMeetingDate.value),
      )
      .then(({ data }) => {
        setAllowedSlots(data);
      });
  }, [attendeeDetails, selectedMeetingDate]);

  const event = useMemoizedSelector(makeSelectEventById, eventId);
  useEffect(() => {
    if (!attendeeDetails?.accountId) {
      return;
    }
    api.accessGroups
      .getUserAccessDatesList(eventId, attendeeDetails.accountId)
      .then(({ data }) => {
        const dateOptionsList = scheduledDateOptionsList(
          event,
          monthList3Letter,
          data,
        );
        setDateOptions(dateOptionsList);
      });
  }, [attendeeDetails]);

  const currentUser = useSelector(selectCurrentUser);
  const handleBusinessCardSave = () => {
    api.event
      .saveBusinessCard({ eventId, accountId: attendeeDetails.accountId })
      .then(() => {
        dispatch({
          type: 'global/addSuccessToast',
          payload: { description: 'Successfully saved business card' },
        });
      });
  };

  let prevMessageDay = null;
  const showMessageDay = createdAt => {
    const currentMessageDay = getMessageTime(createdAt).split(' - ')[0];
    if (currentMessageDay !== prevMessageDay) {
      prevMessageDay = currentMessageDay;
      return currentMessageDay;
    }
    return null;
  };
  if (_isEmpty(messages.length)) {
    ChatMessageComponent = <EngagementEmptyState />;
  } else {
    ChatMessageComponent = messages.map(m => (
      <ConversationMessage
        key={m.messageId}
        message={m}
        showMessageDay={showMessageDay(m.createdAt)}
        allowMessageReactions={allowMessageReactions}
        reactionsListData={m.reactions}
        currentUserAccountId={currentUserAccountId}
        // chatBoxType={IChatBoxTypes.ATTENDEE}
      />
    ));
  }

  // TODO set up meeting related code needs to remove from this component completely. Meeting setup moved to common component.

  // get receiver privacy details
  const [
    receiverPrivateMeetingsMode,
    setReceiverPrivateMeetingsMode,
  ] = useState(true);
  useEffect(() => {
    if (!attendeeDetails.accountId) {
      return;
    }
    api.userPrivacySetting
      .getUserAccountPrivacySetting(eventId, attendeeDetails.accountId)
      .then(({ data }) => {
        setReceiverPrivateMeetingsMode(data?.privateMeetingsEnabled);
      });
  }, [attendeeDetails.accountId]);

  // User channel
  const { userChannel } = useUserChannelState();

  useEffect(() => {
    if (!(userChannel && attendeeDetails.accountId)) {
      return undefined;
    }
    const oneToOneChatListener = data => {
      const chatObject = JSON.parse(data);
      const message = chatObject.publishMessage;
      const { fromId } = chatObject;
      const { toId } = chatObject;
      if (
        toId == currentUser.accountId &&
        fromId == attendeeDetails.accountId
      ) {
        addAttendeeMessage(message);
      }
    };
    userChannel.bind('UPDATE_ONE_TO_ONE_CHAT', oneToOneChatListener);

    return () => {
      userChannel.unbind('UPDATE_ONE_TO_ONE_CHAT', oneToOneChatListener);
    };
  }, [userChannel, attendeeDetails.accountId]);

  const meetingRoomInvite = () => {
    setSetupMeetingModalView(EModalViews.BOOK_MEETING_ROOM);
    if (setMeetingSetupOpen) {
      setMeetingSetupOpen(true);
    }
  };

  const meetingRoomCancel = () => {
    setRequestMeeting(false);
    setChatSelected(null);
  };

  const createRoomBtn = () => {
    if (selectedMeetingDate == null) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Please Select Date' },
      });
      return;
    }
    if (selectedMeetingTime == null) {
      dispatch({
        type: 'global/addDangerToast',
        payload: { description: 'Please Select Time' },
      });
      return;
    }

    setRequestMeeting(false);

    const selectedDate = new Date(`${selectedMeetingDate.value}`);
    selectedDate.setHours(selectedMeetingTime.value.hours);
    selectedDate.setMinutes(selectedMeetingTime.value.minutes);
    selectedDate.setSeconds(0);

    const meetingRoom = {
      requestedAccountId: attendeeDetails.accountId,
      eventId: eventId,
      isScheduled: false,
      startTime: selectedDate,
      durationMins:
        (meetingsSettingsAndIntervalTime &&
          meetingsSettingsAndIntervalTime.durationMins) ||
        15,
    };
    api.meetingRoom
      .meetingRoomRequest(meetingRoom)
      .then(res => {
        dispatch({
          type: 'global/addSuccessToast',
          payload: { description: 'Meeting Requested' },
        });
        setRequestMeeting(false);
        setSelectedMeetingDate(null);
        setSelectedMeetingTime(null);
      })
      .catch(err => {
        let description = 'Oops something went wrong. Please try again';
        if (
          err?.response?.data?.message ===
          IUserPrivacyErrors.PRIVATE_MEETINGS_BLOCKED
        ) {
          description =
            'Meeting cannot be requested as the user has turned off private meetings';
        }
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: description,
          },
        });
      });
  };

  const headline = (() => {
    if (!attendeeDetails.designation && !attendeeDetails.company) {
      return '';
    }
    return [attendeeDetails.designation, attendeeDetails.company].join(' at ');
  })();

  const handleBackButton = () => {
    setChatSelected(null);
    setRequestMeeting(false);
  };
  useEffect(() => {
    const timeOptionsList = scheduledTimeOptionsList(allowedSlots);
    setTimeOptions(timeOptionsList);
  }, [allowedSlots]);

  const handleDateSelect = (input: any) => {
    setSelectedMeetingDate(input);
    setSelectedMeetingTime(null);
  };

  const handleTimeSelect = (input: any) => {
    setSelectedMeetingTime(input);
  };

  useReactionListener({
    channel: chatConversationChannel,
    messageId: currentMessageId,
    currentUserAccountId,
    pusherEvent: AttendeeChannelEvent.CHAT_REACTION,
    updateReactions,
    currentMessageId,
    messages,
  });

  useEffect(() => {
    if (!eventId) {
      return;
    }

    const getEventSettings = (cid: string) =>
      api.channel.getChannelSettingsByChannelId(cid).then(({ data }) => {
        setAllowMessageReactions(data?.hasMessageReactions);
      });

    api.channel.getConfigByRef(eventId).then(({ data }) => {
      getEventSettings(data.channelId);
    });
  }, [eventId, userPrivacySetting]);

  const eventChannelSettingListener = data => {
    try {
      const channelSetting = JSON.parse(data);
      setAllowMessageReactions(channelSetting.hasMessageReactions);
    } catch (e) {
      console.error('debugAttendeeMessage > eventChannelSettingListener > ', e);
    }
  };

  useEffect(() => {
    if (!eventAttendeeChannel) {
      return undefined;
    }
    eventAttendeeChannel.bind(
      AttendeeChannelEvent.UPDATE_CHANNEL_SETTING,
      eventChannelSettingListener,
    );

    return () => {
      eventAttendeeChannel.unbind(
        AttendeeChannelEvent.UPDATE_CHANNEL_SETTING,
        eventChannelSettingListener,
      );
    };
  }, [eventAttendeeChannel]);

  useWindowUnloadEffect(
    () => {
      unSubscribeChatConversationChannel();
    },
    false,
    [unSubscribeChatConversationChannel as never],
  );

  const MeetingRoomModalView = setupMeetingModalView && (
    <ProfileModal
      defaultView={setupMeetingModalView}
      details={{
        ...attendeeDetails,
        showMeetingButton: true,
        showChatButton: false,
        picUrl: attendeeDetails.picUrl
          ? prefixPicUrlIfNeeded(attendeeDetails.picUrl)
          : '',
      }}
      onCloseModal={() => setSetupMeetingModalView(undefined)}
      showTicketTagType={false}
      hideScheduleAndChat={false}
    />
  );

  const meetingWithText = `Setup a Meeting with ${attendeeDetails.firstName}`;
  return (
    <div className={styles.attendeeMainContainer}>
      {eventId != '14f0c0a0-ad83-4ea8-9dd3-f2fec517d974' ? (
        <div className={styles.attendeeSection}>
          <div className={styles.iconStyleHeader} onClick={handleBackButton}>
            <ChevronLeft size={15} />
          </div>
          <div className={styles.attendeeContainer}>
            <div className={styles.attendeeImage}>
              {attendeeDetails.picUrl != null ? (
                <Image
                  styleClass="attendeeMessageBoxContainerIcon"
                  src={prefixPicUrlIfNeeded(attendeeDetails.picUrl)}
                  alt={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                ></Image>
              ) : (
                <ProfilePicture
                  hexColor={attendeeDetails.hexColor}
                  name={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                  styleClass="attendeeMessageBoxContainerIcon"
                  // isLive={attendeeDetails.isLive}
                  isLive={false}
                  disableTooltip={true}
                  iconSize="attendeeMessageBoxContainerIcon"
                />
              )}
            </div>
            <div className={styles.attendeeName}>
              <div className={styles.fullName}>
                {attendeeDetails.firstName} {attendeeDetails.lastName}
              </div>
              {headline && <div className={styles.headline}>{headline}</div>}
            </div>
            {/* <div className={styles.attendeeStatus}>Online</div>  */}
          </div>
          <div className={styles.iconStyleVertical}>
            <MoreVertical size={15} />
          </div>
        </div>
      ) : (
        <div className={styles.attendeeBusinessSection}>
          <div
            className={styles.iconStyle}
            onClick={() => setChatSelected(null)}
          >
            <ChevronLeft />
          </div>
          <div className={styles.attendeeContainer}>
            <div className={styles.businessCardContainer}>
              <BusinessCard
                accountDetails={attendeeDetails}
                onSave={handleBusinessCardSave}
              />
            </div>
          </div>
          <div className={styles.iconStyleCal}>
            <MoreVertical />
          </div>
        </div>
      )}
      {requestMeeting ? (
        <div className={styles.attendeeSectionTwo}>
          <div className={styles.iconStyle}>
            <Calendar size={15} />
          </div>
          <div className={styles.meetingSetup}>
            <NewLabel
              text="Setting up a meeting..."
              labelColor={INewLabelColors.NEUTRAL_DARK}
              labelSize={INewLabelSizes.XSMALL}
              labelStyle={INewLabelStyles.DEFAULT}
            />
            <NewLabel
              text="Cancel"
              labelColor={INewLabelColors.DANGER}
              labelSize={INewLabelSizes.XSMALL}
              labelStyle={INewLabelStyles.DEFAULT}
              onClick={() => meetingRoomCancel()}
            />
          </div>
        </div>
      ) : (
        meetingsShownMode &&
        isPrivateMeetingEnabled(attendeeDetails.accessGroups) &&
        receiverPrivateMeetingsMode && (
          <div
            className={styles.attendeeSectionTwo}
            onClick={() => meetingRoomInvite()}
          >
            <div className={styles.iconStyle}>
              <Calendar size={15} />
            </div>
            <div
              className={classnames(
                styles.meetingSetup,
                styles.meetingNameWrapper,
              )}
            >
              {meetingWithText.length > 30 && (
                <ReactTooltip
                  id="meeting-name-label"
                  place="top"
                  effect="solid"
                >
                  {meetingWithText}
                </ReactTooltip>
              )}
              <NewLabel
                text={meetingWithText}
                labelColor={INewLabelColors.NEUTRAL_DARK}
                labelSize={INewLabelSizes.XSMALL}
                labelStyle={INewLabelStyles.DEFAULT}
                dataFor="meeting-name-label"
              />
            </div>
            <div className={styles.iconStyle}>
              <ChevronRight size={15} />
            </div>
          </div>
        )
      )}
      <div
        className={classnames(
          {
            [parent == IChatBoxParent.INBOX
              ? styles.inboxChatContainer
              : parent == IChatBoxParent.BOOTH_OWNER_WIDGET
              ? styles.boothOwnerChatContainer
              : styles.chatBoxMessageContainer]: !requestMeeting,
          },
          { [styles.chatBoxMessageContainerFull]: requestMeeting },
        )}
      >
        {requestMeeting ? (
          <div>
            <div className={styles.datetimeblock}>
              <NewLabel
                text="Choose Day"
                labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                labelSize={INewLabelSizes.XSMALL}
                labelStyle={INewLabelStyles.SEMI_BOLDED}
              />
              <SelectFieldExternal
                options={dateOptions}
                styles={selectFieldStyles}
                onChange={handleDateSelect}
                value={selectedMeetingDate}
              />
            </div>

            <div className={styles.datetimeblock}>
              <NewLabel
                text="Choose Time"
                labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                labelSize={INewLabelSizes.XSMALL}
                labelStyle={INewLabelStyles.SEMI_BOLDED}
              />
              <SelectFieldExternal
                options={timeOptions}
                styles={selectFieldStyles}
                onChange={handleTimeSelect}
                value={selectedMeetingTime}
              />
            </div>

            <div className={styles.datetimeblock}>
              <NewLabel
                text="Send Invite to:"
                labelColor={INewLabelColors.NEUTRAL_MEDIUM}
                labelSize={INewLabelSizes.XSMALL}
                labelStyle={INewLabelStyles.SEMI_BOLDED}
              />
              <div className={styles.attendeeInviteContainer}>
                <div className={styles.attendeeImage}>
                  {attendeeDetails.picUrl != null ? (
                    <Image
                      styleClass="profilePicChatBox"
                      src={attendeeDetails.picUrl}
                      alt={`Profile photo of ${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                    ></Image>
                  ) : (
                    <ProfilePicture
                      hexColor={attendeeDetails.hexColor}
                      name={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                      styleClass="small"
                      // isLive={attendeeDetails.isLive}
                      isLive={false}
                      disableTooltip={true}
                      iconSize={IGeneralSizeTypes.SMALL}
                    />
                  )}
                </div>
                <div className={styles.attendeeName}>
                  <NewLabel
                    text={`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}
                    labelColor={INewLabelColors.NEUTRAL_DARKEST}
                    labelSize={INewLabelSizes.XSMALL}
                    labelStyle={INewLabelStyles.SEMI_BOLDED}
                  />
                  {attendeeDetails && attendeeDetails.headline && (
                    <NewLabel
                      text={attendeeDetails.headline}
                      labelColor={INewLabelColors.NEUTRAL_DARK}
                      labelSize={INewLabelSizes.XXSMALL}
                      labelStyle={INewLabelStyles.DEFAULT}
                    />
                  )}
                </div>
              </div>
            </div>

            <Button
              type="button"
              styleClass={IButtonTypes.NEW_DANGER}
              label="Send Invite"
              margin={IButtonMargins.NO_MARGIN}
              className={styles.createRoomBtn}
              onClick={() => createRoomBtn()}
            />
          </div>
        ) : (
          <div>
            <div className={styles.messageListPlaceholder}>
              <Text
                text={
                  <div className={styles.chatStartTextContainer}>
                    <div className={styles.normalText}>
                      Your conversation with
                    </div>
                    <div className={styles.normalText}>
                      <b>{`${attendeeDetails.firstName} ${attendeeDetails.lastName}`}</b>{' '}
                      begins here
                    </div>
                  </div>
                }
                textSize={ITextSizes.XXSMALL}
                textColor={ITextColors.NEUTRAL}
                className={styles.messageListPlaceholderText}
              />
            </div>
            <div className={styles.messagesList}>{ChatMessageComponent}</div>
          </div>
        )}
      </div>
      {MeetingRoomModalView}
    </div>
  );
};

export default React.memo(
  AttendeeMessage,
  (prevProps, nextProps) => !!nextProps.meetingSetupOpen,
);
