
export enum ILinkType {
  INTERNAL = "internal",
  EXTERNAL = "external"
};

export enum IButtonType {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  CHOOSE_IMAGE = "CHOOSE_IMAGE",
  HOTSPOT = "HOTSPOT"
};

export interface IPropsTypesButtonWidget {
  buttonWidget: any,
  setAddHotSpot: any,
  setAddButton: any,
  setSelectedTab: any,
  setWidgetType: any,
  addWidget: any,
  layoutId? : any,
  setButtonWidgetType?: any,
}