import React from 'react';
import Ticker from '@/components/ui/Ticker';

const TickerWidget = ({ displayText }) => {
  return (
    <Ticker list={displayText} />
  );
};

export default TickerWidget;
