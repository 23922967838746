import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
// components
import StreamWidgetInner from './StreamWidgetInner';
// api + models + helpers
import { makeSelectEventUserRole } from '@/models/event';
import { makeSelectEventById } from '@/models/event';
import { _isEmpty } from '@/utils/jsHelpers/obj';

const StreamWidget = props => {
  const dispatch = useDispatch();
  const { eventId = '' } = props;
  const event = useMemoizedSelector(makeSelectEventById, eventId);
  const eventRole = useMemoizedSelector(makeSelectEventUserRole, eventId);

  useEffect(() => {
    if (_isEmpty(event)) {
      dispatch({
        type: 'event/getById',
        payload: {
          eventId: eventId,
        },
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (eventRole) {
      dispatch({
        type: 'event/getEventRole',
        payload: {
          eventId,
        },
      });
    }
  }, [eventRole, eventId]);

  return (
    <StreamWidgetInner {...props} />
  );
};

export default StreamWidget;