import React, { useState, useEffect } from 'react';
// components
import ImmersiveManualLayout from './ImmersiveComponent';
import NormalManualLayout from './NormalComponent';
// configs + types
import { IManualLayoutParents } from './types';
import { EmbeddedBoothType } from '@/models/event/types';
import BoothOwnerWidget from '../../widgets/BoothOwnerWidget';
import ProfileModal, {
  EModalViews,
  TDefaultModalView,
} from '@/pages/live-event/SmartNetworkingPage/ProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import { makeIsHideContactUsWidget } from '@/models/event';
import { prefixPicUrlIfNeeded } from '@/utils/helpers';
import { useMediaQuery } from 'react-responsive';

const ManualLayout = ({
  isChatOpen,
  config: CONFIG,
  parent = IManualLayoutParents.OTHER,
  widgetDetails,
  layoutDetails,
  zoomedIn,
  setZoomedIn,
  booth,
  showContactUsWidget,
}) => {
  const [boothType, setBoothType] = useState(EmbeddedBoothType.NORMAL);
  const dispatch = useDispatch();
  const isHideContactUsWidget = useSelector(makeIsHideContactUsWidget);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  useEffect(() => {
    if (!booth) return;

    const type = booth.boothType === EmbeddedBoothType.IMMERSIVE ?
      EmbeddedBoothType.IMMERSIVE : EmbeddedBoothType.NORMAL;
    setBoothType(type);
  }, [booth]);

  const [showBoothOwners, setShowBoothOwners] = useState(false);
  const [boothOwnerViewTitle, setBoothOwnerViewTitle] = useState('');
  const handleShowBoothOwners = title => {
    setBoothOwnerViewTitle(title);
    const newState = !showBoothOwners;
    setShowBoothOwners(newState);
    if (newState) {
      // hiding all other side panel bars
      dispatch({
        type: 'event/hideChannelSideBar',
        payload: { isHideChannelSidePanelBar: true },
      });
      dispatch({
        type: 'event/hideMyInboxSidePanelBar',
        payload: { isHideMyInboxSidePanelBar: true },
      });
      dispatch({
        type: 'event/hideNotificationSidePanelBar',
        payload: { isHideNotificationSidePanelBar: true },
      });
    }
  };

  useEffect(() => {
    if (isHideContactUsWidget && showBoothOwners) {
      setShowBoothOwners(false);
    }
    dispatch({
      type: 'event/hideContactUsWidget',
      payload: { isHideContactUsWidget: false },
    });
  }, [isHideContactUsWidget]);

  const [setupMeetingModalView, setSetupMeetingModalView] = useState<
    TDefaultModalView
  >();
  const [attendeeDetails, setAttendeeDetails] = useState({}) as any;
  const handleMeetingClick = attendee => {
    setAttendeeDetails(attendee);
    setSetupMeetingModalView(EModalViews.BOOK_MEETING_ROOM);
  };

  const MeetingRoomModalView = setupMeetingModalView && (
    <ProfileModal
      defaultView={setupMeetingModalView}
      details={{
        ...attendeeDetails,
        showMeetingButton: true,
        showChatButton: false,
        picUrl: attendeeDetails.picUrl
          ? prefixPicUrlIfNeeded(attendeeDetails.picUrl)
          : '',
      }}
      onCloseModal={() => setSetupMeetingModalView(undefined)}
      showTicketTagType={false}
      hideScheduleAndChat={false}
    />
  );

  const isMobileVerticalView = isMobile && layoutDetails?.hasMobViewEnabled;
  const showImmersiveLayout =
    boothType === EmbeddedBoothType.IMMERSIVE &&
    parent === IManualLayoutParents.EXPO &&
    !isMobileVerticalView;

  const showNormalManualLayout =
    boothType === EmbeddedBoothType.NORMAL ||
    parent !== IManualLayoutParents.EXPO ||
    isMobileVerticalView;

  return (
    <>
      {showImmersiveLayout && (
        <ImmersiveManualLayout
          isChatOpen={isChatOpen}
          config={CONFIG}
          parent={parent}
          widgetDetails={widgetDetails}
          layoutDetails={layoutDetails}
          zoomedIn={zoomedIn}
          setZoomedIn={setZoomedIn}
          handleShowBoothOwners={handleShowBoothOwners}
          showContactUsWidget={showContactUsWidget}
        />
      )}
      {showNormalManualLayout && (
        <NormalManualLayout
          isChatOpen={isChatOpen}
          config={CONFIG}
          parent={parent}
          widgetDetails={widgetDetails}
          layoutDetails={layoutDetails}
          handleShowBoothOwners={handleShowBoothOwners}
          showContactUsWidget={showContactUsWidget}
        />
      )}
      {showBoothOwners && (
        <BoothOwnerWidget
          title={boothOwnerViewTitle}
          onClose={handleShowBoothOwners}
          onHandleMeetingClick={handleMeetingClick}
          parent={parent}
        />
      )}
      {MeetingRoomModalView}
    </>
  );
};

export default ManualLayout;
export { IManualLayoutParents };
