import React, { useMemo, useRef, useState, useEffect, useCallback, useLayoutEffect } from 'react';
import styles from './styles.module.scss';
import useWindowDimensions from '@/hooks/use-window-dimensions';
import DeepARCamera from '@/components/DeepARCamera';
import { IButtonTypes } from '@/components/ui/buttons/Button';
import IconTextButton, { IIconTextButtonMarginTypes } from '@/components/ui/buttons/IconTextButton';

import { IUIIcons } from '@/types';
const {
  CAMERA,
  DOWNLOAD
} = IUIIcons;
const ArPhotoboothWidget = ({ baseWidth, baseHeight, effect }) => {

  const parentDims = useWindowDimensions();


  const canvasDims = useMemo(() => {
    if (!parentDims || !parentDims.height) {
      return;
    }

    const parentRatio = parentDims.width / parentDims.height;
    const canvasRatio = baseWidth / baseHeight;

    if (parentRatio > canvasRatio) {
      // container is wider
      const height = parentDims.height;
      const width = height * canvasRatio;
      const scaleFactor = height / baseHeight;
      return {
        height,
        width,
        scaleFactor
      };
    } else if (canvasRatio > parentRatio) {
      // image is wider
      const width = parentDims.width;
      const height = width * baseHeight / baseWidth;
      const scaleFactor = width / baseWidth;

      return {
        height,
        width,
        scaleFactor
      };
    } else {
      return {
        width: baseWidth,
        height: baseHeight,
        scaleFactor: 1
      };
    }
  }, [parentDims]);

  const [deepArRef, setDeepArRef] = useState();
  const deepArCallback = (innerRef) => {
    setDeepArRef(innerRef);
  };

  const [framedImage, setFramedImage] = useState();
  const onScreenshotTaken = (photo) => {
    setFramedImage(photo);
  };

  return (
    <div className={styles.parentContainer}>
      <div className={styles.innerContainer}>
        {canvasDims && <DeepARCamera onScreenshotTaken={onScreenshotTaken} refCallback={deepArCallback} effect={effect} hidden={framedImage} canvasWidth={canvasDims.width} canvasHeight={canvasDims.height} />}
        {framedImage && <img className={styles.framedImage} src={framedImage} />}
        {framedImage ? (
          <div className={styles.actionBar}>
            <div className={styles.actionBarContents}>
              <IconTextButton
                activeIcon={CAMERA}
                activeLabel="Retake"
                defaultLabel="Retake"
                defaultIcon={CAMERA}
                showDefault={true}
                onClick={() => {
                  setFramedImage(null);
                  if (!deepArRef || !deepArRef.current) {
                    return;
                  }
                  deepArRef.current.resume();
                }}
                buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
                buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
              />
              <a download="photobooth.png" href={framedImage}>
                <IconTextButton
                  activeIcon={DOWNLOAD}
                  activeLabel="Download"
                  defaultLabel="Download"
                  defaultIcon={DOWNLOAD}
                  showDefault={true}
                  buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
                  buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
                />
              </a>
            </div>
          </div>
        ) : (
            <div className={styles.actionBar}>
              <div className={styles.actionBarContents}>
                <IconTextButton
                  activeIcon={CAMERA}
                  activeLabel="Take Picture"
                  defaultLabel="Take Picture"
                  defaultIcon={CAMERA}
                  showDefault={true}
                  onClick={() => {
                    if (!deepArRef || !deepArRef.current) {
                      return;
                    }
                    deepArRef.current.takeScreenshot();
                    deepArRef.current.pause();
                  }}
                  buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
                  buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
                />
              </div>
            </div>
          )}
      </div>
    </div >
  );
};

export default ArPhotoboothWidget;
