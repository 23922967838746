import * as introJs from 'intro.js';
import 'intro.js/introjs.css';
import './intro-styles.css';
import api from '@/api';
import { IUIIcons } from '@/types';
const { CHAT, POLL, QA, RAISED_HANDS, FILES, PEOPLE } = IUIIcons;
const sidePanelButtons = [CHAT, POLL, QA, FILES, RAISED_HANDS, PEOPLE];

export const JOIN_BOOTH_FLOW = "JOIN_BOOTH_FLOW";

export const FLOW_STEPS = {
  EXPO: "navbar-menu-expo",
  BOOTH: "intro-element-booth",
}

export const getIntro = (onExitCallback, currentZoneConfig) => {
  const intro = introJs();
  const { name = 'Expo' } = currentZoneConfig || {};
  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    keyboardNavigation : false,
    showBullets: false,
    showProgress: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',

    steps: [
      {
        element: `#${FLOW_STEPS.EXPO}`,
        intro: `Welcome to ${name} Area! Let’s show you what we have here.`,
        position: 'bottom'
      },
      {
        element: `#${FLOW_STEPS.BOOTH}`,
        intro: "Select a booth to view!",
        position: 'right'
      },
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: JOIN_BOOTH_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    if (stepNumber > Object.keys(FLOW_STEPS).length) return;

    const updatedFlowState = { flowName: JOIN_BOOTH_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >=  Object.keys(FLOW_STEPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })
  
  return intro;
}

export const EXPO_BOOTH_FEATURES_FLOW = "EXPO_BOOTH_GO_TO_LIVE_FLOW";
export const EXPO_BOOTH_GO_TO_LIVE_EXPO_STEP = "expo_booth_go_to_live";
export const BOOTH_FLOW_STEPS = {
  CHAT: "stage-chat",
  POLLS: "stage-polls",
  Q7A: "stage-q7a",
  FILES : "stage-files",
  RAISEHAND: "stage-raisehand",
  PEOPLE: "stage-people",
}

export let getBoothGoToLiveIntro = (onExitCallback, channelSettings   , isGoToLive) => {
  const intro = introJs();

  var intro_steps = [];
  var isGoToLiveDiv = document.getElementById(EXPO_BOOTH_GO_TO_LIVE_EXPO_STEP);
  if(isGoToLive && isGoToLiveDiv){
    intro_steps.push( {
      element: `#${EXPO_BOOTH_GO_TO_LIVE_EXPO_STEP}`,
      intro: 'Host a live session on your booth to have a two-way conversation with visitors',
      position: 'right'
    });
  }

  sidePanelButtons.forEach(icon => {
    if (channelSettings[icon]) {
      switch (icon) {
        case CHAT:
          var chatDiv = document.getElementById(BOOTH_FLOW_STEPS.CHAT);
          if(!chatDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.CHAT}`,
            intro: "Enter a chat thread open to everyone on the event",
            position: 'left'
          });
          break;
        case POLL:
          var pollDiv = document.getElementById(BOOTH_FLOW_STEPS.POLLS);
          if(!pollDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.POLLS}`,
            intro: "Create live polls to capture inputs from other attendees in real time",
            position: 'left'
          });
          break;
        case QA:
          var qaDiv = document.getElementById(BOOTH_FLOW_STEPS.Q7A);
          if(!qaDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.Q7A}`,
            intro: 'You can ask questions to speakers on booth or upvote already asked questions',
            position: 'left'
          });
          break;
        case FILES:
          var filesDiv = document.getElementById(BOOTH_FLOW_STEPS.FILES);
          if(!filesDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.FILES}`,
            intro: 'Find all the relevant files here',
            position: 'left'
          });
          break;
        case RAISED_HANDS:
          var raiseHandDiv = document.getElementById(BOOTH_FLOW_STEPS.RAISEHAND);
          if(!raiseHandDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.RAISEHAND}`,
            intro: 'Raise hand allows attendee to join the speaker on booth and have a discussion or ask a question',
            position: 'left'
          });
          break;
        case PEOPLE:
          var peopleDiv = document.getElementById(BOOTH_FLOW_STEPS.PEOPLE);
          if(!peopleDiv)
             break
          intro_steps.push({
            element: `#${BOOTH_FLOW_STEPS.PEOPLE}`,
            intro: 'Explore the list of attendees on the event to hold a private conversation/meeting with them',
            position: 'left'
          });
          break;
      }
    }
  });

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel: 'Next &nbsp; &gt;',
    skipLabel: 'Skip',
    doneLabel: 'Done &nbsp; &check;',
    steps: intro_steps,
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: EXPO_BOOTH_FEATURES_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if (stepNumber > intro_steps.length) return;
    const updatedFlowState = { flowName: EXPO_BOOTH_FEATURES_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= intro_steps.length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}

export const EXPO_BOOTH_LIVE_STREAM_FLOW = 'EXPO_BOOTH_LEAVE_STREAM_FLOW';
export const EXPO_BOOTH_LIVE_STREAM_STEPS = {
  LIVE_STREAM: "expo_booth_leave_stream",
  BACK_TO_CONTENT: "expo_booth_back_to_content",
}

export let getBoothLiveStreamIntro = (onExitCallback, isHost) => {
  const intro = introJs();
  let intro_steps = [];
  intro_steps.push(
    {
      element: `#${EXPO_BOOTH_LIVE_STREAM_STEPS.BACK_TO_CONTENT}`,
      intro: 'Go back to your booth without ending the live session',
      position: 'top'
    }
  );
  if(isHost){
    intro_steps.push( {
      element: `#${EXPO_BOOTH_LIVE_STREAM_STEPS.LIVE_STREAM}`,
      intro: 'End the live session',
      position: 'top'
    });
  }
  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel: 'Next &nbsp; &gt;',
    skipLabel: 'Skip',
    doneLabel: 'Done &nbsp; &check;',
    steps: intro_steps,
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: EXPO_BOOTH_LIVE_STREAM_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    if (stepNumber > intro_steps.length) return;

    const updatedFlowState = { flowName: EXPO_BOOTH_LIVE_STREAM_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= intro_steps.length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}