import React from 'react';
// components
import { Form } from 'react-final-form';
import FormSection from '@/components/forms/FormSection';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
// config + styles + types
import { STREAM_WIDGET_SETTINGS_FORM_FIELDS } from './config';
import styles from './styles.module.scss';
import { IStreamWidgetSettingsProps } from './types';

const StreamWidgetSettings = ({ onSave, initialValues }: IStreamWidgetSettingsProps) => {
  return (
    <div className={styles.streamWidgetSettingsForm}>
      <Form onSubmit={onSave} initialValues={initialValues} render={({ handleSubmit, submitting, }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormSection
              formData={STREAM_WIDGET_SETTINGS_FORM_FIELDS}
            />
            <Button
              loading={submitting}
              label="Save"
              type="submit"
              styleClass={IButtonTypes.CONTRAST_PRIMARY}
            />
          </form>
        )
      }} />
    </div>
  );
};

export default StreamWidgetSettings;
