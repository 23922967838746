/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useReducer, useMemo } from 'react';
import { useParams } from 'react-router';
// Hooks + Components
import Image from '@/components/ui/media/Image';
import ProfilePicture from '@/components/ui/ProfilePicture';
import OneToOneChat from '@/components/chat/OneToOneChat';
// styles + types
import styles from './styles.module.scss';
import { IContentColors, IGeneralSizeTypes } from '@/types';
import { getCssVar } from '@/utils/cssVars';
import { X } from 'react-feather';
import { isEmpty } from 'lodash';
// api, models + services
import api from '@/api';
import produce from 'immer';
import { getImageUrlWithSize } from '@/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { IUserPrivacyErrors } from '@/types/userPrivacySetting';
import { CalendarPlus, LiveStatusIcon, SendChatIcon } from '../icons';
import { IChatBoxParent } from '@/components/chat/AttendeeMessage/types';
import { IChatMessageSender } from '@/components/chat/ChatMessageV2';
import { selectCurrentUser } from '@/models/account';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import { selectUserPrivacySetting } from '@/models/global';
import { makeSelectEventChannelSettings } from '@/models/event';
import { IEventChannelSettingsType } from '@/types/channel';
import { useUserAccessGroups } from '@/hooks/use-user-access-groups';

const PAGE_SECTIONS = {
  OWNERS_LIST: 'OWNERS_LIST',
  SELECTED_CHAT: 'SELECTED_CHAT',
};

function chatMessageReducer(state, action) {
  switch (action.type) {
    case 'setList':
      const { messageList } = action.payload;
      return produce(state, draft => {
        messageList.forEach(message => {
          if (!draft[message.messageId]) {
            draft.messageMap[message.messageId] = message;
            draft.messageIdList.push(message.messageId);
          }
        });
      });
    case 'add':
      const { message } = action.payload;
      return produce(state, draft => {
        if (!draft[message.messageId]) {
          draft.messageMap[message.messageId] = message;
          draft.messageIdList.push(message.messageId);
        }
      });
    case 'remove':
      return produce(state, draft => {
        draft.messageMap = [];
        draft.messageIdList = [];
      });

    case 'updateReactions':
      const { updatedReactions, messageId } = action.payload;
      return produce(state, draft => {
        if (draft.messageMap[messageId]) {
          draft.messageMap[messageId].reactions = updatedReactions;
        }
      });

    default:
      throw new Error();
  }
}

const BoothOwnerWidget = props => {
  const { eventId = '', boothId = '' } = useParams<{
    eventId: string;
    boothId: string;
  }>();
  const dispatch = useDispatch();
  const { title, onClose, onHandleMeetingClick, parent } = props;
  const [boothOwners, setBoothOwners] = useState([]) as any;
  const [viewSection, setViewSection] = useState(PAGE_SECTIONS.OWNERS_LIST);
  const [selectedOwner, setSelectedOwner] = useState(null) as any;
  const [requestMeeting, setRequestMeeting] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const userPrivacySetting = useSelector(selectUserPrivacySetting);
  const [currentUserMeetingsMode, setCurrentUserMeetingsMode] = useState(false);
  const [meetingsShownMode, setMeetingsShownMode] = useState(false);
  const eventChannelSettings: IEventChannelSettingsType = useSelector(
    makeSelectEventChannelSettings,
  );
  const {
    isPrivateChatEnabled,
    isPrivateMeetingEnabled,
  } = useUserAccessGroups();

  useEffect(() => {
    if (!eventId) {
      return;
    }
    api.segment.getMeetingIntervalTime(eventId).then(({ data }) => {
      if (!isEmpty(data) && !data.active) {
        setMeetingsShownMode(false);
      } else {
        setMeetingsShownMode(!isEmpty(data));
      }
      if (!isEmpty(data) && !data.userPrivateMeetingsEnabled) {
        setCurrentUserMeetingsMode(false);
      } else {
        setCurrentUserMeetingsMode(!isEmpty(data));
      }
    });
  }, [eventId, userPrivacySetting]);

  const handleChatClick = owner => {
    setRequestMeeting(false);
    setSelectedOwner(owner);
    setViewSection(PAGE_SECTIONS.SELECTED_CHAT);
  };

  const handleMeetingClick = owner => {
    onHandleMeetingClick(owner);
  };

  const handleBackButtonClick = e => {
    setViewSection(PAGE_SECTIONS.OWNERS_LIST);
    setSelectedOwner(null);
  };

  useEffect(() => {
    if (!boothId || !eventId) {
      return;
    }
    // fetching booth owners
    api.booth
      .getBoothOwnersWithLiveStatus(eventId, boothId)
      .then(({ data = [] }) => {
        const boothOwnersList = data.filter(
          item => item.accountId !== currentUser.accountId,
        );
        setBoothOwners(boothOwnersList);
      });
  }, [eventId, boothId]);

  useEffect(() => {
    if(parent !== 'lobby') {
      return;
    }

    // fetching selected lobby organizers
    api.event
      .getLobbyContactsWithLiveStatus(eventId)
      .then(({ data = [] }) => {
      const boothOwnersList = data.filter(
        item => item.accountId !== currentUser.accountId,
      );
      setBoothOwners(boothOwnersList);
    });
  }, [eventId]);

  useEffect(() => {
    if (
      boothOwners.length === 1 &&
      eventChannelSettings?.hasPeople &&
      boothOwners[0]?.isPrivateMessagingEnabled &&
      isPrivateChatEnabled(boothOwners[0]?.accessGroups)
    ) {
      handleChatClick(boothOwners[0]);
    }
  }, [eventChannelSettings, boothOwners]);

  const [eventChannelConfig, setEventChannelConfig] = useState(null) as any;
  useEffect(() => {
    if (!eventId) return;
    api.channel.getConfigByRef(eventId).then(({ data }) => {
      setEventChannelConfig(data);
    });
  }, [eventId]);

  const [messageState, messageDispatch] = useReducer(chatMessageReducer, {
    messageMap: {},
    messageIdList: [],
  });

  const chatMessageList = useMemo(() => {
    const { messageMap, messageIdList } = messageState;
    return messageIdList.map(messageId => {
      return messageMap[messageId];
    });
  }, [messageState]);

  useEffect(() => {
    messageDispatch({
      type: 'remove',
    });
    if (selectedOwner != null && eventChannelConfig) {
      api.channel
        .getAttendeeMessageList(
          eventChannelConfig.channelId,
          selectedOwner.accountId,
          eventId,
        )
        .then(({ data }) => {
          messageDispatch({
            type: 'setList',
            payload: {
              messageList: data,
            },
          });
        });
    }
  }, [selectedOwner, eventChannelConfig]);

  const addAttendeeMessage = message => {
    messageDispatch({
      type: 'add',
      payload: {
        message: message,
      },
    });
  };

  const updateAttendeeReactions = (updatedReactions, messageId) => {
    messageDispatch({
      type: 'updateReactions',
      payload: {
        updatedReactions,
        messageId
      },
    });
  };

  const sendEventMessage = (message: string) => {
    api.channel
      .sendAttendeeMessage(
        eventChannelConfig.channelId,
        selectedOwner.accountId,
        message,
      )
      .then(({ data }) => {
        addAttendeeMessage(data);
      })
      .catch(err => {
        let description = 'Oops something went wrong. Please try again';
        if (
          err?.response?.data?.message ===
          IUserPrivacyErrors.PRIVATE_MESSAGING_BLOCKED
        ) {
          description =
            'Message cannot be sent as the user has turned off private chats';
        }
        dispatch({
          type: 'global/addDangerToast',
          payload: {
            description: description,
          },
        });
      });
  };

  const liveStatus = isLive => {
    return isLive ? (
      <div className={styles.liveStatus}>
        <LiveStatusIcon fillColor="#77B903" size={13} />
      </div>
    ) : (
      <div className={styles.liveStatus}>
        <LiveStatusIcon fillColor="#ABABAB" size={13} />
      </div>
    );
  };

  const getPicView = owner => {
    return (
      <div className={styles.pictureContainer}>
        {owner.picUrl != null ? (
          <>
            {liveStatus(owner.isLive)}
            <Image
              styleClass="inboxItemProfilePic"
              src={getImageUrlWithSize(owner.picUrl, 0, 50)}
            ></Image>
          </>
        ) : (
          <>
            {liveStatus(owner.isLive)}
            <ProfilePicture
              name={`${owner.firstName} ${owner.lastName}`}
              styleClass="small"
              hexColor={owner.hexColor}
              disableTooltip={true}
              iconSize={IGeneralSizeTypes.SMALL}
            />
          </>
        )}
      </div>
    );
  };

  const getCloseIcon = () => (
    <div className={styles.crossIcon} onClick={onClose}>
      <X size={20} color={getCssVar(IContentColors.WHITE)} />
    </div>
  );

  const getSubTitle = owner => {
    if (owner.designation && owner.company) {
      return `${owner.designation}, ${owner.company}`;
    }
    if (owner.designation && !owner.company) {
      return `${owner.designation}`;
    }
    if (!owner.designation && owner.company) {
      return `${owner.company}`;
    }
    return null;
  };

  const OwnerRoleLabel = role => {
    const ownerRole = (role || '').toLowerCase();
    const textConfig = (() => {
      if (ownerRole === IChatMessageSender.SPEAKER) {
        return ['Speaker', ITextColors.NEUTRAL_DARK];
      }
      if (ownerRole === IChatMessageSender.ORGANIZER) {
        return ['Organizer', ITextColors.SUCCESS];
      }
      if (ownerRole === IChatMessageSender.MODERATOR) {
        return ['Moderator', ITextColors.SUCCESS];
      }
      return null;
    })();
    const Label = textConfig && (
      <>
        <Text
          text="• "
          textColor={ITextColors.NEUTRAL_MEDIUM}
          textSize={ITextSizes.XSMALL}
          textStyle={ITextStyles.BOLDED}
          textLineHeight={1.2}
          block={false}
        />
        <Text
          text={textConfig[0]}
          textColor={textConfig[1]}
          textSize={ITextSizes.XSMALL}
          textStyle={ITextStyles.BOLDED}
          textLineHeight={1.2}
          block={false}
        />
      </>
    );
    return Label;
  };

  return (
    <>
      <div className={styles.mainContainer}>
        {viewSection === PAGE_SECTIONS.OWNERS_LIST && (
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            {getCloseIcon()}
          </div>
        )}
        {viewSection === PAGE_SECTIONS.OWNERS_LIST && (
          <div className={styles.ownersListContainer}>
            {boothOwners.map(owner => {
              return (
                <div className={styles.ownerItemContainer}>
                  {getPicView(owner)}
                  <div className={styles.detailsContainer}>
                    <div className={styles.nameLabel}>
                      <div
                        className={styles.nameText}
                      >{`${owner.firstName} ${owner.lastName}`}</div>
                      <div className={styles.ownerLabel}>
                        {OwnerRoleLabel(owner.role)}
                      </div>
                    </div>
                    {(owner.designation || owner.company) && (
                      <div className={styles.designationLabel}>
                        {getSubTitle(owner)}
                      </div>
                    )}
                  </div>
                  <div className={styles.actionContainer}>
                    {meetingsShownMode &&
                      currentUserMeetingsMode &&
                      owner.isPrivateMeetingsEnabled &&
                      isPrivateMeetingEnabled(owner.accessGroups) && (
                        <div
                          className={styles.actionButton}
                          onClick={() => handleMeetingClick(owner)}
                        >
                          <CalendarPlus />
                        </div>
                      )}
                    {eventChannelSettings?.hasPeople &&
                      owner.isPrivateMessagingEnabled &&
                      isPrivateChatEnabled(owner.accessGroups) && (
                        <div
                          className={styles.actionButton}
                          onClick={() => handleChatClick(owner)}
                        >
                          <SendChatIcon size={13} />
                        </div>
                      )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {viewSection === PAGE_SECTIONS.SELECTED_CHAT && (
          <div className={styles.selectedChatContainer}>
            <OneToOneChat
              messages={chatMessageList}
              onMessageSubmit={sendEventMessage}
              chatSelected={selectedOwner}
              setChatSelected={handleBackButtonClick}
              addAttendeeMessage={addAttendeeMessage}
              showRequestMeetingView={requestMeeting}
              parent={IChatBoxParent.BOOTH_OWNER_WIDGET}
              meetingsShownMode={meetingsShownMode}
              updateReactions={updateAttendeeReactions}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default BoothOwnerWidget;
