export interface IAttendeeMessageProps {
  attendeeDetails: any;
  setChatSelected: any;
  messages: any;
  requestMeeting: any;
  setRequestMeeting: any;
  addAttendeeMessage?: any;
  parent?: IChatBoxParent;
  meetingsSettingsAndIntervalTime?: any;
  ticketsDayWiseAccessList?: any;
  showEmojiPicker?: boolean;
  showGifPicker?: boolean;
  meetingsShownMode?: boolean;
  isEventTab?: boolean;
  updateReactions?: any;
}

export enum IChatBoxParent {
  SIDE_PANEL = 'sidePanel',
  INBOX = 'inbox',
  BOOTH_OWNER_WIDGET = 'boothOwnerWidget',
}
