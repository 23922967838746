import React, { useState } from 'react';
import { Form } from 'react-final-form';
import FormSection from '@/components/forms/FormSection';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import { MEDIA_WIDGET_SETTINGS_FORM_FIELD } from './config';
import { IMediaWidgetSettingsProps } from './types';


const MediaWidgetSettings = ({ onSave, initialValues }: IMediaWidgetSettingsProps) => {
  return (
    <Form onSubmit={onSave} initialValues={initialValues} render={({ handleSubmit, form, submitting, pristine, values }) => {
      return (
        <form onSubmit={handleSubmit}>
          <FormSection
            formData={MEDIA_WIDGET_SETTINGS_FORM_FIELD}
          />
          <Button
            loading={submitting}
            label="Save"
            type="submit"
            styleClass={IButtonTypes.CONTRAST_PRIMARY}
          />
        </form>
      )
    }} />
  );
};

export default MediaWidgetSettings;
