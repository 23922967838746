import React, { useState } from 'react';
import RichTextEditor from '@/components/ui/RichTextEditor';
import { IRichTextWidgetSettingsProps } from './types';
import styles from './styles.module.scss';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';

const RichTextWidgetSettings = ({ onSave, initialValues }: IRichTextWidgetSettingsProps) => {
  const { richText } = initialValues || {};

  const blankSlateValue = [{ type: "paragraph", children: [{ text: "" }] }]
  const [value, setValue] = useState(richText || blankSlateValue);


  const handleSave = () => {
    onSave({
      richText: value
    });
  };

  return <React.Fragment>
    <div className={styles.textEditor}>
      <RichTextEditor value={value} handleChange={setValue} />
    </div>
    <Button
      label="Save"
      type="button"
      styleClass={IButtonTypes.PRIMARY}
      onClick={handleSave}
    />
  </React.Fragment >;
};

export default RichTextWidgetSettings;
