// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__gifContainer___2N0np{position:relative;margin-top:4px;border-radius:6px;overflow:hidden;max-width:100%;display:inline-block;height:100px;max-height:100px}.styles-module__emptyLoaderBox___hwYFD{height:100px;width:100px}.styles-module__playerButton___17axu{font-size:10px;font-weight:600;line-height:12px;position:absolute;background:rgba(237,237,237,0.75);backdrop-filter:blur(25px);width:30px;height:30px;top:50%;left:50%;transform:translate(-50%, -50%);border-radius:50%;display:flex;justify-content:center;align-items:center;color:rgba(0,0,0,0.5);cursor:pointer}.styles-module__gif___mka7C{height:100px;max-height:100px;max-width:255px}\n", ""]);
// Exports
exports.locals = {
	"gifContainer": "styles-module__gifContainer___2N0np",
	"emptyLoaderBox": "styles-module__emptyLoaderBox___hwYFD",
	"playerButton": "styles-module__playerButton___17axu",
	"gif": "styles-module__gif___mka7C"
};
module.exports = exports;
