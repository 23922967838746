// helpers
import {
  booleanSelectFieldOptions,
  generateSelectFieldOptionsFromStringArray,
} from '@/utils/helpers';
import { _getEnumKeys } from '@/utils/jsHelpers/array';
// types + validations
import { IVideoFrameTypes } from '@/components/PublishedStreamDisplay';
import { IFormFieldTypes, IFormFieldRow } from '@/types/form';
import validations from '@/validations';

const { composeValidators, required } = validations;

const frameTypes = _getEnumKeys(IVideoFrameTypes);
const FRAME_OPTIONS = generateSelectFieldOptionsFromStringArray(frameTypes);

export const STREAM_WIDGET_SETTINGS_FORM_FIELDS = [
  {
    fields: [
      {
        name: 'frame',
        label: 'Frame',
        fieldType: IFormFieldTypes.SELECT,
        data: FRAME_OPTIONS,
        widthRatio: 1,
        validate: composeValidators(required),
      },
    ],
  },
  {
    fields: [
      {
        name: 'raiseHand',
        label: 'Raise Hand Functionality?',
        fieldType: IFormFieldTypes.SELECT,
        data: booleanSelectFieldOptions,
        widthRatio: 1,
        validate: composeValidators(required),
      },
    ],
  },
  {
    fields: [
      {
        name: 'customizeDims',
        label: 'Enable Custom Dimensions?',
        fieldType: IFormFieldTypes.SELECT,
        data: booleanSelectFieldOptions,
        widthRatio: 1,
        validate: composeValidators(required),
      },
    ],
  },
  {
    fields: [
      {
        name: 'widgetHeight',
        label: 'Widget Height',
        fieldType: IFormFieldTypes.SELECT,
        data: booleanSelectFieldOptions,
        widthRatio: 1,
        validate: composeValidators(required),
      },
    ],
  },
  {
    fields: [
      {
        name: 'widgetWidth',
        label: 'Widget Width',
        fieldType: IFormFieldTypes.SELECT,
        data: booleanSelectFieldOptions,
        widthRatio: 1,
        validate: composeValidators(required),
      },
    ],
  },
] as IFormFieldRow[];