// components
import CertificateWidget from '../CertificateWidget';
import MediaWidget from '../MediaWidget';
import PhotoboothWidget from '../PhotoboothWidget';
import RichTextWidget from '../FormattedTextWidget';
import StreamWidget from '../StreamWidget';
import TextWidget from '../TextWidget';
import ButtonWidget, { UserStarIcon } from '../ButtonWidget';
import HotspotWidget from '../HotspotWidget';
import TickerWidget from '../TickerWidget';
import FileWidget from '../FileWidget';
import IFrameWidget from '../IFrameWidget';
import CouponWidget from '../CouponWidget';
// types
import { WidgetTypes } from './types';
import ArPhotoboothWidget from '../ArPhotoboothWidget';
import { VideoIcon, ImageIcon, FolderDownloadIcon, ArrowIcon, TextIcon } from '../icons';
import { IWidgetType } from '../types';
import { GiftIcon } from '@/pages/live-event/BoothPage/BoothPageInner/icons';

export const widgetComponentMap = {
  [WidgetTypes.CERTIFICATE]: CertificateWidget,
  [WidgetTypes.MEDIA]: MediaWidget,
  [WidgetTypes.PHOTOBOOTH]: PhotoboothWidget,
  [WidgetTypes.RICH_TEXT]: RichTextWidget,
  [WidgetTypes.STREAM]: StreamWidget,
  [WidgetTypes.TEXT]: TextWidget,
  [WidgetTypes.BUTTON]: ButtonWidget,
  [WidgetTypes.HOTSPOT]: HotspotWidget,
  [WidgetTypes.TICKER]: TickerWidget,
  [WidgetTypes.AR_PHOTOBOOTH]: ArPhotoboothWidget,
  [WidgetTypes.FILE]: FileWidget,
  [WidgetTypes.IFRAME]: IFrameWidget,
  [WidgetTypes.COUPON_WIDGET]: CouponWidget,
  [WidgetTypes.BOOTH_OWNER_BUTTON]: ButtonWidget,
};

export const widgetIconMap = {
  [IWidgetType.VIDEO]: VideoIcon,
  [IWidgetType.IMAGE]: ImageIcon,
  [IWidgetType.IMAGECAROUSEL]: ImageIcon,
  [IWidgetType.BUTTON]: ArrowIcon,
  [IWidgetType.TICKER]: TextIcon,
  [IWidgetType.HOTSPOT]: ArrowIcon,
  [IWidgetType.FILE]: FolderDownloadIcon,
  [IWidgetType.IFRAME]: ArrowIcon,
  [IWidgetType.COUPON_WIDGET]: GiftIcon,
  [IWidgetType.BOOTH_OWNER_BUTTON]: UserStarIcon,
};
