import React, { useState, useEffect, useRef, useCallback } from 'react';
// components
import Loader from '@/components/ui/Loader';
import IconTextButton, {
  IIconTextButtonMarginTypes,
} from '@/components/ui/buttons/IconTextButton';
import { IUIIcons } from '@/types';
// models
import { makeSelectUserFullName } from '@/models/account';
// styles + types
import styles from './styles.module.scss';
import { IButtonTypes } from '@/components/ui/buttons/Button';
import { ICertificateWidgetTypes } from './types';
import { WidgetTypes } from '../Widget/types';
// hooks
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';

const { DOWNLOAD } = IUIIcons;

const CertificateWidget = (props: ICertificateWidgetTypes) => {
  const {
    width = '100%',
    height = '100%',
    imageSrc = 'https://phoenixlive.imgix.net/7f0c398c-b89b-45ad-a8d3-7ae9773cbf8b.jpg',
    leftPixelValue = '200',
    rightPixelValue = '400',
    fileSrc,
    type,
  } = props;

  const [naturalDims, setNaturalDims] = useState({});
  const [imgDims, setImgDims] = useState({
    widht: 3604,
    height: 2583,
  });
  const [certificateWithNameUrl, setCertificateWithNameUrl] = useState();
  const userFullName = useMemoizedSelector(makeSelectUserFullName);

  const imageRef = useRef();
  useEffect(() => {
    if (!imageRef || !imageRef.current) {
      return;
    }

    imageRef.current.onload = () => {
      if (!imageRef || !imageRef.current) {
        return;
      }

      const node = imageRef.current;
      setNaturalDims({
        height: node.naturalHeight,
        width: node.naturalWidth,
      });
    };
  }, [imageRef]);

  const canvasRef = useRef();
  const imageRef2 = useRef();

  const canvas = document.getElementById('certificateImageCanvas');
  const certificateImage = document.getElementById('certificateImage');
  const certificateImage2 = document.getElementById('certificateImage2');

  const { height: imageHeight, width: imageWidth } = imgDims;

  const saveCanvas = () => {
    if (canvasRef) {
      const canvasNode = canvasRef.current;
      if (canvasNode) {
        const image = canvasNode
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream');
        setCertificateWithNameUrl(image);
      }
    }
  };

  useEffect(() => {
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (certificateImage) {
        certificateImage.onload = () => {
          certificateImage2.src = imageSrc;
          setImgDims({
            width: certificateImage.clientWidth,
            height: certificateImage.clientHeight,
          });
        };
      }

      if (certificateImage2) {
        certificateImage2.onload = () => {
          ctx.drawImage(certificateImage2, 0, 0, imageWidth, imageHeight);
          ctx.font = imageHeight < 700 ? '14px Inter' : '30px Inter';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center';
          ctx.fillText(
            userFullName,
            imageWidth / 2,
            imageHeight / 2 +
              20 * (naturalDims.height ? imageHeight / naturalDims.height : 1),
          );
          saveCanvas();
        };
      }
    }
  }, [
    canvas,
    certificateImage,
    certificateImage2,
    userFullName,
    imgDims,
    naturalDims,
  ]);

  if (!userFullName) {
    return <Loader />;
  }

  if (WidgetTypes.FILE === type) {
    return <a className={styles.hotspot} target="_blank" href={fileSrc}></a>;
  }

  return (
    <div className={styles.certificateWidgetContainer}>
      <a
        className={styles.downloadButton}
        download="Certificate.png"
        href={certificateWithNameUrl}
      >
        <IconTextButton
          activeIcon={DOWNLOAD}
          activeLabel="Download Certificate"
          defaultLabel="Download Certificate"
          defaultIcon={DOWNLOAD}
          showDefault={true}
          buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
          buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
        />
      </a>
      <div
        className={styles.certificateWidget}
        style={{
          width: imageWidth,
          height: imageHeight,
        }}
      >
        <img
          className={styles.certificateImage}
          ref={imageRef}
          alt=""
          id="certificateImage"
          src={imageSrc}
        />
        <img
          className={styles.certificateImage2}
          ref={imageRef2}
          alt=""
          id="certificateImage2"
          style={{
            width: imageWidth,
            height: imageHeight,
          }}
          crossOrigin="anonymous"
        />
        <canvas
          className={styles.certificateImageCanvas}
          height={imageHeight}
          id="certificateImageCanvas"
          width={imageWidth}
          ref={canvasRef}
        />
      </div>
    </div>
  );
};

export default CertificateWidget;
