import * as introJs from 'intro.js';
import 'intro.js/introjs.css';
import './intro-styles.css';
import api from '@/api';

//export const INTRO_ELEMENT_TABLE = 'intro-element-table';

export const JOIN_TABLE_FLOW = "JOIN_TABLE_FLOW"

export const FLOW_STEPS = {
  TABLE: "navbar-menu-room",
  INTRO_ELEMENT_TABLE: "intro-element-table",
  CREATE_NEW_ROOM : 'table-create-new-room',
  UPCOMING: "table-upcoming",
  PENDING: "table-pending",
  PAST: "table-past"
}

export const getIntro = (onExitCallback, currentZoneConfig) => {
  const intro = introJs();
  const { name = 'Room' } = currentZoneConfig || {};
  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    keyboardNavigation : false,
    showBullets: false,
    showProgress: false,
    nextLabel: 'Start Tour &nbsp; &gt;',
    skipLabel: 'Skip Tour',
    doneLabel: 'Done &nbsp; &check;',
    steps: [
      {
        element: `#${FLOW_STEPS.TABLE}`,
        intro: `Welcome to ${name} Area! Let’s show you what we have here.`,
        position: 'bottom'
      },
      {
        element: `#${FLOW_STEPS.INTRO_ELEMENT_TABLE}`,
        intro: "Click a <b>table</b> to join",
        position: 'left'
      },
      {
        element: `#${FLOW_STEPS.CREATE_NEW_ROOM}`,
        intro: "Set personal meetings with other attendees",
        position: 'left'
      },
      {
        element: `#${FLOW_STEPS.UPCOMING}`,
        intro: "Find all your upcoming meetings here",
        position: 'left'
      },
      {
        element: `#${FLOW_STEPS.PENDING}`,
        intro: "Find all meeting requests pending for your approval here",
        position: 'left'
      },
      {
        element: `#${FLOW_STEPS.PAST}`,
        intro: "Your personal meeting history will reflect here",
        position: 'left'
      }
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: JOIN_TABLE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    if (stepNumber > Object.keys(FLOW_STEPS).length) return;

    const updatedFlowState = { flowName: JOIN_TABLE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= Object.keys(FLOW_STEPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}

export const TABLE_STREAM_JOIN_FLOW = "TABLE_STREAM_JOIN_FLOW";

export const STREAM_FLOW_STEPS = {
  AUDIO: "stream-audio",
  VIDEO: "stream-video",
  SCREEN_SHARE: "stream-screen-share",
  SWITCH_TABLE: "stream-switch-table",
  STAGE_PREVIEW : 'stage-preview-container',
  STAGE_PREIVEW_VOLUME : 'volume-toggle-button',
  STAGE_PREVIEW_DROPDOWN : 'meeting-room-preview-dropdown'
}

export const getStreamIntro = (onExitCallback) => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',
    showProgress: false,
    showBullets: false,

    steps: [
      {
        intro: "A/V controller helps to mute/unmute your audio",
        element: `#${STREAM_FLOW_STEPS.AUDIO}`,
      },
      {
        intro: "A/V controller helps to hide/unhide your video",
        element: `#${STREAM_FLOW_STEPS.VIDEO}`,
      },
      {
        intro: "Shares your device screen with everyone present in the Room",
        element: `#${STREAM_FLOW_STEPS.SCREEN_SHARE}`,
      },
      {
        intro: "Click on other rooms to join them and interact with folks of different groups",
        element: `#${STREAM_FLOW_STEPS.SWITCH_TABLE}`,
        position: 'left'
      },
      {
        intro: "View the stage with other members in the room",
        element: `#${STREAM_FLOW_STEPS.STAGE_PREVIEW}`,
        position: 'left'
      },
      {
        intro: "Mute/ Unmute stage sound",
        element: `#${STREAM_FLOW_STEPS.STAGE_PREIVEW_VOLUME}`,
        position: 'left'
      },
      {
        intro: "Select the stage from the list of all the live stages on the event",
        element: `#${STREAM_FLOW_STEPS.STAGE_PREVIEW_DROPDOWN}`,
        position: 'left'
      },
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: TABLE_STREAM_JOIN_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    if (stepNumber > Object.keys(STREAM_FLOW_STEPS).length) return;
   
    const updatedFlowState = { flowName: TABLE_STREAM_JOIN_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= Object.keys(STREAM_FLOW_STEPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}


export const TABLE_MEETING_STREAM_FLOW = "TABLE_MEETING_STREAM_FLOW";
 
export const MEETING_STREAM_FLOW_STEPS = {
  AUDIO: "stream-audio",
  VIDEO: "stream-video",
  SCREEN_SHARE: "stream-screen-share",
  INVITE_PEOPLE : 'stream-meeting-invite-people',
  MEETING_LINK : 'stream-meeting-copy-link'
}

export const getMeetingStreamIntro = (onExitCallback) => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',
    showProgress: false,
    showBullets: false,

    steps: [
      {
        intro: "A/V controller helps to mute/unmute your audio",
        element: `#${MEETING_STREAM_FLOW_STEPS.AUDIO}`,
      },
      {
        intro: "A/V controller helps to hide/unhide your video",
        element: `#${MEETING_STREAM_FLOW_STEPS.VIDEO}`,
      },
      {
        intro: "Shares your device screen with everyone present in the Room",
        element: `#${MEETING_STREAM_FLOW_STEPS.SCREEN_SHARE}`,
      },
      {
        intro: " Invite people from attendee list for a meeting session",
        element: `#${MEETING_STREAM_FLOW_STEPS.INVITE_PEOPLE}`,
        position: 'left'
      },
      {
        intro: "Invite people on the event to join a personal meeting room with this link",
        element: `#${MEETING_STREAM_FLOW_STEPS.MEETING_LINK}`,
        position: 'left'
      },
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: TABLE_MEETING_STREAM_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    if (stepNumber > Object.keys(MEETING_STREAM_FLOW_STEPS).length) return;
  
    const updatedFlowState = { flowName: TABLE_MEETING_STREAM_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= Object.keys(MEETING_STREAM_FLOW_STEPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}