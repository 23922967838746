import React, { useState } from 'react';
import { ITextWidgetSettingsProps } from './types';
import { Form } from 'react-final-form';
import FormSection from '@/components/forms/FormSection';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import { IFormFieldTypes, IFormFieldRow } from '@/types/form';
import validations from '@/validations';

const { composeValidators, required } = validations;
export const TEXT_WIDGET_SETTINGS_FORM_FIELD = [
  {
    fields: [
      {
        name: 'text',
        label: 'Text',
        fieldType: IFormFieldTypes.INPUT,
        widthRatio: 1,
        validate: composeValidators(required)
      },
    ],
  }
] as IFormFieldRow[];

const TextWidgetSettings = ({ onSave, initialValues }: ITextWidgetSettingsProps) => {
  return (
    <div>
      <Form onSubmit={onSave} initialValues={initialValues} render={({ handleSubmit, form, submitting, pristine, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormSection
              formData={TEXT_WIDGET_SETTINGS_FORM_FIELD}
            />
            <Button
              loading={submitting}
              label="Save"
              type="submit"
              styleClass={IButtonTypes.CONTRAST_PRIMARY}
            />
          </form>
        )
      }} />
    </div>
  );
};

export default TextWidgetSettings;
