export interface IWidgetProps {
  x: number,
  y: number,
  width: number,
  height: number,
  viewOnly?: boolean
};

export interface IWidgetSettingsProps {
  initialValues: any;
  onSave: () => void;
};

export enum IWidgetType {
  VIDEO = 'video',
  IMAGE = 'image',
  BUTTON = 'button',
  HOTSPOT = 'hotspot',
  TICKER = 'ticker',
  REDIRECT_IMAGE_CAROUSEL = 'REDIRECT_IMAGE_CAROUSEL',
  IMAGECAROUSEL = 'IMAGECAROUSEL',
  FILE = 'FILE',
  BOOTH_REDIRECT_HOTSPOT = 'BOOTH_REDIRECT_HOTSPOT',
  IFRAME = 'IFRAME',
  BOOTH_OWNER_BUTTON = 'BOOTH_OWNER_BUTTON',
  COUPON_WIDGET = 'COUPON_WIDGET',
  TEXT = 'TEXT',
};

export enum IWidgetCategory {
  MEDIA = 'MEDIA',
  BUTTON = 'BUTTON',
  TICKER = 'TICKER',
  HOTSPOT = 'HOTSPOT',
  CERTIFICATE = 'CERTIFICATE',
  FILE = 'FILE',
  IFRAME = 'IFRAME',
  BOOTH_OWNER_BUTTON = 'BOOTH_OWNER_BUTTON',
  COUPON_WIDGET = 'COUPON_WIDGET',
  TEXT = 'TEXT',
};
