import * as React from 'react';
import { SwipeIcon } from '../icons';
import styles from './styles.module.scss';

const OverLay = ({ onClose, eventId, isLobby, isBooth }) => {
  const onOkButtonClick = (e) => {
    e.preventDefault();
    if(isBooth) {
      localStorage.setItem(`is-expo-item-displayed-${eventId}`, 'true');
    }
    if(isLobby) {
      localStorage.setItem(`is-lobby-item-displayed-${eventId}`, 'true');
    }
    onClose();
  }

  const component = (() => {
    if (isLobby)
      return "lobby";
    if (isBooth)
      return "booth";
    return "zones";
  })();
  
  return (<div className={styles.scrollInfo}>
    <div className={styles.animationBlock}>
      <div className={styles.swipeIcon}>
        <SwipeIcon /></div>
    </div>
    <p>Please swipe to scroll across the {component}</p>
    <button onClick={onOkButtonClick}>OK</button>
  </div>)
}

export default OverLay;
