export enum PhotoboothLayerTypes {
  IMAGE = 'IMAGE',
  PREVIEW = 'PREVIEW',
  DEEP_AR = 'DEEP_AR',
}

export enum PhotoboothPreviewFrameTypes {
  RECTANGLE = 'RECTANGLE',
  CIRCLE = 'CIRCLE'
}

interface IPhotoboothLayer {
  type: PhotoboothLayerTypes;
  src?: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface IPhotoboothLayers {
  layers: IPhotoboothLayer[];
}
