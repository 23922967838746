import React from 'react';
// styles + types
import styles from './styles.module.scss';
import { IFormValidationErrorTooltipProps } from './types';

const FormValidationErrorTooltip = (
  props: IFormValidationErrorTooltipProps,
) => {
  const { value, dataTestId } = props;

  return (
    <div>
      <div className={styles.fieldValidationErrorTooltip}>
        <p className={styles.fieldValidationErrorText} data-testid={dataTestId}>{value}</p>
      </div>
    </div>
  );
};

export default FormValidationErrorTooltip;
