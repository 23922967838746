import * as introJs from 'intro.js';
import 'intro.js/introjs.css';
import './intro-styles.css';
import api from '@/api';
import { IUIIcons } from '@/types';
import { IStageParent } from '../Stage/types';
const { CHAT, POLL, QA, RAISED_HANDS, FILES, PEOPLE } = IUIIcons;
const sidePanelButtons = [CHAT, POLL, QA, FILES, RAISED_HANDS, PEOPLE];

export const STAGE_FEATURES_FLOW = 'STAGE_FEATURES_FLOW';

export const FLOW_STEPS = {
  STAGE: 'navbar-menu-stage',
  GOBACKSTAGE  : 'onboarding-go-backstage',
  CHAT: "stage-chat",
  Q7A: "stage-q7a",
  POLLS: "stage-polls",
  FILES: 'stage-files',
  RAISEHAND: "stage-raisehand",
  PEOPLE: "stage-people",
};

export let getIntro = (onExitCallback, channelSettings, backstageButton, parent, currentZoneConfig) => {
  const intro = introJs();
  const { name = 'Stage'} = currentZoneConfig || {};
  var intro_steps = [];

  const stageNavDiv = document.getElementById(FLOW_STEPS.STAGE);
  if (stageNavDiv && parent && (parent === IStageParent.LIVE_STAGE || parent === IStageParent.LIVE_BACKSTAGE)) {
    intro_steps.push({
      element: `#${FLOW_STEPS.STAGE}`,
      intro: `Welcome to ${name} Area! Let’s show you what we have here`,
      position: 'bottom'
    });
  }
  
  //backstage button only for organizers and speakers.
  var backstageButtonDiv = document.getElementById(FLOW_STEPS.GOBACKSTAGE);
  if(backstageButton && backstageButtonDiv){
    intro_steps.push({
      element: `#${FLOW_STEPS.GOBACKSTAGE}`,
      intro: 'Lets an organizer or speaker enter the backstage',
      position: 'top'
    });
  }

  sidePanelButtons.forEach(icon => {
    if (channelSettings[icon]) {
      switch (icon) {
        case CHAT:
          var chatDiv = document.getElementById(FLOW_STEPS.CHAT);
          if(!chatDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.CHAT}`,
            intro: "Enter a chat thread open to everyone on the event",
            position: 'left'
          });
          break;
        case POLL:
          var pollDiv = document.getElementById(FLOW_STEPS.POLLS);
          if(!pollDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.POLLS}`,
            intro: "Create live polls to capture inputs from other attendees in real time",
            position: 'left'
          });
          break;
        case QA:
          var qaDiv = document.getElementById(FLOW_STEPS.Q7A);
          if(!qaDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.Q7A}`,
            intro: 'You can ask questions to speakers on stage or upvote already asked questions',
            position: 'left'
          });
          break;
        case FILES:
          var filesDiv = document.getElementById(FLOW_STEPS.FILES);
          if(!filesDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.FILES}`,
            intro: 'Find all the relevant files here',
            position: 'left'
          });
          break;
        case RAISED_HANDS:
          var raiseHandDiv = document.getElementById(FLOW_STEPS.RAISEHAND);
          if(!raiseHandDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.RAISEHAND}`,
            intro: 'Raise hand allows attendee to join the speaker on stage and have a discussion or ask a question',
            position: 'left'
          });
          break;
        case PEOPLE:
          var peopleDiv = document.getElementById(FLOW_STEPS.PEOPLE);
          if(!peopleDiv)
             break
          intro_steps.push({
            element: `#${FLOW_STEPS.PEOPLE}`,
            intro: 'Explore the list of attendees on the event to hold a private conversation/meeting with them',
            position: 'left'
          });
          break;
      }
    }
  });

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    keyboardNavigation : false,
    showBullets: false,
    showProgress: false,
    nextLabel: 'Start Tour &nbsp; &gt;',
    skipLabel: 'Skip Tour',
    doneLabel: 'Done &nbsp; &check;',
    steps: intro_steps,
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: STAGE_FEATURES_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if (stepNumber > intro_steps.length) return;
    const updatedFlowState = { flowName: STAGE_FEATURES_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= intro_steps.length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}

export const STAGE_STREAM_JOIN_FLOW = "STAGE_STREAM_JOIN_FLOW";

export const STREAM_FLOW_STEPS = {
  AUDIO: "stream-audio",
  VIDEO: "stream-video",
  SCREEN_SHARE: "stream-screen-share",
}

export const getStreamIntro = (onExitCallback) => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',

    steps: [
      {
        intro: "Use these to mute/unmute your audio",
        element: `#${STREAM_FLOW_STEPS.AUDIO}`,
      },
      {
        intro: "Use these to hide/unhide your video",
        element: `#${STREAM_FLOW_STEPS.VIDEO}`,
      },
      {
        intro: "You can avail the screenshare functionality by clicking here",
        element: `#${STREAM_FLOW_STEPS.SCREEN_SHARE}`,
      },
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: STAGE_STREAM_JOIN_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if (stepNumber > Object.keys(STREAM_FLOW_STEPS).length) return;
    const updatedFlowState = { flowName: STAGE_STREAM_JOIN_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= Object.keys(STREAM_FLOW_STEPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}


export const BACKSTAGE_TO_LIVE_STAGE_FLOW = "BACKSTAGE_TO_LIVE_STAGE_FLOW";
export const BACKSTAGE_TO_LIVE_STAGE_SETPS = {
  HAND: "hand-raise-to-stage",
  AUDIO: "stream-audio",
  VIDEO: "stream-video",
  SCREEN_SHARE: "stream-screen-share",
}

export const getBackStageToLiveStageIntro = (onExitCallback) => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',
    steps: [
      {
        intro: "A speaker or an organizer can be pushed from backstage to the stage",
        element: `#${BACKSTAGE_TO_LIVE_STAGE_SETPS.HAND}`,
      },
      {
        intro: "Use these to mute/unmute your audio",
        element: `#${BACKSTAGE_TO_LIVE_STAGE_SETPS.AUDIO}`,
      },
      {
        intro: "Use these to hide/unhide your video",
        element: `#${BACKSTAGE_TO_LIVE_STAGE_SETPS.VIDEO}`,
      },
      {
        intro: "You can avail the screenshare functionality by clicking here",
        element: `#${BACKSTAGE_TO_LIVE_STAGE_SETPS.SCREEN_SHARE}`,
      },
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: BACKSTAGE_TO_LIVE_STAGE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if (stepNumber > Object.keys(BACKSTAGE_TO_LIVE_STAGE_SETPS).length) return;
    const updatedFlowState = { flowName: BACKSTAGE_TO_LIVE_STAGE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
    }).onchange(() => {
    const stepNumber = intro._currentStep + 1;
    var nextButton = document.querySelector('.introjs-nextbutton') as HTMLAnchorElement;
    if (stepNumber >= Object.keys(BACKSTAGE_TO_LIVE_STAGE_SETPS).length && nextButton) {
      nextButton.style.display = 'none';
    } else if (stepNumber > 1 && nextButton) {
      nextButton.innerHTML = 'Next &nbsp; &gt;';
    }
  })

  return intro;
}

export const RAISE_HAND_TO_BACKSTAGE_FLOW = "RAISE_HAND_TO_BACKSTAGE_FLOW";
export const RAISE_HAND_TO_BACKSTAGE_STEPS = {
  HAND: "hand-raise-to-backstage",
}

export const getRaiseHandToBackStageIntro = (onExitCallback) => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel : 'Next &nbsp; &gt;',
    doneLabel : 'Done &nbsp; &check;',
    steps: [
      {
        intro: "Brings a speaker or an organizer down from the stage",
        element: `#${RAISE_HAND_TO_BACKSTAGE_STEPS.HAND}`,
      }
    ]
  });

  intro.oncomplete(() => {
    const updatedFlowState = { flowName: RAISE_HAND_TO_BACKSTAGE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  }).onexit(() => {
    const stepNumber = intro._currentStep + 1;
    if(Number.isNaN(stepNumber)) return;
    const updatedFlowState = { flowName: RAISE_HAND_TO_BACKSTAGE_FLOW, stepNumber: -1 }
    onExitCallback && onExitCallback(updatedFlowState);
    api.account.updateUserFlows(updatedFlowState);
  })

  return intro;
}

export const STAGE_STREAMS_VOLUME_TOGGLE_FLOW = 'STAGE_STREAMS_VOLUME_TOGGLE';
export const STAGE_STREAMS_VOLUME_TOGGLE_STEP = {
  VOLUME_BUTTON: 'volume-toggle-button',
};
export const getStageStreamVolumeToggleIntro = onExitCallback => {
  const intro = introJs();

  intro.setOptions({
    showStepNumbers: false,
    tooltipClass: 'custom-tip',
    overlayOpacity: 0,
    exitOnOverlayClick: true,
    disableInteraction: false,
    showBullets: false,
    showProgress: false,
    nextLabel: 'Next &nbsp; &gt;',
    doneLabel: 'Done &nbsp; &check;',
    steps: [
      {
        intro: 'Mute audio here before switch languages',
        element: `#${STAGE_STREAMS_VOLUME_TOGGLE_STEP.VOLUME_BUTTON}`,
        position: 'left',
      },
    ],
  });

  intro
    .oncomplete(() => {
      const updatedFlowState = {
        flowName: STAGE_STREAMS_VOLUME_TOGGLE_FLOW,
        stepNumber: -1,
      };
      onExitCallback && onExitCallback(updatedFlowState);
      api.account.updateUserFlows(updatedFlowState);
    })
    .onexit(() => {
      const stepNumber = intro._currentStep + 1;
      if (Number.isNaN(stepNumber)) return;
      const updatedFlowState = {
        flowName: STAGE_STREAMS_VOLUME_TOGGLE_FLOW,
        stepNumber: -1,
      };
      onExitCallback && onExitCallback(updatedFlowState);
      api.account.updateUserFlows(updatedFlowState);
    });
  return intro;
};
