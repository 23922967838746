import React from 'react';
import styles from './styles.module.scss';

const AnimatedLoader = () => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.loader}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  )
};

export default AnimatedLoader;
