import React from 'react';
import IFrame from '@/components/ui/IFrame';

const IFrameWidget = ({ url }) => {
  return (
    <IFrame url={url} />
  );
};

export default IFrameWidget;
