import { WidgetTypes, WidgetColorSchemeTypes } from '../../widgets/Widget/types';
import { MediaWidgetTypes } from '../../widgets/MediaWidget/types';
import {
  PhotoboothLayerTypes,
  PhotoboothPreviewFrameTypes,
} from '../../widgets/PhotoboothWidget/types';
import { zoneTypes } from '@/models/event/types';
import { ILinkType } from '@/components/forms/WidgetForms/ButtonForm/types';
import { IBoothConfigType, IExpoConfigType } from '@/custom-config/types';

export const PAGE_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/lobby_try.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.RICH_TEXT,
      x: (360 * 1920) / 1234,
      y: (546 * 1920) / 1234,
      width: (534 * 1920) / 1234,
      height: (148 * 1920) / 1234,
      config: {
        richText: [
          {
            type: 'paragraph',
            children: [
              { text: 'This is editable ' },
              { text: 'rich', bold: true },
              { text: ' text, ' },
              { text: 'much', italic: true },
              { text: ' better than a ' },
              { text: '<textarea>', code: true },
              { text: '!' },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text:
                  "Since it's rich text, you can do things like turn a selection of text ",
              },
              { text: 'bold', bold: true },
              {
                text:
                  ', or add a semantically rendered block quote in the middle of the page, like this:',
              },
            ],
          },
          {
            type: 'block-quote',
            children: [{ text: 'A wise quote.' }],
          },
          {
            type: 'paragraph',
            children: [{ text: 'Try it out for yourself!' }],
          },
        ],
      },
    },
    {
      type: WidgetTypes.TEXT,
      x: (907 * 1920) / 1234,
      y: (583 * 1920) / 1234,
      width: (300 * 1920) / 1234,
      height: (30 * 1920) / 1234,
      config: {
        text: 'WHAT DO YOU MEANNNNNNN',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (135 * 1920) / 1224,
      y: (199 * 1920) / 1224,
      width: (169 * 1920) / 1224,
      height: (109 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (697 * 1920) / 1224,
      y: (220 * 1920) / 1224,
      width: (132 * 1920) / 1224,
      height: (99 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (401 * 1920) / 1224,
      y: (376 * 1920) / 1224,
      width: (195 * 1920) / 1224,
      height: (72 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
  ],
};

export const AFCONS_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b8c323c3-aacd-4e31-b4df-6ab7aa18d0c1',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 444,
            baseHeight: 448,
            offsetTop: 353,
            offsetLeft: 890,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b8c323c3-aacd-4e31-b4df-6ab7aa18d0c1',
          },
        ],
      },
    },
  ],
};

export const QS_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/76bf3485-6eff-4ff8-a126-f9bacf00b357',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1058,
            baseHeight: 1186,
            offsetTop: 300,
            offsetLeft: 750,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/76bf3485-6eff-4ff8-a126-f9bacf00b357',
          },
        ],
      },
    },
  ],
};


export const DEMO1_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  // backgroundUrl: 'https://phoenixlive.imgix.net/lobby-confluence-freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};

export const EXPO1_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/expo_backdrop.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1126,
      y: 188,
      width: 656,
      height: 390,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/nmu7kEzAQus',
        muted: false,
      },
    },
  ],
};

export const DEFAULT_EXPO_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {},
  ],
};

export const DEFAULT_GRID_CONFIG = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: []
}

export const AMAZON_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Amazon+lobby+skin.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 730,
      y: 32,
      width: 457,
      height: 348,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/xgGrSjXhwyVZLj02Rw4qeyr2SH9rU8mSshbkCfguH002U.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DELOITTE_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/lobby-freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 477,
      y: 72,
      width: 957,
      height: 548,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/6gkr1JNKp9A',
        muted: false,
      },
    },
  ],
};

export const DEMO2_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/lobby-option2-freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 477,
      y: 72,
      width: 957,
      height: 548,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/6gkr1JNKp9A',
        muted: false,
      },
    },
  ],
};

export const DELHI_CAPITALS_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/delhi-capitals.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 604,
      y: 234,
      width: 695,
      height: 392,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=BsZ6F1Bm8eY&feature=youtu.be',
        muted: false,
      },
    },
  ],
};

export const EXPO2_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/expo_backdrop.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1126,
      y: 188,
      width: 656,
      height: 390,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/nmu7kEzAQus',
        muted: false,
      },
    },
  ],
};

export const DEMO_DAIMLER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/lobby-freeze-daimler.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 390,
      y: 330,
      width: 510,
      height: 287,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/daimler-lobby.jpeg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1000,
      y: 330,
      width: 510,
      height: 287,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=hXbYEYmd2YQ',
        muted: false,
      },
    },
  ],
};

export const EXPO_DAIMLER = {
  type: 'MANUAL',
  zone: 'booth',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/expo_backdrop.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1126,
      y: 188,
      width: 656,
      height: 390,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=pShp0WiKCiE&t=1s',
        muted: false,
      },
    },
  ],
};

export const EXPO_GENERIC = {
  type: 'MANUAL',
  zone: 'booth',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/expo_backdrop.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1126,
      y: 188,
      width: 656,
      height: 390,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=U5tvyoKy04w',
        muted: false,
      },
    },
  ],
};

export const BRITANNIA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Brittania_west-region-lobby-skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 603,
      y: 210,
      width: 700,
      height: 431,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/hgRjlzctQJgh401Jg01D6kYpldDxTOUvFqspbfOYIh7JM.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const COLGATE_FINAL_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Colgate_1609_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};

export const MICROSOFT_FINAL_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/MS_3009_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 649,
      y: 207,
      width: 630,
      height: 355,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/Cz47dzcH7TXPKW9ggE6Zl3YyMpiruTCxScezkKsLP02I.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const BANTERFEST_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Virtual_Football_Lobby-skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 660,
      y: 155,
      width: 610,
      height: 355,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/BTKBeRjOW00hqF02jakixGWYBoqqqj8zFzdVZkwsUEFuU.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const APQF_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/APQF_080910_lobby-freeze-02.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};

export const SYNOPSIS_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/synopsis_12141610_lobby-freeze-02.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 647,
      y: 187,
      width: 633,
      height: 393,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/1V02ZrtXPAX00024EZV02BuN5400qj2E00qFpbB401L00x3NOuo.m3u8',
        muted: false,
        loop: false,
      },
    },
  ]
};
export const CIPLA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/DAIS_10-1110_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 585,
      y: 285,
      width: 750,
      height: 440,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/1LuH00OCq5V537D6kvz8p6UgxIgUhLibdMIZWpMceic4.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const CIPLA_NEW_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/cipla_new_lobby_freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 505,
      y: 180,
      width: 890,
      height: 510,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/iMAhHES02008qdogFSzo02Brv6LSY2XudAU4Xw00OpKCu58.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const APPOLLO_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/8ec5edca-d67c-4798-bd9c-7dd2f526ba59.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 698,
      y: 280,
      width: 520,
      height: 310,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/kElRGLtQEqZWRWi01c7XEmfPyhi1hoeXImhELVgbE1SQ.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const ADOBE_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/adobe/lobby-freeze_new.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 589,
      y: 268,
      width: 733,
      height: 410,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/PzOCk01hr01kmwdWMfvZSuAPrtfLELAnsPXmlCjJtM7pQ.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const PMSYMPOSIUM_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/symposium/lobby-freeze-new.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1007,
      y: 329,
      width: 442,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://youtu.be/HnuOsmHpexY',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const BIRLASOFTGMM_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/birlasoft/birlasoft_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 650,
      y: 254,
      width: 635,
      height: 355,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/GgtWvpaCmRZRhVTEMrHkuOiWgflBsxVeXTpKJ8wTuH8.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const BIRCLAYS_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/barclays-stage-skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const APPOLLO_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/apollo_Expo -Virtual_Photoop.jpg',
          },
        ],
      },
    },
  ],
};
export const PMSYMPOSIUM_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/symposium/collins-aerospace+photo-op--ref.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
        ],
      },
    },
  ],
};

export const PMSYMPOSIUM_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/symposium/Collins+Aerospace+stall-bg.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 504,
      y: 289,
      width: 530,
      height: 300,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://stream.mux.com/yqig7y01LJ8bKJv3qXcpa6Nu4PusS8fFMp3jQ7eHlI8g.m3u8',
        muted: false,
      },
    },
  ],
};
export const PMSYMPOSIUM_BOOTH3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/symposium/Collins+Aerospace+stall+02-bg.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 504,
      y: 289,
      width: 530,
      height: 300,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://youtu.be/aW2m-BtCJyE',
        muted: false,
      },
    },
  ],
};
export const PMSYMPOSIUM_BOOTH4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/symposium/Collins+Aerospace+stall-03-bg.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 504,
      y: 289,
      width: 530,
      height: 300,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://stream.mux.com/l02ySZkQbeo3daCwl1Y02ZEiDYX4M4CgKNApkXJ6WxyhM.m3u8',
        muted: false,
      },
    },
  ],
};
export const PMSYMPOSIUM_BOOTH5 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/symposium/Collins+Aerospace+stall-04-bg.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 504,
      y: 289,
      width: 530,
      height: 300,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://stream.mux.com/sY11HIwX02OPj02KgEbkUVZjUeL602HCYrvx6fgDGcwvNc.m3u8',
        muted: false,
      },
    },
  ],
};
export const APPOLLO_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Expo+-+Virtual+Video+Room.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 78,
      y: 450,
      width: 405,
      height: 230,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://stream.mux.com/DUOFjVrvaOFHZSCQLiPlGzh102P5btSCIgZRGipkokwY.m3u8',
        muted: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 538,
      y: 450,
      width: 405,
      height: 230,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://www.youtube.com/watch?v=fRsNuYOK_vU&list=PL5P2iniUCgpLdenf4R5UuJuJ19wFQqyrW&ab_channel=ApolloTyres',
        muted: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 995,
      y: 450,
      width: 405,
      height: 230,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://www.youtube.com/watch?v=_NDrz_biMkk&list=PL5P2iniUCgpKrnOgSyTRiYWmln3EM-A6W&ab_channel=ApolloTyres',
        muted: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1455,
      y: 450,
      width: 405,
      height: 230,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://stream.mux.com/TmHwO57S02M3Row02Q00Nbks8DaHkNfFDDjntobyVfF7D00.m3u8',
        muted: true,
      },
    },
  ],
};

export const HEXAGON_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Hexagon_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const HEXAGONNEW_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/hexagon/lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const PEPSICO_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/pepsico/Pepsico_0611_lobby-freeze_new_22.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};

export const DRREDDYSRZAN_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/drreddysRZAN/DrReddy_0711_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 600,
      y: 215,
      width: 710,
      height: 400,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/OsvDMjGNJqeH4VcENSY6sPPffCAM8np1BpQaEjnB274.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const SPEAKERDRYRUNS_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/speakerDryRuns-Lobby-Freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const MICROSOFTGLEAMINDIA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/MicrosoftGleamIndia_Lobby-skin-with-text.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const MOCK_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mock_Lobby_Skin.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 600,
      y: 230,
      width: 700,
      height: 400,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/NsD7mI9enay87SieUFRyWQ7HVOCaqwYjCdkHaeRUl5g.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const SEP29DEMO_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/29_demo_LOBBY_freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 980,
      y: 385,
      width: 410,
      height: 230,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/Av5p49f-zMU',
        muted: false,
      },
    },
  ],
};
export const CHUBB_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/chub_su_lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 550,
      y: 190,
      width: 810,
      height: 439,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/frkEKZnnInv0202gdSTxIknSUw98qQTACiK9DamtgMbTw.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};
export const DERMA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Ederma_lobby_skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 603,
      y: 210,
      width: 700,
      height: 431,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/oZUSsst45CFkDgDtJIzbIYci5AEgrUFGyjDl01PnyN5M.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const MMA_EXPO_OUTER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mma-expo-final-final.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 160,
      y: 250,
      width: 360,
      height: 400,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '59d9fefc-a06f-4ef6-9b7a-298491d66bc0',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 610,
      y: 250,
      width: 340,
      height: 400,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'e2f808a9-1039-44e5-92e0-c3825468fb47',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 980,
      y: 250,
      width: 420,
      height: 400,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '77d856fc-3627-4548-ada9-954401ae1e82',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1460,
      y: 250,
      width: 420,
      height: 400,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '19910d90-9ae3-4f10-b881-b7b7f4eb08ab',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
  ],
};

export const MMA_EXPO_OUTER_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mma-expo-landing-final.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 220,
      y: 350,
      width: 340,
      height: 300,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'f3877673-9d03-44da-ab1c-67fa689d634d'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 610,
      y: 350,
      width: 340,
      height: 300,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '4bf3ba78-cab7-4452-9a2d-eca543a61f42'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 980,
      y: 350,
      width: 420,
      height: 300,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '61835497-c2af-488b-8176-4287fa6b1bb6'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1460,
      y: 350,
      width: 420,
      height: 300,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'ed1ecb75-ce7d-4e76-a19d-a49991662936'
      },
    },
  ],
};

export const ASPENTECH_OUTER_EXPO = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Gallery+1.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: []
};

export const MMA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mma_prod_Lobby_Skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 590,
      y: 250,
      width: 735,
      height: 439,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/rsuv1u372UgGKZv2dBthem5dphmyUGWpTrdY3tFeh38.m3u8',
        muted: false,
        loop: false,
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 670,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 770,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 875,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 975,
      y: 950,
      width: 55,
      height: 50,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1075,
      y: 950,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1180,
      y: 950,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.mmaglobal.com/impactindia2020',
      },
    },
    // Agenda, Speakers, Sponsors
    {
      type: WidgetTypes.HOTSPOT,
      x: 650,
      y: 870,
      width: 150,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-day1-agenda.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 850,
      y: 870,
      width: 200,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-day1-speakers.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1080,
      y: 870,
      width: 200,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-day1-sponsors.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1680,
      y: 970,
      width: 200,
      height: 100,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mma_1.jpg',
          'https://phoenixlive.imgix.net/mma_2.jpg',
          'https://phoenixlive.imgix.net/mma_3.jpg',
          'https://phoenixlive.imgix.net/mma_4.jpg',
          'https://phoenixlive.imgix.net/mma_5.jpg',
          'https://phoenixlive.imgix.net/mma_6.jpg',
          'https://phoenixlive.imgix.net/mma_7.jpg',
          'https://phoenixlive.imgix.net/mma_8.jpg',
          'https://phoenixlive.imgix.net/mma_9.jpg',
          'https://phoenixlive.imgix.net/mma_10.jpg',
          'https://phoenixlive.imgix.net/mma_11.jpg',
          'https://phoenixlive.imgix.net/mma_12.jpg',
          'https://phoenixlive.imgix.net/mma_13.jpg',
          'https://phoenixlive.imgix.net/mma_14.jpg',
          'https://phoenixlive.imgix.net/mma_15.jpg',
          'https://phoenixlive.imgix.net/mma_16.jpg',
          'https://phoenixlive.imgix.net/mma_17.jpg',
          'https://phoenixlive.imgix.net/mma_18.jpg',
          'https://phoenixlive.imgix.net/mma_19.jpg',
          'https://phoenixlive.imgix.net/mma_20.jpg',
          'https://phoenixlive.imgix.net/mma_21.jpg',
          'https://phoenixlive.imgix.net/mma_22.jpg',
          'https://phoenixlive.imgix.net/mma_23.jpg',
          'https://phoenixlive.imgix.net/mma_24.jpg',
          'https://phoenixlive.imgix.net/mma_25.jpg',
          'https://phoenixlive.imgix.net/mma_26.jpg',
          'https://phoenixlive.imgix.net/mma_27.jpg',
          'https://phoenixlive.imgix.net/mma_28.jpg',
          'https://phoenixlive.imgix.net/mma_29.jpg',
          'https://phoenixlive.imgix.net/mma_30.jpg',
        ],
      },
    }
  ],
};


export const AVIGAN_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/avigon-lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 603,
      y: 210,
      width: 700,
      height: 431,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/LeRsmrRZCgriCHTITkV2rD7OQ3GxoSeHzMqCgamFtQY.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const IMPOYZ_UNBRANDED_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/unbranded2-lobby.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 633,
      y: 210,
      width: 660,
      height: 350,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src: 'https://www.youtube.com/watch?v=SuOuYyCxmAA',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const IMPOYZ_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/impoyz-final-lobby.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 633,
      y: 210,
      width: 660,
      height: 350,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/8100RlksHeipUq8sriFRcqMpu3Ah02Pr6qs02KrUvc8026k.m3u8',
        muted: false,
        loop: false,
      },
    },
  ],
};

export const MICROSOFT_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/microsoft-lobby.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1147,
      y: 130,
      width: 618,
      height: 350,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=JBgL-asr4c8&feature=youtu.be',
        muted: false,
      },
    },
  ],
};

export const MICROSOFT_EXPO = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/microsoft-expo.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 748,
      y: 358,
      width: 420,
      height: 233,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=Qi3MxbvngH8',
        muted: false,
      },
    },
  ],
};

export const TAPASYA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/tapasya-lobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 522,
      y: 215,
      width: 900,
      height: 653,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/uXZXm3BbUdKS01tcLKnGdp3vsl200KBiOwLBTAPU1t4v8.m3u8',
        muted: false,
      },
    },
  ],
};

export const REIMAGINE_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/reimagin-lobby-freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 477,
      y: 72,
      width: 957,
      height: 548,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=6HS-ojdATmg',
        muted: false,
      },
    },
  ],
};

export const REIMAGINE_EXPO = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/microsoft-expo.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 748,
      y: 358,
      width: 420,
      height: 233,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=Qi3MxbvngH8',
        muted: false,
      },
    },
  ],
};

export const SUNFEAST_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Sunfeast_2808_lobby-freeze-red.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1000,
      y: 330,
      width: 510,
      height: 287,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/oMLnGuKvuGfFAlUsMUBNx47I9gYvnaSNSBTtgEUptRk.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DRGFM_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/DrReddys-GFM_Lobby-skin.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 970,
      y: 243,
      width: 420,
      height: 260,
      // height: 287,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/ohcsNwo02GYjIEkkMA02gEOiFsetQp4abQZx2Su3p8uWw.m3u8',
        muted: false,
        controls: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 538,
      y: 244,
      width: 420,
      height: 287,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/BT501XHD9G02V2NVeqfvILsxQgXndtkpzbov01TxEWgyeg.m3u8',
        muted: true,
        controls: true,
      },
    },
  ],
};
export const SYNCHRONY_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Synchrony_2709_llobby-freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 550,
      y: 165,
      width: 800,
      height: 500,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/8rkV02rtvJUojlc48iR01jhH7fKb01016zZ5CIP02U4Qc02Ms.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const REALPAGE_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/real-page-lobby-skin.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 692,
      y: 242,
      width: 520,
      height: 260,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/lc1mbsGD9oSg5863d01tqEH2rtK8bQ2IDqoza02QSdM0100.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const TEST_CONFIG = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/lobby-confluence-freeze.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.RICH_TEXT,
      x: (360 * 1920) / 1234,
      y: (546 * 1920) / 1234,
      width: (534 * 1920) / 1234,
      height: (148 * 1920) / 1234,
      colorScheme: WidgetColorSchemeTypes.LIGHT_TEXT,
      config: {
        richText: [
          {
            type: 'paragraph',
            children: [
              { text: 'This is editable ' },
              { text: 'rich', bold: true },
              { text: ' text, ' },
              { text: 'much', italic: true },
              { text: ' better than a ' },
              { text: '<textarea>', code: true },
              { text: '!' },
            ],
          },
          {
            type: 'paragraph',
            children: [
              {
                text:
                  "Since it's rich text, you can do things like turn a selection of text ",
              },
              { text: 'bold', bold: true },
              {
                text:
                  ', or add a semantically rendered block quote in the middle of the page, like this:',
              },
            ],
          },
          {
            type: 'block-quote',
            children: [{ text: 'A wise quote.' }],
          },
          {
            type: 'paragraph',
            children: [{ text: 'Try it out for yourself!' }],
          },
        ],
      },
    },
    {
      type: WidgetTypes.TEXT,
      x: (907 * 1920) / 1234,
      y: (583 * 1920) / 1234,
      width: (300 * 1920) / 1234,
      height: (30 * 1920) / 1234,
      colorScheme: WidgetColorSchemeTypes.LIGHT_TEXT,
      config: {
        text: 'WHAT DO YOU MEANNNNNNN',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (135 * 1920) / 1224,
      y: (199 * 1920) / 1224,
      width: (169 * 1920) / 1224,
      height: (109 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (697 * 1920) / 1224,
      y: (220 * 1920) / 1224,
      width: (132 * 1920) / 1224,
      height: (99 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: (401 * 1920) / 1224,
      y: (376 * 1920) / 1224,
      width: (195 * 1920) / 1224,
      height: (72 * 1920) / 1224,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=lAigvAeOoqQ',
      },
    },
  ],
};

// EXPO CONFIGS
export const AVIGAN_BOOTH1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/avigan_expo1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  zone: 'booth',
  widgets: [],
};

export const AVIGAN_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/avigan_expo2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  zone: 'booth',
  widgets: [],
};
export const MMAPROD_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/MMA_2110_lobby-Freeze-[compressed]-.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 590,
      y: 250,
      width: 735,
      height: 439,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://vimeo.com/470270499',
        muted: false,
        loop: false,
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 670,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 770,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 875,
      y: 950,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 975,
      y: 950,
      width: 55,
      height: 50,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1075,
      y: 950,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1180,
      y: 950,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.mmaglobal.com/impactindia2020',
      },
    },
    // Agenda, Speakers, Sponsors
    {
      type: WidgetTypes.HOTSPOT,
      x: 650,
      y: 870,
      width: 150,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-agenda-day2.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 850,
      y: 870,
      width: 200,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-speakers-day2.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1080,
      y: 870,
      width: 200,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma-day1-sponsors.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1680,
      y: 920,
      width: 200,
      height: 100,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mma_gallery/1.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/2.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/3.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/4.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/5.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/6.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/7.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/8.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/9.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/10.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/11.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/12.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/13.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/14.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/15.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/16.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/17.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/18.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/19.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/20.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/21.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/22.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/23.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/24.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/25.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/26.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/27.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/28.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/29.jpg',
          'https://phoenixlive.imgix.net/mma_gallery/30.jpg',
        ],
      },
    }
  ],
};
export const AVIGAN_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/avigan-frame1-layer1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/avigan-frame-sword-final.png',
          },
        ],
      },
    },
  ],
};

export const AVIGAN_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/avigan-whatsapp-layer1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 400,
            baseHeight: 400,
            offsetTop: 0,
            offsetLeft: 0,
            frame: PhotoboothPreviewFrameTypes.CIRCLE,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/avigan-whatsapp-layer2.png',
          },
        ],
      },
    },
  ],
};
export const APQF_BOOTH1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/APQF_080910_abott-stall.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 508,
      y: 289,
      width: 520,
      height: 300,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/TfLH402h9EDtGCYL1CFfCjj01kMlnacba9PpWg3UnB7fc.m3u8',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
  ],
};
export const CIPLA_NEW_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/cipla_new_Photo-Frame-bottom.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
            circleStyles: { bottom: '0px' },
          },
        ],
      },
    },
  ],
};
export const CIPLA_ACNE_BOOTH1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Anti-acne-stalls_1920-x1080-px.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 1060,
      y: 400,
      width: 750,
      height: 450,
      config: {
        url: 'https://zuddl-tic-tac-toe.netlify.app/',
      },
    },
  ],
};
export const CIPLA_HAIR_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Hair-Care_1920-x1080-px.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 1060,
      y: 400,
      width: 750,
      height: 450,
      config: {
        url: 'https://zuddl-memory-game.netlify.app/',
      },
    },
  ],
};
export const CIPLA_FUNGAL_BOOTH3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Antifungals-Stall_1920-x1080-px.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 1060,
      y: 400,
      width: 750,
      height: 450,
      config: {
        url: 'https://zuddl-astray.netlify.app/',
      },
    },
  ],
};
export const CIPLA_PROTECTIVES_BOOTH4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Protectives_1920 x1080 px-01.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1055,
      y: 336,
      width: 759,
      height: 415,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/OFoyvmYopRR6S9SH2LARWXdWshYJ02tGJN3EXe02fn2tg.m3u8',
        muted: false,
        controls: true,
        autoplay: false,
      },
    },
  ],
};

export const CIPLA_DIGIRECORDS_BOOTH5 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Service Backdrop_4.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [],
};
export const CIPLA_MEDISURE_BOOTH6 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Service Backdrop_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 950,
      y: 255,
      width: 950,
      height: 570,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/WXv8iBXhYfNxTYzjj02gv5foHjEhcyQ601Dc302iZexwxM.m3u8',
        muted: false,
        controls: true,
        autoplay: false,
      },
    },
  ],
};
export const CIPLA_DASI_BOOTH7 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Service Backdrop_2.jpg',
  show_cta: true,
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const CIPLA_CIPLAMED_BOOTH8 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Service Backdrop_3.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [],
};

export const CIPLA_PUBLICATIONGALLERY_BOOTH9 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/DAIS_10-1110_posters-booth-only-bg (1).jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 118,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-01.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-01.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 333,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-02.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-02.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 547,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-03.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-03.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 762,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-04.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-04.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 977,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-05.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-05.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1192,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-06.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-06.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1406,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-07.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-07.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1621,
      y: 318,
      width: 184,
      height: 285,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/poster+thumbs-08.jpg',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/all+posters-08.jpg',
      },
    },
  ],
};


export const CIPLA_QUIZ_BOOTH10 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/quiz-booth-2-c2p-02.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [{
    type: WidgetTypes.HOTSPOT,
    x: 0,
    y: 0,
    width: 1920,
    height: 1080,
    config: {
      url: 'https://quiz.cloudisoportal.co.in/',
    },
  },],
};
export const MMA_INMOBI_BOOTH1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/mma_Inmobi Booth_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 835,
      y: 322,
      width: 350,
      height: 233,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/MzD1xWsv-40',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MMA_EPICGAME_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/mma_Unreal_Engine_Expo.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 820,
      y: 327,
      width: 322,
      height: 218,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/6xbxA8tnlbY',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MMA_GOOGLE_BOOTH3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/mma_Google_Expo.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 808,
      y: 327,
      width: 322,
      height: 218,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          ' https://youtu.be/xyqzUF6xgPc',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MMA_ORACLE_BOOTH4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/mma_Oracle_Expo_Image.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 810,
      y: 342,
      width: 350,
      height: 233,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/_Wp9aKNPO7s',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const DRGFM_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Photo+OP_DGFM+2020.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2212,
            baseHeight: 3148,
            offsetTop: 600,
            offsetLeft: 2970,
            circleStyles: { bottom: '0px' },
          },
        ],
      },
    },
  ],
};
export const SYNCHRONY_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/SYNCHRONY_PHOTOBOOTH1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 5212,
            baseHeight: 3200,
            offsetTop: 446,
            offsetLeft: 191,
            circleStyles: { bottom: '0px' },
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/SYNCHRONY_PHOTOBOOTH1.png',
          },
        ],
      },
    },
  ],
};
export const SYNCHRONY_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Final+Leadership_Photobooth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1200,
            baseHeight: 900,
            offsetTop: 1000,
            offsetLeft: 1300,
            circleStyles: { bottom: '0px' },
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Final+Leadership_Photobooth.png',
          },
        ],
      },
    },
  ],
};

export const SYNCHRONY_PHOTOBOOTH3 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 720,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/Dyi9A5d024tPdSDJYvBglUSpYKNYjdS019mgufbrU5rD00.m3u8',
        muted: false,
        controls: true,
        autoplay: true,
      },
    },
  ],
};
export const SYNCHRONY_PHOTOBOOTH4 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 720,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/Lpd7xlC1vdjRqUSGcSG00rjmDcgqrGF01F01S4bc6gmXbs.m3u8',
        muted: false,
        controls: true,
        autoplay: true,
      },
    },
  ],
};
export const SUNFEAST_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Sun+Photo-Frame.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 760,
            circleStyles: { bottom: '0px' },
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/sun+Photo-Frame-Front+2.png',
          },
        ],
      },
    },
  ],
};

export const REALPAGE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/real-page-layer-1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/real-page-layer-2.png',
          },
        ],
      },
    },
  ],
};

// BRITANNIA
export const BRITANNIA_PURE_MAGIC = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Bourbon+booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 750,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/s37porzRn11hZ02qgO1bpabX6B7disgaa3kzcM5V6bSk.m3u8',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1130,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/Mj59M9SVRnMai00bTIT7MI3CeN1ip0002gO1SyBThl4lQg.m3u8',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
  ],
};

export const BRITANNIA_CAKE = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Cake_booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 750,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=6u9bceoaT28',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1130,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=wz-kJ_JBPZc',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
  ],
};

export const BRITANNIA_CHEESE = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Cheese_Booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 960,
      y: 410,
      width: 475,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/IAB1erurtr00ZggbgCvY79WcaK78ktnyBFD4x4501NMt8.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const BRITANNIA_GOOD_DAY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/GoodDay_booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 960,
      y: 410,
      width: 475,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/aRNvNRDeFn6MTTlO25I00JzXvBUuvXlANkPNvExtrKBY.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const BRITANNIA_MILK_BIKIS = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/MilkBikis_Booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 960,
      y: 410,
      width: 475,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/Milk+Bikis+AV.mov',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const BRITANNIA_NUTRICHOICE = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NutriChoice_Booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 960,
      y: 410,
      width: 475,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/k3hPW84oRDXs15SP00HE01fbvp01cmkZsknAc2OKh1HfPI.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const BRITANNIA_TIGER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Tiger_Booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 960,
      y: 410,
      width: 475,
      height: 250,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/F7bcYrFOM5DAqqt02NIieRNjyimKyXd2e3rqgUFQ00UkA.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const BRITANNIA_TREAT = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Treat_Booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 750,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/phaKHCkDe8bPrG01ztjvNTuO02fs9s75nI02WsZr8ygmsU.m3u8',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1130,
      y: 440,
      width: 325,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/Yif7VrFnmHI027TCEZFAtnnxTo401iNd00Omg2NIZu00QpA.m3u8',
        muted: true,
        controls: true,
        autoplay: false,
      },
    },
  ],
};

export const BRITANNIA_PHOTO1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2732,
            baseHeight: 2048,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/britannia-frame-1.png',
          },
        ],
      },
    },
  ],
};

export const BRITANNIA_BOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/britannia-frame-1.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/britannia-frame-1-layer2.png',
          },
        ],
      },
    },
  ],
};

export const BRITANNIA_BOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/Frame-Background-White-Patch.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1850,
            baseHeight: 1880,
            offsetTop: 165,
            offsetLeft: 200,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/Britannia-Frame-Three-Front.png',
          },
        ],
      },
    },
  ],
};

export const BRITANNIA_BOOTH3 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/britannia-frame3.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/britannia-frame3-layer2.png',
          },
        ],
      },
    },
  ],
};

export const PHARMACO_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/PharmaCo_PhotoFrameback.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/PharmaCo_PhotoFramefront.png',
          },
        ],
      },
    },
  ],
};
export const APP_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Photo-Frame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1812,
            baseHeight: 1348,
            offsetTop: 400,
            offsetLeft: 530,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Photo-Frame.png',
          },
        ],
      },
    },
  ],
};

export const PHARMACO_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/PharmaCo_lobbyfreeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 566,
      y: 192,
      width: 775,
      height: 440,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=oUOM-DI2-EA&ab_channel=MedicalMindset',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_DEMO_BOOTH1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/derma-demo-booth-2.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 975,
      y: 410,
      width: 450,
      height: 255,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=k1S0D_04HP4',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_DEMO_BOOTH2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/derma-demo-booth-3.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 730,
      y: 325,
      width: 475,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=DdBFQEr_Ld4',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_WINDOWS10 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Microsoft_1209_stall_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 535,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/HHe47MlnRVYkEALlzhXVGHZoSlsRtAEdz00BM8ShlDhs.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_OFFICE = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Microsoft_1209_stall_2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 540,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/01hfK68VAgqLumA1sTWvddBTr8hv5E1VMkiM5RFxK5CE.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_SERVER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Microsoft_1209_stall_3.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 540,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/zn01q7xmIwe6RkGyZtfzJaM01rGxWp02Z0002c2M8vXdr601k.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_LENOVO = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Lenove_booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 540,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://lbtfzh-online-events-dev-public.s3.ap-south-1.amazonaws.com/Lenovo_Booth_Video.mp4',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_TATA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Tata_booth.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 540,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/e0001diTlzFFcU22kz9EyZimR7Tp02UyKwNk96Ptjh8OQI.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_PANASONIC = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Panasonic_Stall.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 684,
      y: 248,
      width: 540,
      height: 268,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/hGCzD56HA75CUp2rf3VwIG01se1eLnYDOTQPPdBuUo3A.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MICROSOFT_HP = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/ms_HP_Stall.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 684,
      y: 248,
      width: 540,
      height: 268,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/jV2XSOfHsOQgONBLfplpqfXlHt4BnZWYoLvNxOD9XVY.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_SATYAMEV = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Microsoft_1209_stall_5.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 680,
      y: 250,
      width: 540,
      height: 265,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/fTuQUOkRditHJY01pIJjf5eyt00Dm4a01aYaWTwTTt7S4U.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const ZUDDL_HEXGL = {
  type: 'HEXGL',
};

export const ZUDDL_ASTRAY = {
  type: 'ASTRAY',
};

export const COLGATE_GAME = {
  type: 'COLGATE_GAME'
};

export const NASSCOM_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_new_2_Lobby%20skin%202.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const NASSCOM_GOVT_OF_KARNATAKA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Nasscom_gov_karnataka_Stall_Bg.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1210,
      y: 94,
      width: 675,
      height: 395,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://youtu.be/ppjsYPTo21w',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const NASSCOM_MTDC = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_MTDC_1920X1080_2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [],
};
export const NASSCOM_BOSCH = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_bosch_1920x1080.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 75,
      y: 330,
      width: 830,
      height: 480,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=vMmn2PpfH4c',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_CAPGEMIN = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Capgemini -NASSCOM.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1038,
      y: 336,
      width: 801,
      height: 450,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/X18QCOYCi6gkpCinKjt79MAZDyIBcHyBXAPlyyCFNKU.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_CYIENT = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_CYIENT.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 704,
      y: 215,
      width: 1130,
      height: 636,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/zXsZ5fTHhwZ2BxK7DGA2fGJA5eaHNnZNIrdx01fPDq01s.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_HCL = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_hcl-booth-simulation.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 400,
      y: 60,
      width: 1100,
      height: 600,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/A0001uPeEbEgnDAoTyyKX01901ZCH7Pb01yD23E9syz9kiTg.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_INFOSYS = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Nasscom-infosys.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 957,
      y: 410,
      width: 485,
      height: 260,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/p3qlH01rssQFwWOE6JZp46wZAc2Rq6Xv02o005sDgqgo28.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_LT = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_LT.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 530,
      y: 434,
      width: 860,
      height: 484,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=M-l1aTMUNvc&t=6s',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const NASSCOM_QUESTGLOBAL = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Quest-NASSCOM.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1008,
      y: 500,
      width: 418,
      height: 234,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/8ClxNvUJMKrtEfJTKrnsIlSfQcHWBqRtxIko1Dwtk2o.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const NASSCOM_WIPRO = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_1920x1080_wipro.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 250,
      y: 350,
      width: 540,
      height: 310,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/wu2j8ZcGMUWhk8OqPmRu025uoV98Gif7icaXy3xM2AIk.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const NASSCOM_CARRIER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_CARRIER.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 976,
      y: 414,
      width: 445,
      height: 251,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/1AtGFFPHWK2ZHuKTowo4jC3yPDD1VrtaaXyF8m4P01AY.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_SIEMENS = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_SIEMENS.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1198,
      y: 288,
      width: 700,
      height: 400,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/KwaSw3MPfcpCE6k77PH1ij8vRiaPcPM8IQWuTslGU2Q.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_TCS = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_TCS.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 540,
      y: 410,
      width: 865,
      height: 550,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/ity1d202dGBMWHFojmBc6201SoPWzthnd6z00g1cjwak8c.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_TECHMAHINDRA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_TECHMAHINDRA.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1045,
      y: 116,
      width: 837,
      height: 480,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://www.youtube.com/watch?v=zF9D92Ub_Hw',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_BAVARIA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/NASSCOM_BAVARIA.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 132,
      y: 55,
      width: 845,
      height: 478,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/VRW52lSeUG8V4QPkj7Pxw8J3XZMdMtKQ8EsDuhNcM4Q.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const NASSCOM_SATVEN = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/nasscom_setven.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 670,
      y: 600,
      width: 780,
      height: 490,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src: 'https://stream.mux.com/gkKR0100yp5WdzvGLeB6UxJgj9m502KRooqT42z201uzhgM.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const DERMA_ANTOXID = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Antoxid-HC- Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 975,
      y: 410,
      width: 450,
      height: 255,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/Sj1ya5VhMvbgW8tEnfxQvR93Es502bh00kmiFNXVEppLo.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_ATARAX = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Atarax-Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 734,
      y: 377,
      width: 487,
      height: 274,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/sIImJSYLs901hN98CXE02Tzd62oP4UXBQEpL9xMEkikwA.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_EBERNET = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Ebernet-Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 735,
      y: 340,
      width: 487,
      height: 245,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/TUR364QK1AtLRUogMSgtyjKy6tPZAA59wGDnRWjp5AY.m3u8', // EBERNET VA
        muted: false,
        controls: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1350,
      y: 350,
      width: 313,
      height: 209,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/GkIy2dmxNrv8tiQI02JG3WArYqVIUedhlHwGY7N88bUg.m3u8', // EBERNET VA
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_IMPOYZ = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Impoyz-Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 218,
      y: 340,
      width: 450,
      height: 235,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/Impoyz-GIF-1.gif',
        muted: false,
        controls: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1280,
      y: 340,
      width: 450,
      height: 235,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/Impoyz-GIF-2.gif',
        muted: false,
        controls: true,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 735,
      y: 340,
      width: 487,
      height: 245,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/kwUO9cJ2bIEojhAzKfW5xV6b02cBoVOb9XChhgnFBgn4.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_MEDI_ASSIST = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/MediAssist-Stall-Layout.jpg ',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/p5bliyTosJUT9vMxiG01MDgxlOcvkeURUAJB93i1IWjM.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_MINTOP = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Mintop-Stall-Layout1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 720,
      y: 320,
      width: 482,
      height: 286,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/MloVwOJwUf46LfWJMHbbxj8v8gSL00Uk02GAayVLKt02Iw.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_NEXRET = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Nexret-Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [],
};

export const DERMA_REVIBRA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Revibra-Avarta-Stall-Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 734,
      y: 340,
      width: 487,
      height: 245,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/UF01VWjgK39i1qQKSC3xWMjA01ZCjqhi7uRQieapdi1UA.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_REVILUS = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/Revilus-Range-Stall-Layout1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 720,
      y: 327,
      width: 485,
      height: 272,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/JfTyx01Vh2E3JfXmWgs5sOjY9YfaRRxE00QLgzWqxS9WQ.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_VENUSIA = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Venusia-Stall-Layout1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 571,
      y: 210,
      width: 389,
      height: 470,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/Venusia-Panel-GIF1.gif',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_VENUSIA_DERM = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Venusia-Derm-Stall-Layout1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 760,
      y: 344,
      width: 440,
      height: 329,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/ZjiCPWMmytamYanMrB6XQXXUSvbhZESFp1oa49PRLNY.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_XYZAL = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/Xyzal-Stall-Layout2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 1059,
      y: 412,
      width: 381,
      height: 286,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/58MXqUkzCZdlGTtYYbU3HenynIH7h94nQJOVfueT1pk.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const DERMA_CERTIFICATE = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  widgets: [
    {
      type: WidgetTypes.CERTIFICATE,
    },
  ],
};

export const DERMA_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Photo_Frame_Back_Ederma.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1212,
            baseHeight: 1548,
            offsetTop: 250,
            offsetLeft: 1270,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Photo_Frame_Front_Ederma.png',
          },
        ],
      },
    },
  ],
};

export const APOLLO_AR_BOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.AR_PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        baseWidth: 1062,
        baseHeight: 690,
        effect: 'apollo_ar_booth_1',
        // baseWidth: 1620,
        // baseHeight: 1620,
        // effect: 'apollo_ar_booth_2'
      },
    },
  ],
};

export const MAGNIFIQUE_AR_BOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.AR_PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        baseWidth: 2448,
        baseHeight: 2732,
        effect: 'synchronydeepar2',
        // baseWidth: 1620,
        // baseHeight: 1620,
        // effect: 'apollo_ar_booth_2'
      },
    },
  ],
};

export const APOLLO_AR_BOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.AR_PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        baseWidth: 1620,
        baseHeight: 1620,
        effect: 'iotar2'
      },
    },
  ],
};

export const APOLLO_AR_BOOTH3 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.AR_PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        baseWidth: 1062,
        baseHeight: 690,
        effect: 'iotar1'
      },
    },
  ],
};

export const APOLLO_FOUR_WHEELER_BOOTH3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/apollo/8.effectsResult_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 718,
      y: 265,
      width: 484,
      height: 282,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/1G01EWYJvm2W2loeNbpmc7ZQf00ssuQMpk8wRbchUDxL00.m3u8',
        muted: false,
        loop: false,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 118,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Alnac 4G.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 547,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Aspire.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1220,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Apterra HT2.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1645,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png ',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Apterra AT2.jpg',
      },
    }
  ],
};
export const APOLLO_TWO_WHEELER_BOOTH4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/apollo/9.RGB_color_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 718,
      y: 265,
      width: 488,
      height: 282,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://www.youtube.com/watch?v=_NDrz_biMkk&list=PL5P2iniUCgpKrnOgSyTRiYWmln3EM-A6W&ab_channel=ApolloTyres',
        muted: false,
        loop: false,
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 118,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Actigrip R4.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 547,
      y: 530,
      width: 150,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Actigrip S5.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1220,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Alpha H1.jpg',
      },
    },
    {
      type: WidgetTypes.MEDIA,
      x: 1655,
      y: 530,
      width: 140,
      height: 150,
      config: {
        type: MediaWidgetTypes.IMAGE,
        src: 'https://phoenixlive.imgix.net/apollo/img.png',
        fullScreenOnClick: true,
        fullScreenUrl: 'https://phoenixlive.imgix.net/apollo/Alpha S1.jpg',
      },
    }
  ],
};
export const PEPSICO_BOOTH_1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/7.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 837,
      y: 342,
      width: 330,
      height: 233,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://vimeo.com/474748074',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const PEPSICO_BOOTH_2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/5.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    // {
    //   type: WidgetTypes.MEDIA,
    //   x: 772,
    //   y: 368,
    //   width: 310,
    //   height: 183,
    //   config: {
    //     type: MediaWidgetTypes.VIDEO,
    //     src:
    //       'https://youtu.be/oMTFaan7M9c',
    //     muted: false,
    //     controls: true,
    //   },
    // },
  ],
};
export const PEPSICO_BOOTH_3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 853,
      y: 338,
      width: 310,
      height: 183,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/T95BwiTKk7o',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const PEPSICO_BOOTH_4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/4.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [],
};
export const PEPSICO_BOOTH_5 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 800,
      y: 330,
      width: 350,
      height: 200,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/Jw2QFtodj9UbI8p1NaCPRQ8m7q5pnd016r8L61Y9j4So.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const PEPSICO_BOOTH_6 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/3.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 800,
      y: 330,
      width: 310,
      height: 183,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=xr0E1AJwaos',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const PEPSICO_BOOTH_7 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/pepsico/6.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 820,
      y: 380,
      width: 310,
      height: 183,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=h-KddnG792Y',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const PEPSICO_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/pepsico/photo-op.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1722,
            baseHeight: 978,
            offsetTop: 350,
            offsetLeft: 140,
          },
        ],
      },
    },
  ],
};
export const PEPSICO_EXPO_OUTER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/pepsico/Revised+Expo+Layout.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 160,
      y: 390,
      width: 200,
      height: 170,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'bec88f98-fb37-4f9d-8996-92589317313d'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 360,
      y: 580,
      width: 290,
      height: 230,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '1e00b3aa-443d-4db5-96ce-84556602cf4a'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 585,
      width: 290,
      height: 230,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '9a617393-6928-4774-afc6-1c9b67847d78'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 480,
      y: 330,
      width: 240,
      height: 180,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '2d2feacb-1e3a-4a15-a369-802cd1ef21e3'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 782,
      y: 270,
      width: 200,
      height: 155,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'b6e3dcbf-f71f-4e3b-af13-51e68dadb731'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1052,
      y: 270,
      width: 200,
      height: 155,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'bc600cde-3673-47d0-b120-9a7ae6aff5d3'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1320,
      y: 340,
      width: 220,
      height: 180,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'f07841f9-1c88-411a-87a5-f24a824b36ca'
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1640,
      y: 380,
      width: 240,
      height: 180,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '31ad249b-2a09-4526-b1f4-5229bbea4645'
      },
    },
  ],
};
export const ADOBE_BOOTH_1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/adobe/Ben_1.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 650,
      width: 400,
      height: 90,
      config: {
        url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4dILHQrilQlIl-zoxf6ZmA9URDFSQTI5WVNUOElJTlpRTzFCQzRVOURIUS4u',
      },
    },
  ],
};
export const ADOBE_BOOTH_2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/adobe/Car_2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 650,
      width: 400,
      height: 90,
      config: {
        url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4dILHQrilQlIl-zoxf6ZmA9UMUZVRkI2N0QwOFU3MjcyQUpFQUo0UEIzWS4u',
      },
    },
  ],
};
export const ADOBE_BOOTH_3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/adobe/Game_3.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 400,
      width: 450,
      height: 250,
      config: {
        url: 'https://zuddl-tic-tac-toe.netlify.app/',
      },
    },
  ],
};
export const ADOBE_BOOTH_4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/adobe/Net_4.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 650,
      width: 400,
      height: 90,
      config: {
        url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4dILHQrilQlIl-zoxf6ZmA9UMkNVUzhDSlpPWjAwSTRBUEtSTUZGTk1SQi4u',
      },
    },
  ],
};
export const ADOBE_BOOTH_5 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/adobe/Patent2_new.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: false,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 160,
      width: 910,
      height: 533,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/shNaR19B5LC01ovJElLpNDoIncn02EV89DNPWa01xPU02cM.m3u8',
        muted: false,
        controls: true,
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 765,
      y: 870,
      width: 400,
      height: 90,
      config: {
        url: 'https://forms.office.com/Pages/ResponsePage.aspx?id=Wht7-jR7h0OUrtLBeN7O4dILHQrilQlIl-zoxf6ZmA9UMEwwVjJRRlVVQ0kzNU5KUlZJRUVTN0NSNi4u',
      },
    },
  ],
};
export const DRREDDYSRZAN_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/drreddysRZAN/Photo+Booth-revised_111.jpg',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1220,
            baseHeight: 1558,
            offsetTop: 250,
            offsetLeft: 1270,
          },
        ],
      },
    },
  ],
};
export const ADOBE_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/adobe/Photo+Frame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1220,
            baseHeight: 1158,
            offsetTop: 200,
            offsetLeft: 470,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/adobe/Photo+Frame.png',
          },
        ],
      },
    },
  ],
};
export const HEXAGON_BOOTH_1 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-00.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 720,
      y: 320,
      width: 500,
      height: 270,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_2 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-01.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=1P-w6iNsBac&feature=youtu.be',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_3 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-02.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_4 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-06.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/oIMRUy8_cd0',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_5 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-03.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_6 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-09.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=TjmB8JI2_uM&feature=emb_logo',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_7 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-04.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_8 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-07.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_9 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-08.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=r1yFR65EYpg',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_10 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-10.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=arCZK0HeKoU&feature=youtu.be',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_11 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-05.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://youtu.be/sjyTHw-tU5k',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const HEXAGON_BOOTH_12 = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl: 'https://phoenixlive.imgix.net/hexagon/hexagon-stall-11.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  show_cta: true,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 507,
      y: 295,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://www.youtube.com/watch?v=E9KHfgqbIxQ',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MMAINDONESIAIMPACT_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/05f0742b-be79-4bb7-b281-b52f4bf59cf2.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 585,
      y: 252,
      width: 739,
      height: 430,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/tAiERfWL8QKsQVk01kgV1JNwNjkuUjbF38hHkBItM8XA.m3u8',
        muted: false,
        loop: false,
      },
    },
    //  Sponsors
    {
      type: WidgetTypes.HOTSPOT,
      x: 883,
      y: 850,
      width: 145,
      height: 30,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma_Impact_2019/SPONSORS+(1).jpg',
      },
    },
    // BOARD MEMBERS
    {
      type: WidgetTypes.HOTSPOT,
      x: 680,
      y: 870,
      width: 180,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma_Impact_2019/MMABOARD+MEMBERS.jpg',
      },
    },
    //  Speakers
    {
      type: WidgetTypes.HOTSPOT,
      x: 883,
      y: 890,
      width: 145,
      height: 30,
      config: {
        url: 'https://www.mmaglobal.com/impactindonesia2020#speakers',
      },
    },
    // MEDIA PARTNERS
    {
      type: WidgetTypes.HOTSPOT,
      x: 1050,
      y: 870,
      width: 190,
      height: 60,
      config: {
        imgSrc: 'https://phoenixlive.imgix.net/mma_Impact_2019/MMA+MEDIA+PARTNERS.jpg',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 675,
      y: 940,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 770,
      y: 940,
      width: 50,
      height: 50,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 940,
      width: 50,
      height: 50,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 990,
      y: 940,
      width: 55,
      height: 50,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1092,
      y: 940,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1187,
      y: 940,
      width: 60,
      height: 50,
      config: {
        url: 'https://www.mmaglobal.com/impactindonesia2020',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1680,
      y: 920,
      width: 200,
      height: 100,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7845.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7852.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7854.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7861.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7866.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7903.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7913.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7915.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7963.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A7971.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8013.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8022.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8041.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8092.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8103.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8119.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8147.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8175.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8186.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8213.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8231.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8245.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8293.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8343.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8372.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8397.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8417.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8448.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8452.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8493.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8518.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8541.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8597.JPG',
          'https://phoenixlive.imgix.net/mma_Impact_2019/092A8611.JPG',
        ],
      },
    }
  ],
};
export const MMAINDONESIAIMPACT_EXPO_OUTER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mma_Impact_2019/Expo+Landing+page_new.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  zone: 'booth',
  widgets: [
    // google
    {
      type: WidgetTypes.HOTSPOT,
      x: 180,
      y: 550,
      width: 520,
      height: 270,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'd4c35631-cf4d-47c5-9020-a3458ace55a9',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    // mma
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 550,
      width: 530,
      height: 280,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '41b434b6-753f-4e1f-98ff-f058d919b5a3',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    // The TradeDesk
    {
      type: WidgetTypes.HOTSPOT,
      x: 170,
      y: 385,
      width: 185,
      height: 150,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '5008c182-d43a-452a-a6e2-4819363c8e6d',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    //INMOBI
    {
      type: WidgetTypes.HOTSPOT,
      x: 680,
      y: 400,
      width: 200,
      height: 150,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '21b8c27b-c66e-4399-9a4b-4f12f4ff7c26',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    // cityvision
    {
      type: WidgetTypes.HOTSPOT,
      x: 1082,
      y: 400,
      width: 200,
      height: 155,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: '19b59901-b6bd-4a02-b460-917502852748',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
    //vidio
    {
      type: WidgetTypes.HOTSPOT,
      x: 1582,
      y: 400,
      width: 220,
      height: 140,
      config: {
        zone: zoneTypes.EXPO,
        zoneRefId: 'b10d8540-85e6-4929-b508-d3aa551ebb5a',
        buttonLinkType: ILinkType.INTERNAL
      },
    },
  ],
};
export const MAGNIFIQUE_PHOTOBOOTH_1 = {
  type: 'DIGITAL_PHOTO',
  url: 'https://vmrdaplots.com/synchrony/vpb1/index.html'
};
export const MAGNIFIQUE_PHOTOBOOTH_2 = {
  type: 'DIGITAL_PHOTO',
  url: 'https://vmrdaplots.com/synchrony/vpb2/index.html'
};
export const IAA_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/iaa/Photo-Frame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2420,
            baseHeight: 1358,
            offsetTop: 450,
            offsetLeft: 170,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/iaa/Photo-Frame.png',
          },
        ],
      },
    },
  ],
};

export const MMAINDIASMARTIES_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/6a94448b-93b1-43ec-813d-7f5eee639fdd.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 723,
      y: 312,
      width: 480,
      height: 260,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/iKrCZBMjUmHVy01rGPGTsrppG501riC00OgKionsfILF5A.m3u8',
        muted: false,
        loop: false,
      },
    },
    // Jury Members & Pre- Screeners
    {
      type: WidgetTypes.HOTSPOT,
      x: 597,
      y: 882,
      width: 490,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/india/jury',
      },
    },
    // Shortlist
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 882,
      width: 190,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/india',
      },
    },
    //FB
    {
      type: WidgetTypes.HOTSPOT,
      x: 737,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    //twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 884,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 955,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1035,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/india',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1610,
      y: 870,
      width: 200,
      height: 80,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/1.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/2.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/3.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/4.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/5.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/6.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/7.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/8.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/9.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/10.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/11.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/12.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/13.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/14.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/15.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/16.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/17.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/18.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/19.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/20.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/21.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/22.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/23.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/24.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/25.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/26.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/27.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/28.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/29.jpg',
          'https://phoenixlive.imgix.net/mma_smarties/Gallery/30.jpg',
        ],
      },
    }
  ],
};
export const SPEAKERDRYRUNS_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Frame-11111111.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2420,
            baseHeight: 1358,
            offsetTop: 450,
            offsetLeft: 170,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/Frame-11111111.png',
          },
        ],
      },
    },
  ],
};

export const MMASMARTIESINDONESIA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/mma_Lobby+Freeze.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 723,
      y: 312,
      width: 480,
      height: 260,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://youtu.be/f-eLfuLNf_M',
        muted: false,
        loop: false,
      },
    },
    // Jury Members & Pre- Screeners
    {
      type: WidgetTypes.HOTSPOT,
      x: 597,
      y: 882,
      width: 490,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/indonesia/jury',
      },
    },
    // Shortlist
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 882,
      width: 190,
      height: 60,
      config: {
        url: ' https://www.mmaglobal.com/smarties/awards/programs/indonesia',
      },
    },
    //FB
    {
      type: WidgetTypes.HOTSPOT,
      x: 737,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    //twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 884,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 955,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1035,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: ' https://www.mmaglobal.com/smarties/awards/programs/indonesia',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1610,
      y: 870,
      width: 200,
      height: 80,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8730.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8739.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8741.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8748.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8764.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8767.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8772.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8774.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8780.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8785.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8792.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8795.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8817.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8836 (1).JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8845.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8854.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8863.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8947.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8974.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A8980.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9010.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9069.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9078.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9095.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9118.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9133.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9155.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9193.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9202.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9217.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9278.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9399.JPG',
          'https://phoenixlive.imgix.net/mmaSmartiesIndonesia/SMARTIES+PHOTO/092A9453.JPG',
        ],
      },
    }
  ],
};
export const PEPSICOTM_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/pepsicotsmphotoop-01.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 5420,
            baseHeight: 3358,
            offsetTop: 450,
            offsetLeft: 170,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/pepsicotsmphotoop-01.png',
          },
        ],
      },
    },
  ],
};
export const MMASMARTIESAPAC_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/f9d06589-918b-4107-adb4-d08937477557.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 723,
      y: 312,
      width: 480,
      height: 260,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/Qwbs00WJ01JMLIEqA9Grj4LqhTdHzJCmc01VUZS800jpPFg.m3u8',
        muted: false,
        loop: false,
      },
    },
    // Jury Members & Pre- Screeners
    {
      type: WidgetTypes.HOTSPOT,
      x: 597,
      y: 882,
      width: 490,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/apac/jury',
      },
    },
    // Shortlist
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 882,
      width: 190,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/apac',
      },
    },
    //FB
    {
      type: WidgetTypes.HOTSPOT,
      x: 737,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    //twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    //linkedin
    {
      type: WidgetTypes.HOTSPOT,
      x: 884,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    //youtube
    {
      type: WidgetTypes.HOTSPOT,
      x: 955,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    //instagram
    {
      type: WidgetTypes.HOTSPOT,
      x: 1035,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 963,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/apac',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1610,
      y: 870,
      width: 200,
      height: 80,
      config: {
        images: [
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52418_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52424_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52426_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52429_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52430_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52431_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52433_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52435_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52436_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52437_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52443_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52444_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52448_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52451_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52471_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52472_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52473_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52474_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52475_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52476_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52477_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52478_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52479_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52480_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52481_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52482_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52484_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52485_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52486_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52487_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52488_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52489_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52490_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52493_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52508_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52509_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52511_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52512_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52513_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52514_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52515_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52516_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52517_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52518_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52519_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52520_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52521_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52522_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52523_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52524_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52525_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52526_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52532_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52537_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52540_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52541_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52542_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52543_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52544_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52545_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52546_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52547_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52548_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52549_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52550_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52551_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52552_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52553_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52554_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52555_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52556_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52557_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52558_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52559_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52560_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52561_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52565_0.jpg',
          'https://phoenixlive.imgix.net/MMA_SMARTIES_APAC_2019/IMG_52566_0.jpg',
        ],
      },
    }
  ],
};
export const MMASMARTIEEMEA_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/mmaEMEA/Lobby+Freeze+-+final.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    // emea shortlistc
    {
      type: WidgetTypes.HOTSPOT,
      x: 457,
      y: 848,
      width: 280,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/smarties/awards/programs/emea',
      },
    },
    // South Africa Shortlist
    {
      type: WidgetTypes.HOTSPOT,
      x: 760,
      y: 848,
      width: 380,
      height: 60,
      config: {
        url: 'https://www.mmaglobal.com/files/documents/mma_sa_smarties_phase_1_shortlist.pdf',
      },
    },
    // turiye-shortlistc
    {
      type: WidgetTypes.HOTSPOT,
      x: 1165,
      y: 848,
      width: 300,
      height: 60,
      config: {
        url: 'https://www.mmaturkey.org/post/smarties-turiye-shortlistc',
      },
    },
    //linkedin
    {
      type: WidgetTypes.HOTSPOT,
      x: 768,
      y: 938,
      width: 57,
      height: 60,
      config: {
        url: 'http://www.linkedin.com/company/mma-turkey',
      },
    },
    //twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 850,
      y: 938,
      width: 57,
      height: 60,
      config: {
        url: 'https://twitter.com/MobileInSA',
      },
    },
    //linkedin
    {
      type: WidgetTypes.HOTSPOT,
      x: 935,
      y: 938,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.linkedin.com/company/mobileinsa/',
      },
    },
    //youtube
    {
      type: WidgetTypes.HOTSPOT,
      x: 1017,
      y: 938,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.youtube.com/channel/UCjCdRT1X-6HoFdCcK3Ryq6g',
      },
    },
    //linkedin
    {
      type: WidgetTypes.HOTSPOT,
      x: 1098,
      y: 943,
      width: 57,
      height: 60,
      config: {
        url: 'http://linkedin.com/company/mma-emea',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1610,
      y: 840,
      width: 200,
      height: 80,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mmaEMEA/BBVA_SPN.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/COCACOLA_EMEA.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/GRINCH_SPN.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/IMG-20191014-WA0318.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/IMG-20191014-WA0348.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/IMG-20191015-WA0010.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MCDONALDS_SPN.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MENA All winners 2019.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MENA Best in Show winner Rakbank 2019.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MMA 2020 Awarding-2291.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MMA 2020 Awarding-2323.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MMA SA Agency of Year 2019.jpg',
          'https://phoenixlive.imgix.net/mmaEMEA/MMA SA Agency to watch 2019.jpg',
        ],
      },
    }
  ],
};
export const PEPSICOTMSC_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/fc7d6a36-cb6a-4498-8972-1abbb5cdde92.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 590,
      y: 168,
      width: 758,
      height: 395,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/8FrvMLI00fqqgGXQ00Jyom00RGymQN2RzZCUQZlAOLg5Nw.m3u8',
        muted: false,
        loop: false,
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 490,
      y: 890,
      width: 200,
      height: 60,
      config: {
        images: [
          'https://phoenixlive.imgix.net/pepiscoTSM/pepisco_TSM_AGENDA-01.jpg',
        ],
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 737,
      y: 890,
      width: 110,
      height: 60,
      config: {
        images: [
          'https://phoenixlive.imgix.net/pepiscoTSM/FAQs.png',
        ],
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 898,
      y: 890,
      width: 165,
      height: 60,
      config: {
        images: [
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3523.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3571.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3582.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3598.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3600.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3604.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3605.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3611.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3622.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3629.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3637.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3643.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3646.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3649.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3678.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3680.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3683.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3688.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3730.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3750.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3782.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3783.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3817.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3819.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3820.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3834.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3862.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3867.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3924.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3935.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L3991.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4009.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4020.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4033.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4044.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4097.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4121.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4138.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4154.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4166.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4171.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4175.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4220.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4223.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4231.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4239.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4252.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4280.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4289.JPG',
          'https://phoenixlive.imgix.net/pepiscoTSM/Gallery/FS1L4292.JPG',

        ],
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1105,
      y: 890,
      width: 250,
      height: 60,
      config: {
        url: 'https://app.zuddl.com/l/event/583aec77-6dd8-41f0-8848-b1a356dd44ef/booth/7fe01e10-966e-4f22-bf17-9a1a6c2d6689?idx=0',
      },
    },
  ]
};



export const CLEARTAX_BOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/02MnVc602LXcHjNF6LTiQ01FFK5UjoqViivfleh3zqrFd4.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const CLEARTAX_BOOTH_2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/WBz00001TJBDUFhd4NTJRJscKgHeHteAw1DVoyo01ag00Eo.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const CLEARTAX_BOOTH_3 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/01XEHDobPJeycgkroggI9p01wedj3RkiivT8KGK02aTDiA.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const CLEARTAX_BOOTH_4 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/yKEwijim7y02dsVh1ZQS8tLUHe1p01USCNt4IqCyVuJ00M.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const CLEARTAX_BOOTH_5 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/ki02Pa5oTJli027EY40155a36HjnhAaLq894t2orkjJ35M.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const CLEARTAX_BOOTH_6 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/nAPwRgvYewBjg33ZkUFahbp02eN5YRmFwV02Qm54kiQQg.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};

export const MICROSOFT_TRANSCEND_BOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/stci-frame-1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2733,
            baseHeight: 2049,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/stci-frame-1.png',
          },
        ],
      },
    },
  ],
};

export const MICROSOFT_TRANSCEND_BOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/stci-frame-2.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2346,
            baseHeight: 1759,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/stci-frame-2.png',
          },
        ],
      },
    },
  ],
};

export const MICROSOFT_ED_OFFSITE = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/microsoft_ED_Photo_Frame2.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2732,
            baseHeight: 2048,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/microsoft_ED_Photo_Frame2.png',
          },
        ],
      },
    },
  ],
};

export const ZUDDLMOCK_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-Photo-Frame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 5420,
            baseHeight: 2900,
            offsetTop: 820,
            offsetLeft: 170,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-Photo-Frame.png',
          },
        ],
      },
    },
  ],
};

export const ZUDDLMOCK_BOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 520,
      height: 290,
      config: {
        type: MediaWidgetTypes.VIDEO,
        src:
          'https://stream.mux.com/FBxmD9qMBrJorDYuuehvVE5mQEu2tM9QMOLPwl01Tf4U.m3u8',
        muted: false,
        controls: true,
      },
    },
  ],
};
export const MICROSOFT_DIGITAL_BOOTH_1 = {
  type: 'DIGITAL_PHOTO',
  url: 'https://www.virtualolympicgames.net/'
};
export const MICROSOFT_DIGITAL_BOOTH_2 = {
  type: 'DIGITAL_PHOTO',
  url: 'https://www.justkapture.com/photobooth/demo/corporate/corporate.html'
};
export const VIRTUAL_OLYMPIC_GAMES = {
  type: 'DIGITAL_PHOTO',
  url: 'https://www.virtualolympicgames.net/'
}

export const MICROSOFT_GLEAM_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/microsoft_gleam_Photoframe_2.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 582,
            baseHeight: 348,
            offsetTop: 85,
            offsetLeft: 40,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/microsoft_gleam_Photoframe_2.png',
          },
        ],
      },
    },
  ],
};

export const ELITE_EXCELLENCE_PHOTOBOOTH_1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/eliteExcellencephotobooth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1800,
            baseHeight: 950,
            offsetTop: 85,
            offsetLeft: 40,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/eliteExcellencephotobooth.png',
          },
        ],
      },
    },
  ],
};

export const ESSILOR_IGNITE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/essilor-ignite-photo-booth-01.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1732,
            baseHeight: 2048,
            offsetTop: 0,
            offsetLeft: 800,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/essilor-ignite-photo-booth-01.png',
          },
        ],
      },
    },
  ],
};
export const MMARI2021_LOBBY = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/f413cc51-b60e-4b63-966e-75227657a86a.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 563,
      y: 182,
      width: 780,
      height: 460,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:
          'https://stream.mux.com/yRKZ77fnVoYkXqPO8ewwtiBqsWHDwct6ZuDaOQfzB8I.m3u8',
        muted: false,
        loop: false,
      },
    },

    // FB
    {
      type: WidgetTypes.HOTSPOT,
      x: 780,
      y: 919,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.facebook.com/apac.mma',
      },
    },
    // twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 855,
      y: 919,
      width: 57,
      height: 60,
      config: {
        url: 'https://twitter.com/MMA_APAC',
      },
    },
    // linkedin
    {
      type: WidgetTypes.HOTSPOT,
      x: 929,
      y: 919,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.linkedin.com/company/mma-apac/?viewAsMember=true',
      },
    },
    // youtube
    {
      type: WidgetTypes.HOTSPOT,
      x: 1000,
      y: 919,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.youtube.com/channel/UClbWFz8S49EJKaAR9xJhI9w',
      },
    },
    // instagram
    {
      type: WidgetTypes.HOTSPOT,
      x: 1074,
      y: 919,
      width: 57,
      height: 60,
      config: {
        url: 'https://www.instagram.com/apac.mma/?hl=en',
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1540,
      y: 899,
      width: 200,
      height: 80,
      config: {
        images: [
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-1.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-10.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-11.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-12.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-13.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-14.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-15.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-16.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-17.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-18(1).jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-18.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-19.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-2.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-3.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-4.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-5.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-6.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-7.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-8.jpg',
          'https://phoenixlive.imgix.net/mmari/galleryimages/MMA Ramadan Insights 2020-9.jpg',
        ],
      },
    },
  ],
};
export const WHITEHAT_JS_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/whitehat_photo-booth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1000,
            baseHeight: 1000,
            offsetTop: 0,
            offsetLeft: 400,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/whitehat_photo-booth.png',
          },
        ],
      },
    },
  ],
};
export const ZUDDL_VO_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-vo/zvo-photo-booth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1032,
            baseHeight: 848,
            offsetTop: 150,
            offsetLeft: 350,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-vo/zvo-photo-booth.png',
          },
        ],
      },
    },
  ],
};
export const WHITE_HAT_JR_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/whitehatjr-Photobooth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1582,
            baseHeight: 1448,
            offsetTop: 250,
            offsetLeft: 575,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/whitehatjr-Photobooth.png',
          },
        ],
      },
    },
  ],
};
export const TIK_TOK_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/tiktok_photo-booth-frame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1032,
            baseHeight: 848,
            offsetTop: 150,
            offsetLeft: 850,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/tiktok_photo-booth-frame.png',
          },
        ],
      },
    },
  ],
};
export const SAAS_INSIDER_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/saas_insider_photframe-3.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1348,
            offsetTop: 355,
            offsetLeft: 1250,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/saas_insider_photframe-3.png',
          },
        ],
      },
    },
  ],
};
export const PHOENIX_LIVE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/phoenixlive-PhotoFrame.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1348,
            offsetTop: 355,
            offsetLeft: 650,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/phoenixlive-PhotoFrame.png',
          },
        ],
      },
    },
  ],
};
export const TOY_INSIDER_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/TOY_INSIDER_Photobooth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1548,
            offsetTop: 305,
            offsetLeft: 650,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/TOY_INSIDER_Photobooth.png',
          },
        ],
      },
    },
  ],
};
export const ZUDDL_VO_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-vo/zuddl_1y_photo_booth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1448,
            offsetTop: 350,
            offsetLeft: 1200,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/zuddl-vo/zuddl_1y_photo_booth.png',
          },
        ],
      },
    },
  ],
};
export const ZUDDL_PHOTOBOOTH10 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/077e573a-0499-454a-a650-48acaad28573.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 600,
            offsetTop: 40,
            offsetLeft: 700,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/077e573a-0499-454a-a650-48acaad28573.png',
          },
        ],
      },
    },
  ],
};
export const Zuddl_HR_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/76ee84f8-1c76-4e21-bf5e-bd0df9a82a19.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 750,
            baseHeight: 630,
            offsetTop: 35,
            offsetLeft: 670,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/76ee84f8-1c76-4e21-bf5e-bd0df9a82a19.png',
          },
        ],
      },
    },
  ],
};
export const GT8th_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/217e780d-366a-45af-acc2-0c4f3cf877cf',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 720,
            baseHeight: 845,
            offsetTop: 0,
            offsetLeft: 780,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/217e780d-366a-45af-acc2-0c4f3cf877cf',
          },
        ],
      },
    },
  ],
};
export const KPMGCH2023_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/cf694378-1b74-42bb-92a2-342e0c319671',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 3400,
            baseHeight: 3400,
            offsetTop: 588,
            offsetLeft: 287,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/cf694378-1b74-42bb-92a2-342e0c319671',
          },
        ],
      },
    },
  ],
};
export const KPMGCH2023_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/JRLFK9CQ7r01FsiCKR400Ni9XbrK15I200ekbXp5cQWhto.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Auto Profil
    {
      type: WidgetTypes.HOTSPOT,
      x: 259,
      y: 230,
      width: 186,
      height: 140,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '67186bbf-e2f7-46bd-9ffc-40e26c713d87',
        buttonLinkType: "internal"
      },
    },
    //CIA Wizards
    {
      type: WidgetTypes.HOTSPOT,
      x: 570,
      y: 230,
      width: 186,
      height: 140,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd2bdb91e-68ca-4d92-a11d-b430bc22dad8',
        buttonLinkType: "internal"
      },
    },
    //M&A Bot
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 230,
      width: 186,
      height: 140,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '28e3b00e-fcbb-4da4-866a-9b33235bc731',
        buttonLinkType: "internal"
      },
    },
    //ProBuilder
    {
      type: WidgetTypes.HOTSPOT,
      x: 1190,
      y: 230,
      width: 186,
      height: 140,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '89162caf-e88a-41b0-aa1e-203aebfd9354',
        buttonLinkType: "internal"
      },
    },
    //R Bot
    {
      type: WidgetTypes.HOTSPOT,
      x: 1490,
      y: 230,
      width: 186,
      height: 140,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e4fb0e11-0a23-45df-bbc4-d6af8011e80b',
        buttonLinkType: "internal"
      },
    },
    //Power Platform
    {
      type: WidgetTypes.HOTSPOT,
      x: 164,
      y: 440,
      width: 207,
      height: 162,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f966f5ab-be05-479a-b119-fa604c9c48dc',
        buttonLinkType: "internal"
      },
    },
    //Powered Reporting
    {
      type: WidgetTypes.HOTSPOT,
      x: 514,
      y: 440,
      width: 207,
      height: 162,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a9822602-fb6b-476f-a43f-07f6cecf8783',
        buttonLinkType: "internal"
      },
    },
    //KM AutoBot
    {
      type: WidgetTypes.HOTSPOT,
      x: 862,
      y: 440,
      width: 207,
      height: 162,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a2f5cadd-a200-4af3-85ad-309dd911179f',
        buttonLinkType: "internal"
      },
    },
    //Status Station
    {
      type: WidgetTypes.HOTSPOT,
      x: 1205,
      y: 440,
      width: 207,
      height: 162,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '500e95d8-d78b-4777-a13a-b0e69f0486d3',
        buttonLinkType: "internal"
      },
    },
    //Summus Bot
    {
      type: WidgetTypes.HOTSPOT,
      x: 1546,
      y: 440,
      width: 207,
      height: 162,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '364ecfc6-e972-481b-aaf7-4413ef95d820',
        buttonLinkType: "internal"
      },
    },
    //Email Generator
    {
      type: WidgetTypes.HOTSPOT,
      x: 150,
      y: 710,
      width: 220,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8e52006f-5c39-424e-8724-928fae48a5ad',
        buttonLinkType: "internal"
      },
    },
    //Monthly Dashboard
    {
      type: WidgetTypes.HOTSPOT,
      x: 492,
      y: 710,
      width: 220,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'fb880da9-a848-4487-9dea-00c0de4b754c',
        buttonLinkType: "internal"
      },
    },
    //Benchmarking Fusion
    {
      type: WidgetTypes.HOTSPOT,
      x: 1247,
      y: 710,
      width: 220,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0cdd21d7-6608-4521-85a3-630b870e3c13',
        buttonLinkType: "internal"
      },
    },
    //Create Automate
    {
      type: WidgetTypes.HOTSPOT,
      x: 1568,
      y: 710,
      width: 220,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '43f20eb9-c962-4c77-a6b6-fcc7ce26cfaf',
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 931,
      y: 690,
      width: 85,
      height: 190,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'ccd453b8-80e9-45b1-9a4e-19b54aba5871',
        buttonLinkType: "internal"
      },
    },
  ],
};

export const POSTSCRIPT_1 = {
    type: 'GRID',
    bgWidth: '100%',
    bgHeight: '100%',
    zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
     layers: [
      {
      type: PhotoboothLayerTypes.IMAGE,
      src: 'https://phoenixlive.imgix.net/cffe7b16-8a79-40e0-abef-817481669793',
    },
    {
      type: PhotoboothLayerTypes.PREVIEW,
      baseWidth: 889,
      baseHeight: 874,
      offsetTop: 217,
      offsetLeft: 1497,
    },
    {
      type: PhotoboothLayerTypes.IMAGE,
      src: 'https://phoenixlive.imgix.net/cffe7b16-8a79-40e0-abef-817481669793',
    },
        ],
      },
    },
  ],
  };


export const PROPEL2023_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c45281a4-10a3-4039-a0ea-ba0e0d93e15a',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 590,
            baseHeight: 855,
            offsetTop: 105,
            offsetLeft: 95,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c45281a4-10a3-4039-a0ea-ba0e0d93e15a',
          },
        ],
      },
    },
  ],
};
export const HUX24_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/bfefe40f-b20a-4a98-b1dc-5aeae55ae052',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 700,
            baseHeight: 700,
            offsetTop: 205,
            offsetLeft: 105,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/bfefe40f-b20a-4a98-b1dc-5aeae55ae052',
          },
        ],
      },
    },
  ],
};

export const FINALTEMP_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/79bacadb-fe7c-4d65-91b7-01f84aeb9014',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1357,
            baseHeight: 1340,
            offsetTop: 422,
            offsetLeft: 766,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/79bacadb-fe7c-4d65-91b7-01f84aeb9014',
          },
        ],
      },
    },
  ],
};

export const ROCKETLANE_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d5c10cd4-eae1-4c55-a7a8-c4f56abfb18a',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 684,
            baseHeight: 958,
            offsetTop: 65,
            offsetLeft: 1162,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d5c10cd4-eae1-4c55-a7a8-c4f56abfb18a',
          },
        ],
      },
    },
  ],
};

export const QS_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/9ed76233-bb25-49dc-8768-588284c1e936',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2071,
            baseHeight: 2263,
            offsetTop: 620,
            offsetLeft: 1250,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/9ed76233-bb25-49dc-8768-588284c1e936',
          },
        ],
      },
    },
  ],
};

export const QS_PHOTOBOOTH2 = {
    type: 'GRID',
    bgWidth: '100%',
    bgHeight: '100%',
    zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/939408df-4fd7-4faf-ad21-564d3249f253',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1083,
            baseHeight: 1184,
            offsetTop: 336,
            offsetLeft: 647,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/939408df-4fd7-4faf-ad21-564d3249f253',
          },
        ],
      },
    },
  ],
  };

// export const SURAJ_MASTER_PHOTOBOOTH = {
//   type: 'GRID',
//   bgWidth: '100%',
//   bgHeight: '100%',
//   zone: 'booth',
//   widgets: [
//     {
//       type: WidgetTypes.PHOTOBOOTH,
//       x: 0,
//       y: 0,
//       width: '100%',
//       height: '100%',
//       config: {
//         layers: [
//           {
//             type: PhotoboothLayerTypes.IMAGE,
//             src: 'https://phoenixlive.imgix.net/9ed76233-bb25-49dc-8768-588284c1e936',
//           },
//           {
//             type: PhotoboothLayerTypes.PREVIEW,
//             baseWidth: 2071,
//             baseHeight: 2263,
//             offsetTop: 620,
//             offsetLeft: 1250,
//           },
//           {
//             type: PhotoboothLayerTypes.IMAGE,
//             src: 'https://phoenixlive.imgix.net/9ed76233-bb25-49dc-8768-588284c1e936',
//           },
//         ],
//       },
//     },
//   ],

// };

export const Chargefly_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/54903aff-7235-408d-9bb2-56a20defc3c2',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 880,
            baseHeight: 800,
            offsetTop: 50,
            offsetLeft: 100,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/54903aff-7235-408d-9bb2-56a20defc3c2',
          },
        ],
      },
    },
  ],
};
export const Everafter_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/696180a4-1f53-4b80-b254-1b0c009e6add',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 780,
            baseHeight: 750,
            offsetTop: 210,
            offsetLeft: 210,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/696180a4-1f53-4b80-b254-1b0c009e6add',
          },
        ],
      },
    },
  ],
};
export const QUALCOMM_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/QUALCOMM_PHOTOBOOTH1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1448,
            offsetTop: 350,
            offsetLeft: 700,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/QUALCOMM_PHOTOBOOTH1.png',
          },
        ],
      },
    },
  ],
};
export const CHANGE_HEALTHCARE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/CHANGE_HEALTHCARE_PHOTOBOOTH1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1448,
            offsetTop: 350,
            offsetLeft: 700,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/CHANGE_HEALTHCARE_PHOTOBOOTH1.png',
          },
        ],
      },
    },
  ],
};
export const BOMBALIVE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/BOMBALIVE_PHOTOBOOTH.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1432,
            baseHeight: 1448,
            offsetTop: 300,
            offsetLeft: 650,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/BOMBALIVE_PHOTOBOOTH.png',
          },
        ],
      },
    },
  ],
};
export const OTICONNEXT_2022:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/800d9ce6-a886-4dd5-9f32-0fb8319a875e.jpeg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      //Price_Drawing_INFORMATION
      type: WidgetTypes.HOTSPOT,
      x: 1440,
      y: 680,
      width: 190,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a36323b8-5dd5-4910-b655-7026ee75f647',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Business Development Group
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 220,
      width: 285,
      height: 60,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '323c3ee5-1c9a-491a-aff7-5d7cf6ce755c',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Local Consumer marketing
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 305,
      width: 285,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e892ad14-62dd-4379-91c7-2c0af3fb8039',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Product Showcase
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 385,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'eace5b76-1f18-4a25-a533-4325ff88b22f',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Blue Ridge
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 450,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '39b26cb6-da79-401f-b7da-2c29fbae3110',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Central
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 520,
      width: 285,
      height: 35,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'ccb3d873-010e-4de4-801c-0bbf6778d4f5',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Mid-America
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 575,
      width: 285,
      height: 35,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8567ffe7-85d9-4ce2-87b3-ed895483dedf',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Mid-Atlantic
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 620,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '51896fb7-8cb1-4e84-b65c-0fd856dc4c46',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Mountain
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 690,
      width: 285,
      height: 47,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9463c521-c092-4144-b876-eccb73ef95ee',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Region Northeast
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 760,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '18f84017-ffe7-41bd-9ad5-f372b33cb610',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Southeast Region
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 825,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e13e9bdd-873c-421a-bf3b-b0f9c9b3b419',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon West Region
      type: WidgetTypes.HOTSPOT,
      x: 120,
      y: 895,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5ced9f5a-4f61-486a-90c5-1585ed94ec3e',
        buttonLinkType: "internal"
      },
    },
    {
      //Audigy
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 225,
      width: 285,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5269fdf1-7714-42af-bec1-1a8400c9562a',
        buttonLinkType: "internal"
      },
    },
    {
      //Audiology Plus
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 305,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'cf79d0ce-6045-4ff8-9ffd-564c93216715',
        buttonLinkType: "internal"
      },
    },
    {
      //Audiology Design
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 390,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e56a782e-41b6-44ac-a6cf-445a7ec4b6f9',
        buttonLinkType: "internal"
      },
    },
    {
      //Audioscan
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 470,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7c8e42a0-12f8-49fc-aacf-086af5e8c13a',
        buttonLinkType: "internal"
      },
    },
    {
      //Beeman Marketing
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 555,
      width: 285,
      height: 40,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6c5c1993-c201-4bda-bab5-5a2c00acd2aa',
        buttonLinkType: "internal"
      },
    },
    {
      //CQ Partners
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 630,
      width: 285,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bcbd8633-f0a4-444d-ba46-422c7e3b275f',
        buttonLinkType: "internal"
      },
    },
    {
      //E3 Diagnostics
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 720,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd52aa556-45d9-4614-8595-fa6ecf1376e7',
        buttonLinkType: "internal"
      },
    },
    {
      //Entheos
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 800,
      width: 285,
      height: 40,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e54d6990-966b-4fed-8d85-c941141c3530',
        buttonLinkType: "internal"
      },
    },
    {
      //Fuel Media Group
      type: WidgetTypes.HOTSPOT,
      x: 460,
      y: 885,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'cedab175-9dc5-4d41-a637-813612b1ab90',
        buttonLinkType: "internal"
      },
    },
    {
      //Grason-Stadler (GSI)
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 220,
      width: 285,
      height: 45,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9d8475e6-989a-45a2-8832-76415f2881f2',
        buttonLinkType: "internal"
      },
    },
    {
      //HDI
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 300,
      width: 285,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '57b63e30-25b8-47d3-bc4e-3e17da6b6158',
        buttonLinkType: "internal"
      },
    },
    {
      //Interacoustics
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 385,
      width: 285,
      height: 40,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '93254534-5bc4-43e0-8d8a-78bdf4fa4be5',
        buttonLinkType: "internal"
      },
    },
    {
      //Maico Diagnostics
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 450,
      width: 285,
      height: 35,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'de6a5f4a-a815-4a36-960d-c4deeef2bc1e',
        buttonLinkType: "internal"
      },
    },
    {
      //Medrx
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 510,
      width: 285,
      height: 35,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '959503ba-1738-4148-a4df-fefdb6afa191',
        buttonLinkType: "internal"
      },
    },
    {
      //Nutshell marketing services llc
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 575,
      width: 285,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '41de6286-78ff-4ccc-803e-95de106005b8',
        buttonLinkType: "internal"
      },
    },
    {
      //Oticon Medical
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 660,
      width: 285,
      height: 40,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1d4dcdb5-cc12-47a0-9e9d-856cf4274292',
        buttonLinkType: "internal"
      },
    },
    {
      //Redux
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 730,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c0a8d325-cf33-405b-b500-93e37a2a9fa5',
        buttonLinkType: "internal"
      },
    },
    {
      //Roc advertising
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 800,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9ec9ac66-f1d8-4475-84a5-68396b60d11f',
        buttonLinkType: "internal"
      },
    },
    {
      //Sureway Marketing
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 890,
      width: 285,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '78b1d028-e57c-4320-8fbc-1a6a1afa822c',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const RockwellAutomationSB_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/809f8f3d-8b80-4676-8a9c-ed3ed85cf0b1.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Center video
    {
      type: WidgetTypes.MEDIA,
      x: 628,
      y: 360,
      width: 664,
      height: 354.66,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: true,
        src:'https://stream.mux.com/d3kPysnY6V4Hq3kIH2Qf02GfrtdxvMi2XWscFWHA93CU.m3u8',
        muted: false,
        loop: true,
      },
    },
  ],
};
export const Bayer_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/V00luXHT3hECzlYtxabIbdrzS1apBRVCKEYEK9mxcYlc.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Crop Science
    {
      type: WidgetTypes.HOTSPOT,
      x: 345,
      y: 270,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e6b9e55f-bdd1-48cc-adf8-07885f057f04',
        buttonLinkType: "internal"
      },
    },
    //Consumer Health
    {
      type: WidgetTypes.HOTSPOT,
      x: 690,
      y: 270,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5ab02b5a-81f5-4926-82dd-e933dca05898',
        buttonLinkType: "internal"
      },
    },
    //Pharmaceuticals
    {
      type: WidgetTypes.HOTSPOT,
      x: 1040,
      y: 270,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '169a1d95-f7fe-433c-8e23-4f654f52d873',
        buttonLinkType: "internal"
      },
    },
    //Enabling Functions
    {
      type: WidgetTypes.HOTSPOT,
      x: 1380,
      y: 270,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '546a2ccd-9c8b-446a-a481-511bb232012e',
        buttonLinkType: "internal"
      },
    },
    //Safegaurd Assets
    {
      type: WidgetTypes.HOTSPOT,
      x: 480,
      y: 440,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5cbf21fb-0860-47c1-a86f-99cd21592b08',
        buttonLinkType: "internal"
      },
    },
    //Modernize Architecture
    {
      type: WidgetTypes.HOTSPOT,
      x: 865,
      y: 440,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '13d50973-b349-4146-bf26-14adf4ef62f8',
        buttonLinkType: "internal"
      },
    },
    //Invest in Growth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1240,
      y: 440,
      width: 210,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'ecb44464-1848-4047-a03a-2d69880edf1c',
        buttonLinkType: "internal"
      },
    },
    //Talent at Scale
    {
      type: WidgetTypes.HOTSPOT,
      x: 865,
      y: 620,
      width: 200,
      height: 160,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '4e6c15d7-9250-4567-948f-4b6676ce17b0',
        buttonLinkType: "internal"
      },
    },
    //Lobby
    {
      type: WidgetTypes.HOTSPOT,
      x: 0,
      y: 620,
      width: 550,
      height: 120,
      config: {
        zone: "lobby",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
    //Help Desk
    {
      type: WidgetTypes.HOTSPOT,
      x: 1370,
      y: 620,
      width: 550,
      height: 120,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '448e871f-ee4e-4240-aaf5-27794f420022',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Finsec2022_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/TROd300Xy5wUA00irTUulc8rtcDb9ICyFEPy5p2VZn1fI.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Accops
    {
      type: WidgetTypes.HOTSPOT,
      x: 445,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5a819990-7ade-4194-8f29-4039bb21ba8f',
        buttonLinkType: "internal"
      },
    },
    //CyberSRC
    {
      type: WidgetTypes.HOTSPOT,
      x: 735,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6ba52c0a-affd-42d2-88b7-079c99965373',
        buttonLinkType: "internal"
      },
    },
    //Data Resolve
    {
      type: WidgetTypes.HOTSPOT,
      x: 1025,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e210c873-4fc3-4b35-8017-a82fad252ed4',
        buttonLinkType: "internal"
      },
    },
    //EventUS
    {
      type: WidgetTypes.HOTSPOT,
      x: 1315,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9a205387-f02f-469a-b870-74b682a8d71b',
        buttonLinkType: "internal"
      },
    },
    //LastPass
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '141891e8-3ab8-41b5-9c73-c2f0d4f9659e',
        buttonLinkType: "internal"
      },
    },
    //Microsoft
    {
      type: WidgetTypes.HOTSPOT,
      x: 560,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '59d7bebe-3d47-4c20-a173-8303d20bd0d5',
        buttonLinkType: "internal"
      },
    },
    //MySQL
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'de16fc10-e593-4ad2-8afc-dbc7a9b73cd4',
        buttonLinkType: "internal"
      },
    },
    //Paytm
    {
      type: WidgetTypes.HOTSPOT,
      x: 1195,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '943acae5-d74a-4782-8f3e-8ecd6ff07f08',
        buttonLinkType: "internal"
      },
    },
    //Tenable
    {
      type: WidgetTypes.HOTSPOT,
      x: 1515,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bdb06ac0-79c5-475b-af12-b5977a36a36c',
        buttonLinkType: "internal"
      },
    },
    //Help Desk
    {
      type: WidgetTypes.HOTSPOT,
      x: 315,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd92ad6a8-be0d-4d8f-8e70-9e15a24ca207',
        buttonLinkType: "internal"
      },
    },
    //DSCI
    {
      type: WidgetTypes.HOTSPOT,
      x: 870,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7fb86006-cd83-4c4b-a751-fd83908262f3',
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1415,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '689be19b-2e87-478d-bf08-74e8d8dfea61',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const PresaleFinsec2022Copy_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/TROd300Xy5wUA00irTUulc8rtcDb9ICyFEPy5p2VZn1fI.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Accops
    {
      type: WidgetTypes.HOTSPOT,
      x: 445,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0294c1e9-e639-428b-880d-83bb1979a053',
        buttonLinkType: "internal"
      },
    },
    //CyberSRC
    {
      type: WidgetTypes.HOTSPOT,
      x: 735,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '63ee23b5-8061-4558-a700-9db7a5381a43',
        buttonLinkType: "internal"
      },
    },
    //Data Resolve
    {
      type: WidgetTypes.HOTSPOT,
      x: 1025,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '10ab858d-08d8-47fc-9d12-bee695042283',
        buttonLinkType: "internal"
      },
    },
    //EventUS
    {
      type: WidgetTypes.HOTSPOT,
      x: 1315,
      y: 420,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '58fb31f2-a8ef-4bb6-a4a8-7b3c6382683f',
        buttonLinkType: "internal"
      },
    },
    //LastPass
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '446018d0-4b76-4d72-bb49-b90f103b1b59',
        buttonLinkType: "internal"
      },
    },
    //Microsoft
    {
      type: WidgetTypes.HOTSPOT,
      x: 560,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '336e85c6-957c-4197-b6bc-db9a2c1d48dc',
        buttonLinkType: "internal"
      },
    },
    //MySQL
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '88734308-bf81-4d4a-9645-3907ce5c7f30',
        buttonLinkType: "internal"
      },
    },
    //Paytm
    {
      type: WidgetTypes.HOTSPOT,
      x: 1195,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '909b24d0-2c93-4729-a9a7-21120a9000ee',
        buttonLinkType: "internal"
      },
    },
    //Tenable
    {
      type: WidgetTypes.HOTSPOT,
      x: 1515,
      y: 550,
      width: 160,
      height: 100,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '909cf860-2212-4c0d-9c46-d10f4ede9e45',
        buttonLinkType: "internal"
      },
    },
    //Help Desk
    {
      type: WidgetTypes.HOTSPOT,
      x: 315,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7885a0a8-26ab-4b70-9f46-6171f00fd901',
        buttonLinkType: "internal"
      },
    },
    //DSCI
    {
      type: WidgetTypes.HOTSPOT,
      x: 870,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7d3ac972-f779-464f-a5dc-ccc69ad491a3',
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1415,
      y: 715,
      width: 180,
      height: 105,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '3187fc98-f826-4359-8c35-eb8a9efc16e1',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Veeco1_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/eGKSJPl4gMJX3BCoX45shq01FMuuNgqYrYaETrt5RMDw.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Session 6
    {
      type: WidgetTypes.HOTSPOT,
      x: 425,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f1d9112c-8c0c-4a87-8c11-eb30fe0dccd0',
        buttonLinkType: "internal"
      },
    },
    //Session 7
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '95cee7fa-1912-47ad-a0f9-f46f0cc9f24c',
        buttonLinkType: "internal"
      },
    },
    //Session 8 / Graduation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bafa38c3-a928-455c-ad33-df6b1ee0e867',
        buttonLinkType: "internal"
      },
    },
    // Session 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 410,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1b2fb368-9073-4c48-a12e-64a512b81409',
        buttonLinkType: "internal"
      },
    },
    //Session 4
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1968ce22-e13a-436c-9455-f65c2bd33379',
        buttonLinkType: "internal"
      },
    },
    //Session 5
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e524568a-c401-4ee2-a34a-34a83120ae4d',
        buttonLinkType: "internal"
      },
    },
    //Session 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 390,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '2fc532d5-3646-4c7d-9e2e-8446fbe97d46',
        buttonLinkType: "internal"
      },
    },
    //KickOff
    {
      type: WidgetTypes.HOTSPOT,
      x: 790,
      y: 755,
      width: 330,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e9869015-bd1a-43fa-95e6-a0b148bf62b7',
        buttonLinkType: "internal"
      },
    },
    //Session 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'ef7c8c94-e9f9-4005-b815-7af12e2c2142',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Veeco2_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/eGKSJPl4gMJX3BCoX45shq01FMuuNgqYrYaETrt5RMDw.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Session 6
    {
      type: WidgetTypes.HOTSPOT,
      x: 425,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f34c26ce-e748-4c4c-9dec-2feb8523c3eb',
        buttonLinkType: "internal"
      },
    },
    //Session 7
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a97576d2-672b-4b9d-90ac-dcbcd67973e1',
        buttonLinkType: "internal"
      },
    },
    //Session 8 / Graduation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f44594ac-f08b-4999-b8f7-a17023c6516a',
        buttonLinkType: "internal"
      },
    },
    // Session 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 410,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '03103a58-80c4-4305-ab73-d806c6028e51',
        buttonLinkType: "internal"
      },
    },
    //Session 4
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '51a64a52-cabf-40d7-89f0-c0b1612ec69f',
        buttonLinkType: "internal"
      },
    },
    //Session 5
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '72641161-a2ff-4058-8efd-348c0169123a',
        buttonLinkType: "internal"
      },
    },
    //Session 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 390,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e4c480ea-022b-4724-bf33-bc06ced59150',
        buttonLinkType: "internal"
      },
    },
    //KickOff
    {
      type: WidgetTypes.HOTSPOT,
      x: 790,
      y: 755,
      width: 330,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '4f6252ab-c6ae-4019-bf22-94af58780387',
        buttonLinkType: "internal"
      },
    },
    //Session 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '490618eb-9f96-4bae-b4ef-3701a6c727c8',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Veeco3_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/pctZkD8Fg4kUCPmW9Kh2600Jt101M6VRTf01p1w4c4LRjo.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Session 6
    {
      type: WidgetTypes.HOTSPOT,
      x: 425,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a3155cdd-850b-4cd8-b651-53c149d56189',
        buttonLinkType: "internal"
      },
    },
    //Session 7
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'add00cb9-487c-465a-a13c-1def122f7357',
        buttonLinkType: "internal"
      },
    },
    //Session 8 / Graduation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'fc78fb3d-6c6e-4b9c-aa00-91aca27e5f63',
        buttonLinkType: "internal"
      },
    },
    // Session 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 410,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7b3180fd-5624-415a-8347-5fc79206f79b',
        buttonLinkType: "internal"
      },
    },
    //Session 4
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '36704f62-280b-4253-a5f2-8b8bb06d2f54',
        buttonLinkType: "internal"
      },
    },
    //Session 5
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1f433a87-3b92-422a-8d61-34552c97d550',
        buttonLinkType: "internal"
      },
    },
    //Session 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 390,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7ec92b2a-f26f-40d8-87aa-6574a9e0b9b1',
        buttonLinkType: "internal"
      },
    },
    //KickOff
    {
      type: WidgetTypes.HOTSPOT,
      x: 790,
      y: 755,
      width: 330,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5518289d-fbf1-48f6-a0f1-68be2ef1588d',
        buttonLinkType: "internal"
      },
    },
    //Session 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '65b42ecc-574a-4433-9472-368afb4e5e7d',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Veeco4_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/eGKSJPl4gMJX3BCoX45shq01FMuuNgqYrYaETrt5RMDw.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Session 6
    {
      type: WidgetTypes.HOTSPOT,
      x: 425,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '77b80a86-6000-4296-8e41-300fb562fea9',
        buttonLinkType: "internal"
      },
    },
    //Session 7
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0fb5e692-fb8e-4f13-8621-f3fad013a85d',
        buttonLinkType: "internal"
      },
    },
    //Session 8 / Graduation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '953e9275-2447-4e2f-ab71-ba04f14b6f8f',
        buttonLinkType: "internal"
      },
    },
    // Session 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 410,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'aee6ae2b-c103-461e-999a-f24a95d571d5',
        buttonLinkType: "internal"
      },
    },
    //Session 4
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c5d1dc45-26c3-4483-9bd3-5f54aba39961',
        buttonLinkType: "internal"
      },
    },
    //Session 5
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'ff62dfe4-9b4a-44a0-ba6a-11be6876885d',
        buttonLinkType: "internal"
      },
    },
    //Session 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 390,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '52f14d0d-cf84-4844-a08c-22fd71292ec7',
        buttonLinkType: "internal"
      },
    },
    //KickOff
    {
      type: WidgetTypes.HOTSPOT,
      x: 790,
      y: 755,
      width: 330,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7faa10cc-5dd9-4d83-836e-6fffbc9a4a78',
        buttonLinkType: "internal"
      },
    },
    //Session 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '64ef6f81-002c-4059-b7bb-082b543c1659',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const VeecoLL2_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/cZAbK8VpXrqGkDBY2S9j501padYgNKvUjrZB78IxTtdM.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Session 6
    {
      type: WidgetTypes.HOTSPOT,
      x: 425,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '824d2674-cc2c-4a55-9969-bb2aaa3929d1',
        buttonLinkType: "internal"
      },
    },
    //Session 7
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a05f1683-3a49-4115-b11c-ada1edb78a4b',
        buttonLinkType: "internal"
      },
    },
    //Session 8 / Graduation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 225,
      width: 240,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '293cc5ec-c7f8-47f2-93a8-6203c7b0068b',
        buttonLinkType: "internal"
      },
    },
    // Session 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 410,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0dfb5a5c-012e-4d3b-98e1-48faceb47ec5',
        buttonLinkType: "internal"
      },
    },
    //Session 4
    {
      type: WidgetTypes.HOTSPOT,
      x: 840,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '900b233a-fe1e-45df-b393-745ad59f01e7',
        buttonLinkType: "internal"
      },
    },
    //Session 5
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 445,
      width: 240,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '25206b6f-7669-4e3c-aea3-7f11f3294b7e',
        buttonLinkType: "internal"
      },
    },
    //Session 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 390,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0ddb1ad3-c3db-4574-a11d-5ec70a61c15e',
        buttonLinkType: "internal"
      },
    },
    //KickOff
    {
      type: WidgetTypes.HOTSPOT,
      x: 790,
      y: 755,
      width: 330,
      height: 150,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '2533d3ab-89f7-4449-82b9-a9534cbfec10',
        buttonLinkType: "internal"
      },
    },
    //Session 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1260,
      y: 715,
      width: 260,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '42ada9ef-3417-4e42-b49c-6a39211f4639',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const SandboxMS_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/034c7db8-db89-4d77-8437-09ca9d795b38.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Back Button
    {
      type: WidgetTypes.HOTSPOT,
      x: 35,
      y: 30,
      width: 55,
      height: 60,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c3a0972c-1ac2-4277-b0c0-e334ffd9148a',
        buttonLinkType: "internal"
      },
    },
    //Events On Zuddl
    {
      type: WidgetTypes.HOTSPOT,
      x: 210,
      y: 300,
      width: 270,
      height: 60,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c3a0972c-1ac2-4277-b0c0-e334ffd9148a',
        buttonLinkType: "internal"
      },
    },
    //Archive
    {
      type: WidgetTypes.HOTSPOT,
      x: 610,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '4154811e-10fe-4440-bcae-1b1c79f3ba69',
        buttonLinkType: "internal"
      },
    },
    // Products
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bbe9bc5a-2211-4365-9bed-72b6c46f7d52',
        buttonLinkType: "internal"
      },
    },
    //Cafe
    {
      type: WidgetTypes.HOTSPOT,
      x: 1010,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'df9f8a8c-ef89-402c-b25a-2fc244bc7d3b',
        buttonLinkType: "internal"
      },
    },
    //PhotoBooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1200,
      y: 550,
      width: 100,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c998ef7c-26dd-433c-b128-b52ac4237041',
        buttonLinkType: "internal"
      },
    },
    //Virtual Office
    {
      type: WidgetTypes.HOTSPOT,
      x: 520,
      y: 890,
      width: 120,
      height: 30,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c43c0aef-b52d-482a-ba20-2d9ff7043d97',
        buttonLinkType: "internal"
      },
    },
    //Contact Us
    {
      type: WidgetTypes.HOTSPOT,
      x: 1175,
      y: 890,
      width: 120,
      height: 30,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f556e8ac-7be6-4d22-9108-f46988fad4ba',
        buttonLinkType: "internal"
      },
    },
    //Our Website
    {
      type: WidgetTypes.HOTSPOT,
      x: 1465,
      y: 890,
      width: 130,
      height: 30,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.zuddl.com/',
        buttonLinkType: "external"
      },
    },
  ],
};
export const SandboxMS_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/64e3d0f3-38fd-4643-a9c3-02d56944de92.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1100,
            baseHeight: 960,
            offsetTop: 70,
            offsetLeft: 410,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/64e3d0f3-38fd-4643-a9c3-02d56944de92.png',
          },
        ],
      },
    },
  ],
};
export const SandboxMS2_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/034c7db8-db89-4d77-8437-09ca9d795b38.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Back Button
    {
      type: WidgetTypes.HOTSPOT,
      x: 35,
      y: 30,
      width: 55,
      height: 60,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '50964416-aed0-4918-aac3-aaeee75d6967',
        buttonLinkType: "internal"
      },
    },
    //Events On Zuddl
    {
      type: WidgetTypes.HOTSPOT,
      x: 210,
      y: 300,
      width: 270,
      height: 60,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '50964416-aed0-4918-aac3-aaeee75d6967',
        buttonLinkType: "internal"
      },
    },
    //Archive
    {
      type: WidgetTypes.HOTSPOT,
      x: 610,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b61d1b2d-39f5-4e5e-8df4-88a8e70723bf',
        buttonLinkType: "internal"
      },
    },
    // Products
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a55820d4-eac1-4a24-b020-4a66fd766034',
        buttonLinkType: "internal"
      },
    },
    //Cafe
    {
      type: WidgetTypes.HOTSPOT,
      x: 1010,
      y: 550,
      width: 90,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd178e518-68d0-4c1b-af6f-dbca12ef0cbf',
        buttonLinkType: "internal"
      },
    },
    //PhotoBooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1200,
      y: 550,
      width: 100,
      height: 120,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'eda6997d-0680-4fdd-ba6a-f895849ab4c0',
        buttonLinkType: "internal"
      },
    },
    //Virtual Office
    {
      type: WidgetTypes.HOTSPOT,
      x: 520,
      y: 890,
      width: 120,
      height: 30,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '2876e073-49b7-4114-b5a7-cc0a2eb81f80',
        buttonLinkType: "internal"
      },
    },
    //Contact Us
    {
      type: WidgetTypes.HOTSPOT,
      x: 1175,
      y: 890,
      width: 120,
      height: 30,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bda52b01-27bd-47ce-9cf1-75807a226106',
        buttonLinkType: "internal"
      },
    },
    //Our Website
    {
      type: WidgetTypes.HOTSPOT,
      x: 1465,
      y: 890,
      width: 130,
      height: 30,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.zuddl.com/',
        buttonLinkType: "external"
      },
    },
  ],
};
export const OTRGlobal1_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/12e2a92b-0ab2-4933-91fa-21d2bf488391.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 850,
            baseHeight: 820,
            offsetTop: 90,
            offsetLeft: 30,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/12e2a92b-0ab2-4933-91fa-21d2bf488391.png',
          },
        ],
      },
    },
  ],
};
export const Juneteenth_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/610ca695-7612-40ac-918e-8fee3570e13d.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Carhartt
    {
      type: WidgetTypes.HOTSPOT,
      x: 320,
      y: 285,
      width: 180,
      height: 135,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '099f0631-a79d-4fc0-93e0-c86228df8f1a',
        buttonLinkType: "internal"
      },
    },
    //John Deer
    {
      type: WidgetTypes.HOTSPOT,
      x: 675,
      y: 285,
      width: 180,
      height: 135,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '859fb9c9-b2a7-4d7b-96c5-96dea46593fd',
        buttonLinkType: "internal"
      },
    },
    //Leidos
    {
      type: WidgetTypes.HOTSPOT,
      x: 1035,
      y: 285,
      width: 180,
      height: 135,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '84de7461-d3e0-4ff9-8277-356521bce20a',
        buttonLinkType: "internal"
      },
    },
    // T-Mobile
    {
      type: WidgetTypes.HOTSPOT,
      x: 1400,
      y: 285,
      width: 180,
      height: 135,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'da5939d9-2b46-42eb-ab77-b00a8214ea4d',
        buttonLinkType: "internal"
      },
    },
    //Freddie Mac
    {
      type: WidgetTypes.HOTSPOT,
      x: 450,
      y: 450,
      width: 195,
      height: 130,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a9b307fd-b3f0-474c-a039-64aea1187e1d',
        buttonLinkType: "internal"
      },
    },
    //Wells Fargo
    {
      type: WidgetTypes.HOTSPOT,
      x: 850,
      y: 450,
      width: 195,
      height: 130,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd5b2386e-9878-4516-8d06-216c0aca166a',
        buttonLinkType: "internal"
      },
    },
    //Johnson & Johnson
    {
      type: WidgetTypes.HOTSPOT,
      x: 1240,
      y: 450,
      width: 195,
      height: 130,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '406ef5b4-7743-4e40-86cd-7985c251e95b',
        buttonLinkType: "internal"
      },
    },
    //Alaska Airlines
    {
      type: WidgetTypes.HOTSPOT,
      x: 110,
      y: 630,
      width: 250,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a4bf3716-f6c1-40e3-b549-3213cbd62fef',
        buttonLinkType: "internal"
      },
    },
    //Google
    {
      type: WidgetTypes.HOTSPOT,
      x: 835,
      y: 630,
      width: 250,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd0495101-0731-4c7c-91d1-7170c3d40a68',
        buttonLinkType: "internal"
      },
    },
    //Hilton
    {
      type: WidgetTypes.HOTSPOT,
      x: 1540,
      y: 630,
      width: 250,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd7d2bb75-493d-4840-800b-6b6ce30211d7',
        buttonLinkType: "internal"
      },
    },
    //Aaron's
    {
      type: WidgetTypes.HOTSPOT,
      x: 370,
      y: 810,
      width: 225,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '483b340e-639a-410f-b61e-107b0ddb2a16',
        buttonLinkType: "internal"
      },
    },
    //Juneteenth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1340,
      y: 810,
      width: 225,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8c69d687-8b27-4ac6-b036-fc99c920e21d',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const GoogleSB_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/9da4b985-4c1b-4219-8b41-0f8631f96bd8.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Virtual Office
    {
      type: WidgetTypes.HOTSPOT,
      x: 40,
      y: 220,
      width: 545,
      height: 305,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8d82b7ca-5dc3-49ae-8fe4-5f5e934a2c33',
        buttonLinkType: "internal"
      },
    },
    //Events
    {
      type: WidgetTypes.HOTSPOT,
      x: 605,
      y: 220,
      width: 545,
      height: 305,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '27fdaeaa-3cc8-462e-8c4e-3fffda351474',
        buttonLinkType: "internal"
      },
    },
    //Google
    {
      type: WidgetTypes.HOTSPOT,
      x: 1170,
      y: 220,
      width: 710,
      height: 305,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.google.com/search?q=Zuddl+is+the+best&sxsrf=APq-WBtm_GNuJFqvGPgTxdz0u5tM584NJw%3A1646997836223&ei=TDErYoeWDauUseMPsvyU0Ao&ved=0ahUKEwiH7OSH-b32AhUrSmwGHTI-BaoQ4dUDCA4&uact=5&oq=Zuddl+is+the+best&gs_lcp=Cgdnd3Mtd2l6EAMyBQghEKABOgQIIxAnOgQIABBDOgUIABCRAjoICAAQgAQQsQM6DgguEIAEELEDEMcBENEDOg4ILhCABBCxAxDHARCjAjoKCC4QxwEQ0QMQQzoKCC4QsQMQ1AIQQzoQCC4QsQMQgwEQxwEQ0QMQQzoHCC4Q1AIQQzoOCAAQgAQQsQMQgwEQyQM6BQgAEJIDOgUIABCABDoLCC4QgAQQxwEQrwE6CggAEIAEEIcCEBQ6BggAEBYQHjoHCCEQChCgAToECCEQFUoECEEYAEoECEYYAFAAWIcPYMgQaABwAXgAgAGBAYgBoQySAQQxNS4ymAEAoAEBwAEB&sclient=gws-wiz',
        buttonLinkType: "external"
      },
    },
    //Products
    {
      type: WidgetTypes.HOTSPOT,
      x: 40,
      y: 545,
      width: 260,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '07fe5ffb-2e0b-43b4-bd63-d3fcaae731f6',
        buttonLinkType: "internal"
      },
    },
    //Brand Gossip
    {
      type: WidgetTypes.HOTSPOT,
      x: 312,
      y: 545,
      width: 390,
      height: 253,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c99df08b-3f3d-4e3d-82e6-cee4ccf6be90',
        buttonLinkType: "internal"
      },
    },
    //Cafe Ambience
    {
      type: WidgetTypes.HOTSPOT,
      x: 720,
      y: 545,
      width: 260,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'cc85e90e-59f5-48aa-9ce4-9e7b665e864f',
        buttonLinkType: "internal"
      },
    },
    //Mini Archives
    {
      type: WidgetTypes.HOTSPOT,
      x: 995,
      y: 545,
      width: 260,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6d1c9852-f181-4041-b0ca-114293207451',
        buttonLinkType: "internal"
      },
    },
    //Selfies
    {
      type: WidgetTypes.HOTSPOT,
      x: 1275,
      y: 545,
      width: 260,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a778f49e-1505-4ded-b68f-968bd2ef2f07',
        buttonLinkType: "internal"
      },
    },
    //Hire
    {
      type: WidgetTypes.HOTSPOT,
      x: 1550,
      y: 545,
      width: 330,
      height: 250,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6c9342be-853f-4080-b990-ebd96a2bbcd5',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const INDIAMOBILECONGRESS_EXPO_OUTER = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/IMC_blanks_with_people-GPJ.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 315,
      y: 440,
      width: 256,
      height: 161,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6eeb12a9-4fbc-49e7-ae65-5ad505288551',
        buttonLinkType: "internal"
      },
    }
  ],
};
export const UnileverSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/61fe3801-9921-4169-be5c-ad40b920e509.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 750,
            baseHeight: 750,
            offsetTop: 200,
            offsetLeft: 575,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/61fe3801-9921-4169-be5c-ad40b920e509.png',
          },
        ],
      },
    },
  ],
};
export const AccentureSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/455a8482-5c65-4384-b378-6178fd7a50ac.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 750,
            baseHeight: 750,
            offsetTop: 200,
            offsetLeft: 575,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/455a8482-5c65-4384-b378-6178fd7a50ac.png',
          },
        ],
      },
    },
  ],
};
export const DUSIPM1_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/98182e0a-7dc5-4ad3-b4aa-781994fae37b.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 500,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/98182e0a-7dc5-4ad3-b4aa-781994fae37b.png',
          },
        ],
      },
    },
  ],
};
export const DUSIPM2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/51ffcb93-4c22-46c8-88c2-91a27f4b26a8.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 500,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/51ffcb93-4c22-46c8-88c2-91a27f4b26a8.png',
          },
        ],
      },
    },
  ],
};
export const DUSIPM3_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/81417bb7-bf92-45b9-9c1b-4d55fba173da.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 500,
            baseHeight: 500,
            offsetTop: 0,
            offsetLeft:200,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/81417bb7-bf92-45b9-9c1b-4d55fba173da.png',
          },
        ],
      },
    },
  ],
};
export const DUSIPM4_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/82b95361-1d00-49b1-96e5-edb9bdd029c4.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 800,
            baseHeight: 400,
            offsetTop: 40,
            offsetLeft: 50,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/82b95361-1d00-49b1-96e5-edb9bdd029c4.png',
          },
        ],
      },
    },
  ],
};
export const DUSIPM5_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8dd81a83-6ec0-4c97-93a5-762ab97a8dc0.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 420,
            offsetTop: 0,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8dd81a83-6ec0-4c97-93a5-762ab97a8dc0.png',
          },
        ],
      },
    },
  ],
};
export const Adobe2022_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7061c599-f040-4be4-9838-c1fcb205062b.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1350,
            baseHeight: 1400,
            offsetTop: 200,
            offsetLeft: 500,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7061c599-f040-4be4-9838-c1fcb205062b.png',
          },
        ],
      },
    },
  ],
};
export const ADOBE_ALCHEMY_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/adobe_Photo_op.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1632,
            baseHeight: 1800,
            offsetTop: 210,
            offsetLeft: 870,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/adobe_Photo_op.png',
          },
        ],
      },
    },
  ],
};
export const DubaiHolding_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/abdc7770-a5a1-4868-adc4-d35c8d0fafe0.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1480,
            baseHeight: 1435,
            offsetTop: 200,
            offsetLeft: 640,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/abdc7770-a5a1-4868-adc4-d35c8d0fafe0.png',
          },
        ],
      },
    },
  ],
};
export const Fractal1729_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/4c93e0fc-cdea-417d-b58a-805670869a6b.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 550,
            baseHeight: 600,
            offsetTop: 65,
            offsetLeft: 25,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/4c93e0fc-cdea-417d-b58a-805670869a6b.png',
          },
        ],
      },
    },
  ],
};
export const Fractal1729_2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7c24bb82-8d9b-4bbe-9666-43acdd9d9053.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 850,
            baseHeight: 750,
            offsetTop: 175,
            offsetLeft: 105,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7c24bb82-8d9b-4bbe-9666-43acdd9d9053.png',
          },
        ],
      },
    },
  ],
};
export const Fractal1729_3_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3d2fb77e-5976-43ef-8299-e5bd1a51f3bc.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 850,
            baseHeight: 830,
            offsetTop: 130,
            offsetLeft: 900,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3d2fb77e-5976-43ef-8299-e5bd1a51f3bc.png',
          },
        ],
      },
    },
  ],
};
export const FractalGWR_1_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d211e779-f1ff-4748-b79a-a893a4c6d40a.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 770,
            baseHeight: 780,
            offsetTop: 160,
            offsetLeft: 75,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d211e779-f1ff-4748-b79a-a893a4c6d40a.png',
          },
        ],
      },
    },
  ],
};
export const FractalGWR_2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b3ba4091-1f1e-459a-9268-53b96ab2e623.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 770,
            baseHeight: 780,
            offsetTop: 160,
            offsetLeft: 75,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b3ba4091-1f1e-459a-9268-53b96ab2e623.png',
          },
        ],
      },
    },
  ],
};
export const CH_FamilyDay_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/a627e88c-72d4-4ba6-a550-0454a204fa8f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 705,
            baseHeight: 420,
            offsetTop: 65,
            offsetLeft: 140,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/a627e88c-72d4-4ba6-a550-0454a204fa8f.png',
          },
        ],
      },
    },
  ],
};
export const PolygonSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/f63de3b8-e045-4934-99d8-c626de74e2f3.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1060,
            baseHeight: 950,
            offsetTop: 55,
            offsetLeft: 330,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/f63de3b8-e045-4934-99d8-c626de74e2f3.png',
          },
        ],
      },
    },
  ],
};
export const Tidal_EXPO_OUTER1:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/5cea5f56-531c-4f69-8fb1-7fb7b509fc25.jpeg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    // Fast Charging
    {
      type: WidgetTypes.HOTSPOT,
      x: 360,
      y: 275,
      width: 290,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a5bd69f8-fe6d-4332-b9b4-e72bdf9d7da4',
        buttonLinkType: "internal"
      },
    },
    // Design
    {
      type: WidgetTypes.HOTSPOT,
      x: 1150,
      y: 275,
      width: 290,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6a153528-5c44-422b-b43b-73af4f63540c',
        buttonLinkType: "internal"
      },
    },
    // Pricing
    {
      type: WidgetTypes.HOTSPOT,
      x: 190,
      y: 520,
      width: 180,
      height: 95,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bb0dbeba-1881-4994-b7f2-e155bf508fc2',
        buttonLinkType: "internal"
      },
    },
    // Range
    {
      type: WidgetTypes.HOTSPOT,
      x: 1325,
      y: 525,
      width: 290,
      height: 80,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a4aa6822-1ec8-4b54-b9d2-60a282dafa25',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const TidalSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/4797e68d-6646-4df8-a41e-5c52bc5d7e64.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 555,
            baseHeight: 530,
            offsetTop: 180,
            offsetLeft: 25,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/4797e68d-6646-4df8-a41e-5c52bc5d7e64.png',
          },
        ],
      },
    },
  ],
};
export const ChargebeeSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5f5ebb11-5c82-4138-9d77-2aa4d4a60c18.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 470,
            baseHeight: 450,
            offsetTop: 40,
            offsetLeft: 75,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5f5ebb11-5c82-4138-9d77-2aa4d4a60c18.png',
          },
        ],
      },
    },
  ],
};
export const GTIPCAug22_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/p8fAfKO5TkzyUKbuCLIbKPWH8HbjbeOQ3AwlTbD7I7U.m3u8',
        muted: true,
        loop: true,
      },
    },
    //Addressing India's Financial Stress Problems
    {
      type: WidgetTypes.HOTSPOT,
      x: 140,
      y: 240,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b346fa3b-9590-4e43-9525-90d641fd5095',
        buttonLinkType: "internal"
      },
    },
    //Scripting the future of financial services
    {
      type: WidgetTypes.HOTSPOT,
      x: 560,
      y: 240,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '59b4f2ad-e75d-4c43-aec4-6b2808c86515',
        buttonLinkType: "internal"
      },
    },
    //Secure your business Secure the nation
    {
      type: WidgetTypes.HOTSPOT,
      x: 1015,
      y: 240,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'fb1c4b89-b685-4048-9efc-57023296d983',
        buttonLinkType: "internal"
      },
    },
    //Addressing supply chain challenges in auto sector
    {
      type: WidgetTypes.HOTSPOT,
      x: 1440,
      y: 240,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '29393dbe-6817-4f93-bbcf-89344f4e0d51',
        buttonLinkType: "internal"
      },
    },
    //Strengthening the family office
    {
      type: WidgetTypes.HOTSPOT,
      x: 310,
      y: 400,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bfe5b591-6580-49e3-a818-9da8d7e68ba2',
        buttonLinkType: "internal"
      },
    },
    //Trust beyond boundries
    {
      type: WidgetTypes.HOTSPOT,
      x: 810,
      y: 400,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '35701614-2410-4fe6-b51b-9722b72d6f1e',
        buttonLinkType: "internal"
      },
    },
    //Enabling growth through skilling
    {
      type: WidgetTypes.HOTSPOT,
      x: 1270,
      y: 400,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'aa985740-ecb6-4b32-8380-bd00d42d92e3',
        buttonLinkType: "internal"
      },
    },
    //Enabling seamless travel
    {
      type: WidgetTypes.HOTSPOT,
      x: 100,
      y: 590,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e8cf7ed9-6eb2-4de3-8015-1d56697781ce',
        buttonLinkType: "internal"
      },
    },
    //Driving the national water agenda
    {
      type: WidgetTypes.HOTSPOT,
      x: 805,
      y: 590,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1445be0f-b127-4924-b694-dbb7f7c314bb',
        buttonLinkType: "internal"
      },
    },
    //Shaping a vibrant Bharat
    {
      type: WidgetTypes.HOTSPOT,
      x: 1530,
      y: 590,
      width: 280,
      height: 85,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f6641cf2-c035-4c30-8e04-96ec033cd06f',
        buttonLinkType: "internal"
      },
    },
    //Lobby
    {
      type: WidgetTypes.HOTSPOT,
      x: 545,
      y: 650,
      width: 140,
      height: 180,
      config: {
        zone: "lobby",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1220,
      y: 650,
      width: 140,
      height: 180,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '16fe1a35-210c-4c72-8d58-9be2051d72be',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const GTIPCAug22_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3e1e6dce-acaf-4e24-9ff0-b9edf5ab3df4.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1796,
            baseHeight: 1345,
            offsetTop: 0,
            offsetLeft: 1750,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3e1e6dce-acaf-4e24-9ff0-b9edf5ab3df4.png',
          },
        ],
      },
    },
  ],
};
export const SATAIRSB_EXPO:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/f05f0603-9aef-4089-b3fc-986fb010c0e7.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Structure Parts
    {
      type: WidgetTypes.HOTSPOT,
      x: 340,
      y: 470,
      width: 247,
      height: 74,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '26d38785-b4a6-4864-baa5-4cfe4a670da5',
        buttonLinkType: "internal"
      },
    },
    //Modification Kits
    {
      type: WidgetTypes.HOTSPOT,
      x: 830,
      y: 470,
      width: 247,
      height: 74,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bf7c77a4-4f54-4b06-bf57-31fbe3055c5f',
        buttonLinkType: "internal"
      },
    },
    //Avionics and Instruments
    {
      type: WidgetTypes.HOTSPOT,
      x: 1330,
      y: 470,
      width: 247,
      height: 74,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5fc1c0bb-a716-42ad-b635-8932a06c8369',
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 420,
      y: 635,
      width: 265,
      height: 70,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'da820fe6-c333-4d0d-8e58-a3c981b094fa',
        buttonLinkType: "internal"
      },
    },
    //Airport Cafe
    {
      type: WidgetTypes.HOTSPOT,
      x: 825,
      y: 635,
      width: 265,
      height: 70,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c2d6f688-aa7d-4f6a-9c67-fe4d2aaf96dd',
        buttonLinkType: "internal"
      },
    },
    //Social Wall
    {
      type: WidgetTypes.HOTSPOT,
      x: 1238,
      y: 635,
      width: 265,
      height: 70,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '6507d230-b337-4030-810d-2affd3f818b4',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const SATAIRSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/98cc8608-5ed3-40f2-9c04-27b1c780de89.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1000,
            baseHeight: 950,
            offsetTop: 0,
            offsetLeft: 45,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/98cc8608-5ed3-40f2-9c04-27b1c780de89.png',
          },
        ],
      },
    },
  ],
};
export const BPM2022_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/1gQzPeyn4eqWnT00K5wWVxBDLhl022nlfpuE3YwwGOz34.m3u8',
        muted: true,
        loop: true,
      },
    },
    // ARMIS
    {
      type: WidgetTypes.HOTSPOT,
      x: 240,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a2ce054e-de39-429e-ba10-c5597f0ca5cc',
        buttonLinkType: "internal"
      },
    },
    // Check Point SoftCell
    {
      type: WidgetTypes.HOTSPOT,
      x: 540,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'cc039534-b2c9-451e-bd8e-2fefabea9ef8',
        buttonLinkType: "internal"
      },
    },
    // Cyble
    {
      type: WidgetTypes.HOTSPOT,
      x: 875,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9a82d147-2f08-4451-b85d-f062a40d9fd9',
        buttonLinkType: "internal"
      },
    },
    // Deloitte
    {
      type: WidgetTypes.HOTSPOT,
      x: 1230,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'eabc4b3d-0ebc-4713-8191-529ea07e9227',
        buttonLinkType: "internal"
      },
    },
    // Esecforte
    {
      type: WidgetTypes.HOTSPOT,
      x: 1535,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1836257b-a86e-4560-9e3c-12a98adf513b',
        buttonLinkType: "internal"
      },
    },
    // Rapifuzz
    {
      type: WidgetTypes.HOTSPOT,
      x: 183,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7bdba073-250d-4773-833f-c2e23e9bf958',
        buttonLinkType: "internal"
      },
    },
    // Secneurx
    {
      type: WidgetTypes.HOTSPOT,
      x: 535,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f9406c05-3455-4f36-9c16-170a4ae0a4a9',
        buttonLinkType: "internal"
      },
    },
    // Sisa
    {
      type: WidgetTypes.HOTSPOT,
      x: 868,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1eb2e818-b729-4ee6-9917-f59d2d18d98b',
        buttonLinkType: "internal"
      },
    },
    // Zoom
    {
      type: WidgetTypes.HOTSPOT,
      x: 1215,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5daa4519-ee1d-4e5d-a1c8-83bed069510e',
        buttonLinkType: "internal"
      },
    },
    // DCPLA
    {
      type: WidgetTypes.HOTSPOT,
      x: 1540,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1c531d4e-062d-4aa0-86fa-dec5c0bf3ffb',
        buttonLinkType: "internal"
      },
    },
    // Lobby
    {
      type: WidgetTypes.HOTSPOT,
      x: 350,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "lobby",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 865,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5394a0b2-95d6-4c7f-afc6-bd5f2f58adda',
        buttonLinkType: "internal"
      },
    },
    // Stage
    {
      type: WidgetTypes.HOTSPOT,
      x: 1380,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "stages",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
  ],
};
export const BPM2022_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/09382771-8c20-4909-b5e7-b513d79ae276.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 850,
            baseHeight: 840,
            offsetTop: 140,
            offsetLeft: 120,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/09382771-8c20-4909-b5e7-b513d79ae276.png',
          },
        ],
      },
    },
  ],
};
export const PreSaleBPM2022Copy_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/1gQzPeyn4eqWnT00K5wWVxBDLhl022nlfpuE3YwwGOz34.m3u8',
        muted: true,
        loop: true,
      },
    },
    // ARMIS
    {
      type: WidgetTypes.HOTSPOT,
      x: 240,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '4b371482-a85a-4d63-9052-2a737fb0c230',
        buttonLinkType: "internal"
      },
    },
    // Check Point SoftCell
    {
      type: WidgetTypes.HOTSPOT,
      x: 540,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd9fff696-1c4f-4e0d-8e7d-1cde497ff24d',
        buttonLinkType: "internal"
      },
    },
    // Cyble
    {
      type: WidgetTypes.HOTSPOT,
      x: 875,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5971afbf-7e28-4474-b2c6-ec84df6170a1',
        buttonLinkType: "internal"
      },
    },
    // Deloitte
    {
      type: WidgetTypes.HOTSPOT,
      x: 1230,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '2014bff6-13f3-490c-a674-fd5ce1b2b64b',
        buttonLinkType: "internal"
      },
    },
    // Esecforte
    {
      type: WidgetTypes.HOTSPOT,
      x: 1535,
      y: 260,
      width: 175,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0dd2980f-d798-4358-8d0e-be06ee8de0f4',
        buttonLinkType: "internal"
      },
    },
    // Rapifuzz
    {
      type: WidgetTypes.HOTSPOT,
      x: 183,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5a0a3bf5-c49d-492d-92f6-fe93385fb7b6',
        buttonLinkType: "internal"
      },
    },
    // Secneurx
    {
      type: WidgetTypes.HOTSPOT,
      x: 535,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e087b023-bd66-48ff-834d-75e9f433ae05',
        buttonLinkType: "internal"
      },
    },
    // Sisa
    {
      type: WidgetTypes.HOTSPOT,
      x: 868,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'c9b36a53-90c5-4f7a-9f90-78b8921fef8f',
        buttonLinkType: "internal"
      },
    },
    // Zoom
    {
      type: WidgetTypes.HOTSPOT,
      x: 1215,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'd3d554f1-389b-41f0-b808-e028d7756ac7',
        buttonLinkType: "internal"
      },
    },
    // DCPLA
    {
      type: WidgetTypes.HOTSPOT,
      x: 1540,
      y: 480,
      width: 190,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e02fca6c-936c-4b9f-b05b-ac174865ad02',
        buttonLinkType: "internal"
      },
    },
    // Lobby
    {
      type: WidgetTypes.HOTSPOT,
      x: 350,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "lobby",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
    //Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 865,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '37659aad-26c3-4e8f-8f8b-1b8a1999dd63',
        buttonLinkType: "internal"
      },
    },
    // Stage
    {
      type: WidgetTypes.HOTSPOT,
      x: 1380,
      y: 750,
      width: 205,
      height: 115,
      config: {
        zone: "stages",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
  ],
};
export const Revenera_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/fa85e5a2-56fe-463f-b6b4-0537b0eed991',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 108,
      y: 189,
      width: 1066,
      height: 530,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://youtu.be/E3tkr2UJ4I8',
        muted: true,
        loop: true,
      },
    },
    // View Event Calendar
    {
      type: WidgetTypes.HOTSPOT,
      x: 1383,
      y: 342,
      width: 291,
      height: 54,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.revenera.com/resources?type%5Bwebinar-event%5D=webinar-event',
        buttonLinkType: "external"
      },
    },
    // Back to Stage
    {
      type: WidgetTypes.HOTSPOT,
      x: 1383,
      y: 533,
      width: 291,
      height: 54,
      config: {
        zone: "stages",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        buttonLinkType: "internal"
      },
    },
    // View Resources
    {
      type: WidgetTypes.HOTSPOT,
      x: 1383,
      y: 605,
      width: 291,
      height: 54,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.revenera.com/resources',
        buttonLinkType: "external"
      },
    },
    // Website
    {
      type: WidgetTypes.HOTSPOT,
      x: 133,
      y: 848,
      width: 36,
      height: 40,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.revenera.com/',
        buttonLinkType: "external"
      },
    },
    // LinkedIn
    {
      type: WidgetTypes.HOTSPOT,
      x: 168,
      y: 848,
      width: 36,
      height: 40,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.linkedin.com/company/revenera/',
        buttonLinkType: "external"
      },
    },
    // Twitter
    {
      type: WidgetTypes.HOTSPOT,
      x: 226,
      y: 848,
      width: 36,
      height: 40,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://twitter.com/getrevenera',
        buttonLinkType: "external"
      },
    },
    // Youtube
    {
      type: WidgetTypes.HOTSPOT,
      x: 282,
      y: 848,
      width: 36,
      height: 40,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.youtube.com/GetRevenera',
        buttonLinkType: "external"
      },
    },
    // Instagram
    {
      type: WidgetTypes.HOTSPOT,
      x: 342,
      y: 848,
      width: 36,
      height: 40,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.instagram.com/weareflexera/',
        buttonLinkType: "external"
      },
    },
  ],
};
export const NeomSB_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/S6lEnZkn7tEqbhGVhkvIwR8002DyVMNCijDDDxtsk2U4.m3u8',
        muted: true,
        loop: true,
      },
    },
    // View Projects
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 320,
      width: 320,
      height: 90,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '037493df-a4ab-433d-8c36-2a88cc37f293',
        buttonLinkType: "internal"
      },
    },
    // Partners > Know More
    {
      type: WidgetTypes.HOTSPOT,
      x: 220,
      y: 635,
      width: 155,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'dd4d1de0-36dd-4c65-b4f7-2ef123fc9079',
        buttonLinkType: "internal"
      },
    },
    // About Neom > Know More
    {
      type: WidgetTypes.HOTSPOT,
      x: 755,
      y: 640,
      width: 155,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f3497679-e676-48e1-b2b0-470ef5f45902',
        buttonLinkType: "internal"
      },
    },
    // Careers
    {
      type: WidgetTypes.HOTSPOT,
      x: 170,
      y: 770,
      width: 335,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '591da69b-5375-4516-b624-e4e22fcb11b8',
        buttonLinkType: "internal"
      },
    },
    // Invest
    {
      type: WidgetTypes.HOTSPOT,
      x: 525,
      y: 770,
      width: 335,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9639e4d0-09d1-451a-b310-09077b17f0d3',
        buttonLinkType: "internal"
      },
    },
    // Suppliers
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 770,
      width: 340,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f83a09b4-0498-453a-aba6-87eff4a4ebca',
        buttonLinkType: "internal"
      },
    },
    // Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1245,
      y: 535,
      width: 505,
      height: 75,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9bce738b-d0ac-4ab8-b1c0-8220261042a2',
        buttonLinkType: "internal"
      },
    },
    // News
    {
      type: WidgetTypes.HOTSPOT,
      x: 1250,
      y: 625,
      width: 245,
      height: 200,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'da5f9c62-e07f-4cc5-937d-67f12924c12c',
        buttonLinkType: "internal"
      },
    },
    // Social Media
    {
      type: WidgetTypes.HOTSPOT,
      x: 1510,
      y: 625,
      width: 245,
      height: 200,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0d0a68c6-7ccd-45f8-8f88-32e164bb9cb8',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const NeomRenewed_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/0a101ca8-79f3-4c50-b715-91ccfb7c8a60',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    // View Projects
    {
      type: WidgetTypes.HOTSPOT,
      x: 245,
      y: 320,
      width: 320,
      height: 90,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '564a8a46-f11e-4756-8f6f-f4a1a0ceb90f',
        buttonLinkType: "internal"
      },
    },
    // Partners > Know More
    {
      type: WidgetTypes.HOTSPOT,
      x: 220,
      y: 635,
      width: 155,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9f978791-4e1b-4373-bb03-320a3669772c',
        buttonLinkType: "internal"
      },
    },
    // About Neom > Know More
    {
      type: WidgetTypes.HOTSPOT,
      x: 755,
      y: 640,
      width: 155,
      height: 50,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '64f5daf0-db0d-4358-a5aa-ce2eda84ebbb',
        buttonLinkType: "internal"
      },
    },
    // Careers
    {
      type: WidgetTypes.HOTSPOT,
      x: 170,
      y: 770,
      width: 335,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '511d7385-18c2-4e6e-ad81-37af29bf7831',
        buttonLinkType: "internal"
      },
    },
    // Invest
    {
      type: WidgetTypes.HOTSPOT,
      x: 525,
      y: 770,
      width: 335,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e8b262be-7d2c-4237-9cae-8493cbc3a8dc',
        buttonLinkType: "internal"
      },
    },
    // Suppliers
    {
      type: WidgetTypes.HOTSPOT,
      x: 880,
      y: 770,
      width: 340,
      height: 55,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'e2f6e2d2-ae81-4ff9-bd4f-241b52512a3c',
        buttonLinkType: "internal"
      },
    },
    // Photobooth
    {
      type: WidgetTypes.HOTSPOT,
      x: 1245,
      y: 535,
      width: 505,
      height: 75,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8dbf0503-b6ee-4e8a-8ecc-72153d084e4f',
        buttonLinkType: "internal"
      },
    },
    // News
    {
      type: WidgetTypes.HOTSPOT,
      x: 1250,
      y: 625,
      width: 245,
      height: 200,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '9b909afb-8793-4478-bf19-cb77da3ea7a7',
        buttonLinkType: "internal"
      },
    },
    // Social Media
    {
      type: WidgetTypes.HOTSPOT,
      x: 1510,
      y: 625,
      width: 245,
      height: 200,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1361dc4f-2d8e-4bc3-bd34-65cdf85e0128',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const ARTSGROWSCSB_EXPO:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/82bf731b-182d-427f-8508-5a85879d91a8.jpg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    //Dance
    {
      type: WidgetTypes.HOTSPOT,
      x: 152,
      y: 147,
      width: 540,
      height: 387,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'def25aff-5d6e-4b47-8729-2707d7341ac7',
        buttonLinkType: "internal"
      },
    },
    //Literature
    {
      type: WidgetTypes.HOTSPOT,
      x: 1169,
      y: 171,
      width: 506,
      height: 345,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '7da35d95-49d5-4cf8-ba4c-410b57cae6fa',
        buttonLinkType: "internal"
      },
    },
    //Theatre
    {
      type: WidgetTypes.HOTSPOT,
      x: 170,
      y: 644,
      width: 508,
      height: 344,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b0cc548e-7916-4b5f-8ce2-c80059fa9114',
        buttonLinkType: "internal"
      },
    },
    //Music
    {
      type: WidgetTypes.HOTSPOT,
      x: 1171,
      y: 649,
      width: 504,
      height: 343,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'f880515c-a201-42bd-9e1e-cfd3b504f91f',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const SHRMSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b426da27-71ac-4684-9524-40117ac44a0c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 950,
            baseHeight: 965,
            offsetTop: 61,
            offsetLeft: 934,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b426da27-71ac-4684-9524-40117ac44a0c.png',
          },
        ],
      },
    },
  ],
};
export const EYSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/99cde660-5505-4436-bbe2-29b475be1b70.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 800,
            baseHeight: 650,
            offsetTop: 185,
            offsetLeft: 965,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/99cde660-5505-4436-bbe2-29b475be1b70.png',
          },
        ],
      },
    },
  ],
};
export const TinymagiqSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8f9414c9-b194-44a3-8fab-081b00bc9e4c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1190,
            baseHeight: 970,
            offsetTop: 65,
            offsetLeft: 380,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8f9414c9-b194-44a3-8fab-081b00bc9e4c.png',
          },
        ],
      },
    },
  ],
};
export const AlphaWaveSB_EXPO:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'https://phoenixlive.imgix.net/03fe1a82-57ba-42e0-a8f7-02de40dc4619.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    // Know more about Alpha Wave
    {
      type: WidgetTypes.HOTSPOT,
      x: 140,
      y: 630,
      width: 320,
      height: 61,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.alphawaveglobal.com/',
        buttonLinkType: "external"
      },
    },
    // Social Wall
    {
      type: WidgetTypes.HOTSPOT,
      x: 140,
      y: 710,
      width: 195,
      height: 61,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '47f4a0dd-cffb-465c-abcb-1ff6ea8cf4a0',
        buttonLinkType: "internal"
      },
    },
    // Zuddl 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 1233,
      y: 163,
      width: 213,
      height: 108,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5445fafc-2988-454a-a320-642608633472',
        buttonLinkType: "internal"
      },
    },
    // Classplus 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 1464,
      y: 163,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://classplusapp.com/grow-your-business?utm_source=classplus_brandled_brandled_exact_he&utm_medium=Cpc&utm_content=brand&utm_campaign=google_search_brandled_he&utm_term=classplus&gclid=Cj0KCQjwjbyYBhCdARIsAArC6LKNhFVRJTwB9GL5ZzBQJEx7GfP_C3QSqPKEETmZkSM_AsooYLe2zfMaAi_9EALw_wcB',
        buttonLinkType: "external"
      },
    },
    // Indiagold 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 1110,
      y: 288,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://indiagold.co/',
        buttonLinkType: "external"
      },
    },
    // Hippo Video 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 1341,
      y: 288,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.hippovideo.io/',
        buttonLinkType: "external"
      },
    },
    // momos 1
    {
      type: WidgetTypes.HOTSPOT,
      x: 1566,
      y: 288,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.momos.com/',
        buttonLinkType: "external"
      },
    },
    // Zuddl 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1233,
      y: 412,
      width: 213,
      height: 108,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5445fafc-2988-454a-a320-642608633472',
        buttonLinkType: "internal"
      },
    },
    // Classplus 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1464,
      y: 412,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://classplusapp.com/grow-your-business?utm_source=classplus_brandled_brandled_exact_he&utm_medium=Cpc&utm_content=brand&utm_campaign=google_search_brandled_he&utm_term=classplus&gclid=Cj0KCQjwjbyYBhCdARIsAArC6LKNhFVRJTwB9GL5ZzBQJEx7GfP_C3QSqPKEETmZkSM_AsooYLe2zfMaAi_9EALw_wcB',
        buttonLinkType: "external"
      },
    },
    // Indiagold 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1110,
      y: 532,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://indiagold.co/',
        buttonLinkType: "external"
      },
    },
    // Hippo Video 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1341,
      y: 532,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.hippovideo.io/',
        buttonLinkType: "external"
      },
    },
    // momos 2
    {
      type: WidgetTypes.HOTSPOT,
      x: 1566,
      y: 532,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://www.momos.com/',
        buttonLinkType: "external"
      },
    },
    // Zuddl 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 1233,
      y: 655,
      width: 213,
      height: 108,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '5445fafc-2988-454a-a320-642608633472',
        buttonLinkType: "internal"
      },
    },
    // Classplus 3
    {
      type: WidgetTypes.HOTSPOT,
      x: 1464,
      y: 655,
      width: 213,
      height: 108,
      config: {
        type: "BUTTON",
        buttonType: "HOTSPOT",
        redirectURL: 'https://classplusapp.com/grow-your-business?utm_source=classplus_brandled_brandled_exact_he&utm_medium=Cpc&utm_content=brand&utm_campaign=google_search_brandled_he&utm_term=classplus&gclid=Cj0KCQjwjbyYBhCdARIsAArC6LKNhFVRJTwB9GL5ZzBQJEx7GfP_C3QSqPKEETmZkSM_AsooYLe2zfMaAi_9EALw_wcB',
        buttonLinkType: "external"
      },
    },
  ],
};
export const SenseHawkSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/33110c96-5c8e-4d48-8a48-053c5f05396c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1020,
            baseHeight: 870,
            offsetTop: 115,
            offsetLeft: 465,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/33110c96-5c8e-4d48-8a48-053c5f05396c.png',
          },
        ],
      },
    },
  ],
};
export const PolygonSB2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/34f493fd-5052-46f3-8b55-dd20531df9f3.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1100,
            baseHeight: 960,
            offsetTop: 60,
            offsetLeft: 310,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/34f493fd-5052-46f3-8b55-dd20531df9f3.png',
          },
        ],
      },
    },
  ],
};
export const Bayer_Global_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/fb1b2cd6-85cc-4fc8-9d9d-20c2d6ac8e5c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1140,
            baseHeight: 1000,
            offsetTop: 75,
            offsetLeft: 460,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/fb1b2cd6-85cc-4fc8-9d9d-20c2d6ac8e5c.png',
          },
        ],
      },
    },
  ],
};
export const CONNECTSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/ed312ce5-950f-49d6-9cf5-2f653de0794f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 850,
            baseHeight: 770,
            offsetTop: 65,
            offsetLeft: 105,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/ed312ce5-950f-49d6-9cf5-2f653de0794f.png',
          },
        ],
      },
    },
  ],
};
export const MinimalismTechSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/e76d1254-7614-45b1-89ed-244b7ff3245c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 890,
            baseHeight: 850,
            offsetTop: 115,
            offsetLeft: 105,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/e76d1254-7614-45b1-89ed-244b7ff3245c.png',
          },
        ],
      },
    },
  ],
};
export const ServiceNow_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/810d64be-4f39-40e3-97f5-feb3bfb9bde4.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 830,
            baseHeight: 850,
            offsetTop: 115,
            offsetLeft: 105,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/810d64be-4f39-40e3-97f5-feb3bfb9bde4.png',
          },
        ],
      },
    },
  ],
};
export const ElevateUC22_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/93b97bf8-6c36-4c8a-8275-3d71641ca5d2.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 830,
            baseHeight: 850,
            offsetTop: 115,
            offsetLeft: 165,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/93b97bf8-6c36-4c8a-8275-3d71641ca5d2.png',
          },
        ],
      },
    },
  ],
};
export const AIDEASB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/67844e54-770d-4855-b799-4c6cb23cff3b.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1350,
            baseHeight: 1250,
            offsetTop: 160,
            offsetLeft: 680,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/67844e54-770d-4855-b799-4c6cb23cff3b.png',
          },
        ],
      },
    },
  ],
};
export const Lambdatest_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/fc2245d8-e33d-4174-a67c-6115c6445307.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1110,
            baseHeight: 1050,
            offsetTop: 20,
            offsetLeft: 450,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/fc2245d8-e33d-4174-a67c-6115c6445307.png',
          },
        ],
      },
    },
  ],
};
export const Cyberfrat_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/49194006-fbef-4403-a457-000df960f177.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1015,
            baseHeight: 930,
            offsetTop: 75,
            offsetLeft: 435,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/49194006-fbef-4403-a457-000df960f177.png',
          },
        ],
      },
    },
  ],
};
export const Legasis_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/14041c99-bd9c-4c79-ab9b-6afdf286415d.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1015,
            baseHeight: 930,
            offsetTop: 75,
            offsetLeft: 435,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/14041c99-bd9c-4c79-ab9b-6afdf286415d.png',
          },
        ],
      },
    },
  ],
};
export const Legasis2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/26a1bfc1-9edb-4c47-a63c-2c6a7d9d07d3.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1015,
            baseHeight: 930,
            offsetTop: 75,
            offsetLeft: 435,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/26a1bfc1-9edb-4c47-a63c-2c6a7d9d07d3.png',
          },
        ],
      },
    },
  ],
};
export const ADOBE_TRANSCEND_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c4071993-aa37-4544-a5a2-c679f9309bee.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1084,
            baseHeight: 1084,
            offsetTop: 180,
            offsetLeft: 225,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c4071993-aa37-4544-a5a2-c679f9309bee.png',
          },
        ],
      },
    },
  ],
};
export const AOP_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-03.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 832,
            baseHeight: 1048,
            offsetTop: 350,
            offsetLeft: 1200,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-03.png',
          },
        ],
      },
    },
  ],
};
export const AOP_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-04.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1132,
            baseHeight: 1148,
            offsetTop: 580,
            offsetLeft: 1420,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-04.png',
          },
        ],
      },
    },
  ],
};
export const AOP_PHOTOBOOTH3 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-05.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1132,
            baseHeight: 1148,
            offsetTop: 570,
            offsetLeft: 1420,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/aop_photobooth-05.png',
          },
        ],
      },
    },
  ],
};
export const IMEX_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b30b5c9a-ca8a-4bc8-93b1-d21da9734789.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 670,
            baseHeight: 660,
            offsetTop: 225,
            offsetLeft: 200,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b30b5c9a-ca8a-4bc8-93b1-d21da9734789.png',
          },
        ],
      },
    },
  ],
};
export const ZWELLNESS_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/1b6991e8-5e2e-4fd3-8f71-c7cb6d7b648d.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1340,
            baseHeight: 1350,
            offsetTop: 570,
            offsetLeft: 0,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/1b6991e8-5e2e-4fd3-8f71-c7cb6d7b648d.png',
          },
        ],
      },
    },
  ],
};
export const ZWELLNESS_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8839a456-698a-457c-826d-fc8af43c7951.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1160,
            baseHeight: 1320,
            offsetTop: 570,
            offsetLeft: 1530,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/8839a456-698a-457c-826d-fc8af43c7951.png',
          },
        ],
      },
    },
  ],
};
export const SHIFT_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/shift_2021_photobooth.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 532,
            baseHeight: 548,
            offsetTop: 70,
            offsetLeft: 470,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/shift_2021_photobooth.png',
          },
        ],
      },
    },
  ],
};
export const AZ_GIVE_PHOTOBOOTH1: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/24fd2b17-54ac-438c-8d46-7389a849f58f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1610,
            baseHeight: 1610,
            offsetTop: 215,
            offsetLeft: 906,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/24fd2b17-54ac-438c-8d46-7389a849f58f.png',
          },
        ],
      },
    },
  ],
};
export const ETP_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/404069c1-f0e5-4508-8b58-9c1d0756cb25.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1185,
            baseHeight: 1320,
            offsetTop: 370,
            offsetLeft: 770,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/404069c1-f0e5-4508-8b58-9c1d0756cb25.png',
          },
        ],
      },
    },
  ],
};
export const DSCI_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/photobooth_dsci.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 732,
            baseHeight: 698,
            offsetTop: 200,
            offsetLeft: 1050,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/photobooth_dsci.png',
          },
        ],
      },
    },
  ],
};
export const PSAWARDS_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7a3ef0e3-4e62-425f-bb98-65035fc244d9.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 900,
            offsetTop: 125,
            offsetLeft: 510,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7a3ef0e3-4e62-425f-bb98-65035fc244d9.png',
          },
        ],
      },
    },
  ],
};
export const DeliciousExperiencesSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7e7c6f88-f50f-4b76-8807-4732144ee791.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 530,
            baseHeight: 520,
            offsetTop: 70,
            offsetLeft: 60,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/7e7c6f88-f50f-4b76-8807-4732144ee791.png',
          },
        ],
      },
    },
  ],
};
export const RealMeB1_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c77223c9-3a04-4db9-ab88-662342d5e1c6.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 950,
            baseHeight: 1000,
            offsetTop: 40,
            offsetLeft: 30,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/c77223c9-3a04-4db9-ab88-662342d5e1c6.png',
          },
        ],
      },
    },
  ],
};
export const RealMeB2_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/2b359e24-5f8d-4f1d-b9bf-5256fcfd72b5.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1110,
            baseHeight: 900,
            offsetTop: 50,
            offsetLeft: 720,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/2b359e24-5f8d-4f1d-b9bf-5256fcfd72b5.png',
          },
        ],
      },
    },
  ],
};
export const LuvPalooza_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/6958f6e0-d6d0-4a82-b203-348f511df32e.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1225,
            baseHeight: 1040,
            offsetTop: 30,
            offsetLeft: 350,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/6958f6e0-d6d0-4a82-b203-348f511df32e.png',
          },
        ],
      },
    },
  ],
};
export const TallyDigiSabha_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/896572ea-da3c-49bc-9b1c-e9195eb80397.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 325,
            baseHeight: 350,
            offsetTop: 80,
            offsetLeft: 300,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/896572ea-da3c-49bc-9b1c-e9195eb80397.png',
          },
        ],
      },
    },
  ],
};
export const DSCIFinsec_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b2653ba7-1eb6-4636-9db6-7fd0f7e35431.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 700,
            baseHeight: 700,
            offsetTop: 220,
            offsetLeft: 80,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b2653ba7-1eb6-4636-9db6-7fd0f7e35431.png',
          },
        ],
      },
    },
  ],
};
export const AnnualEventSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/f70e1bc2-1999-4809-914c-2febe4dcf75d.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 900,
            offsetTop: 100,
            offsetLeft: 70,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/f70e1bc2-1999-4809-914c-2febe4dcf75d.png',
          },
        ],
      },
    },
  ],
};
export const NewHireSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/6dae58b8-812c-4ddd-80e6-cf1a206d6d6f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 900,
            offsetTop: 100,
            offsetLeft: 70,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/6dae58b8-812c-4ddd-80e6-cf1a206d6d6f.png',
          },
        ],
      },
    },
  ],
};
export const RidersMusicFestivalSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5e492be0-fe92-4d79-adef-22f44b02cdc9.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 900,
            offsetTop: 100,
            offsetLeft: 70,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5e492be0-fe92-4d79-adef-22f44b02cdc9.png',
          },
        ],
      },
    },
  ],
};
export const SalesMeetSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/960d41ff-919a-4ce1-b7b2-39dd758d31f6.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 900,
            baseHeight: 900,
            offsetTop: 100,
            offsetLeft: 70,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/960d41ff-919a-4ce1-b7b2-39dd758d31f6.png',
          },
        ],
      },
    },
  ],
};
export const ManchesterSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/884765c2-798c-49ad-abc8-a8c60e9641e4.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 400,
            baseHeight: 400,
            offsetTop: 0,
            offsetLeft: 30,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/884765c2-798c-49ad-abc8-a8c60e9641e4.png',
          },
        ],
      },
    },
  ],
};
export const Cipla2022_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/0e6547b7-281e-469d-bb25-d28b934b7385.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2500,
            baseHeight: 1500,
            offsetTop: 400,
            offsetLeft: 580,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/0e6547b7-281e-469d-bb25-d28b934b7385.png',
          },
        ],
      },
    },
  ],
};
export const REDHATSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/53afbe1f-2ae7-46db-a864-33047457c47c.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/53afbe1f-2ae7-46db-a864-33047457c47c.png',
          },
        ],
      },
    },
  ],
};
export const VODAFONESB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5483a7cc-3fc8-4254-9a67-e68254f2df5a.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/5483a7cc-3fc8-4254-9a67-e68254f2df5a.png',
          },
        ],
      },
    },
  ],
};
export const BACARDISB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b3cc43a7-c68a-4bbc-8088-b86a41d27a3f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/b3cc43a7-c68a-4bbc-8088-b86a41d27a3f.png',
          },
        ],
      },
    },
  ],
};
export const MeeshoSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/323fbc48-6865-449a-a2fe-9dd8ebeb1a86.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/323fbc48-6865-449a-a2fe-9dd8ebeb1a86.png',
          },
        ],
      },
    },
  ],
};
export const DELOITTESB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d1fe3609-0d1d-448d-92a9-80284a3f17b1.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/d1fe3609-0d1d-448d-92a9-80284a3f17b1.png',
          },
        ],
      },
    },
  ],
};export const UBERSB_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/a77af443-7079-4d0e-816f-1024dbdd4ac9.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 80,
            offsetLeft: 320,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/a77af443-7079-4d0e-816f-1024dbdd4ac9.png',
          },
        ],
      },
    },
  ],
};
export const MYTHOLOGY_SHOW_PRESS_CONFERENCE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3d0b25bf-4753-4cee-be95-61513e3c4f77.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1400,
            baseHeight: 850,
            offsetTop: 100,
            offsetLeft: 250,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/3d0b25bf-4753-4cee-be95-61513e3c4f77.png',
          },
        ],
      },
    },
  ],
};
export const NACD_GALA_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/3fac6271-ca30-4424-884c-e8d46bbfe253.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1640,
            baseHeight: 1640,
            offsetTop: 270,
            offsetLeft: 650,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/3fac6271-ca30-4424-884c-e8d46bbfe253.png',
          },
        ],
      },
    },
  ],
};

export const BRITANNIA_CLUB_ROYALE_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/74522aee-600d-4cfb-b634-d45a25a56603.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 2290,
            baseHeight: 2290,
            offsetTop: 1450,
            offsetLeft: 3080,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/74522aee-600d-4cfb-b634-d45a25a56603.png',
          },
        ],
      },
    },
  ],
};

export const PCMA_RE_UNITE_PHOTOBOOTH: IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/95babf67-b2be-4721-9273-8ba9905e8c5f.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1690,
            baseHeight: 1690,
            offsetTop: 150,
            offsetLeft: 550,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src:
              'https://phoenixlive.imgix.net/95babf67-b2be-4721-9273-8ba9905e8c5f.png',
          },
        ],
      },
    },
  ],
};
export const INDIAMOBILECONGRESS_EXPO_OUTER1:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/6f45a5bb-7d20-4dbd-b1c1-799541bd0b1b.jpeg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 485,
      y: 275,
      width: 286,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '3dd330b0-f604-447a-8812-9512ba77f175',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 833,
      y: 287,
      width: 256,
      height: 171,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '99cd99fa-ae20-427b-9206-d83599fd8d74',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1168,
      y: 273,
      width: 262,
      height: 171,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '61025959-3c7a-49eb-9fcf-24c2ddd7bf75',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 485,
      y: 455,
      width: 286,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '8e6d1dbb-2d0f-4f6e-92e2-8cc56114a319',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 833,
      y: 455,
      width: 256,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '21e393b9-b2bc-42f3-91d9-c2e67e9974f7',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1148,
      y: 455,
      width: 286,
      height: 197,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a682a48e-a9f6-4581-8211-349e78e27434',
        buttonLinkType: "internal"
      },
    }
  ],
};
export const WORLDBANK_EXPO_OUTER1:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/cc69a84c-8fe0-4f28-a1ae-bded233fe836.png',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 800,
      y: 450,
      width: 285,
      height: 170,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1de58791-2d75-4015-88d1-5461defa8122',
        buttonLinkType: "internal"
      },
    },
  ],
};

export const TESCO_PHOTOBOOTH:IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/TESCO_photoframe.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 732,
            baseHeight: 638,
            offsetTop: 300,
            offsetLeft: 1100,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/TESCO_photoframe.png',
          },
        ],
      },
    },
  ],
};
export const UECE_PHOTOBOOTH = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/520b1bc3-ad35-46f0-a1b8-cde4c4c613a0.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 1700,
            baseHeight: 1700,
            offsetTop: 300,
            offsetLeft: 2000,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/520b1bc3-ad35-46f0-a1b8-cde4c4c613a0.png',
          },
        ],
      },
    },
  ],
};
export const GoogleSB_PHOTOBOOTH1 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/1cead812-4585-413a-9ec3-80138512d757.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 600,
            baseHeight: 550,
            offsetTop: 160,
            offsetLeft: 460,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/1cead812-4585-413a-9ec3-80138512d757.png',
          },
        ],
      },
    },
  ],
};
export const GoogleSB_PHOTOBOOTH2 = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/67dfc432-3bbd-4e63-80f7-b387b589ca7b.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 800,
            baseHeight: 500,
            offsetTop: 160,
            offsetLeft: 600,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/67dfc432-3bbd-4e63-80f7-b387b589ca7b.png',
          },
        ],
      },
    },
  ],
};
export const AISS2021_PHOTOBOOTH:IBoothConfigType = {
  type: 'GRID',
  bgWidth: '100%',
  bgHeight: '100%',
  zone: 'booth',
  widgets: [
    {
      type: WidgetTypes.PHOTOBOOTH,
      x: 0,
      y: 0,
      width: '100%',
      height: '100%',
      config: {
        layers: [
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/bb6169fc-29e5-417f-897c-a269299b6458.png',
          },
          {
            type: PhotoboothLayerTypes.PREVIEW,
            baseWidth: 632,
            baseHeight: 638,
            offsetTop: 230,
            offsetLeft: 1130,
          },
          {
            type: PhotoboothLayerTypes.IMAGE,
            src: 'https://phoenixlive.imgix.net/bb6169fc-29e5-417f-897c-a269299b6458.png',
          },
        ],
      },
    },
  ],
};
export const serviceNow_Expo:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:'',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.MEDIA,
      x: 0,
      y: 0,
      width: 1920,
      height: 1080,
      config: {
        type: MediaWidgetTypes.VIDEO,
        controls: false,
        src:'https://stream.mux.com/FrKcQRdZ6i72p4PO1sXcqu016Tn01e301nOaK36Ehn4TsU.m3u8',
        muted: true,
        loop: true,
      },
    },
    // photo booth
    {
      type: WidgetTypes.HOTSPOT,
      x: 380,
      y: 280,
      width: 445,
      height: 245,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '0e0b63a4-e46b-4e9b-b569-5a21a3153298',
        buttonLinkType: "internal"
      },
    },
    // talent
    {
      type: WidgetTypes.HOTSPOT,
      x: 1100,
      y: 280,
      width: 450,
      height: 245,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'bba0c50c-3e40-4d09-8260-254321559120',
        buttonLinkType: "internal"
      },
    },
    // meet our designers
    {
      type: WidgetTypes.HOTSPOT,
      x: 30,
      y: 550,
      width: 480,
      height: 265,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: '1f048c16-51ee-4bbe-aff8-ce9087e8e7f1',
        buttonLinkType: "internal"
      },
    },
    // meet our leadership
    {
      type: WidgetTypes.HOTSPOT,
      x: 720,
      y: 550,
      width: 480,
      height: 265,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'a1d57b8c-5431-447d-872e-777724cfa734',
        buttonLinkType: "internal"
      },
    },
    // meet out writers
    {
      type: WidgetTypes.HOTSPOT,
      x: 1390,
      y: 550,
      width: 505,
      height: 265,
      config: {
        zone: "discussions",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'fda6fb93-19ad-45a7-aa02-5abaea348a3b',
        buttonLinkType: "internal"
      },
    },
  ],
};
export const INDIAMOBILECONGRESS_EXPO_OUTER2:IExpoConfigType = {
  type: 'MANUAL',
  backgroundType: 'IMAGE', // IMAGE, GIF, VIDEO
  backgroundUrl:
    'https://phoenixlive.imgix.net/6f45a5bb-7d20-4dbd-b1c1-799541bd0b1b.jpeg',
  bgWidth: 1920,
  bgHeight: 1080,
  widgets: [
    {
      type: WidgetTypes.HOTSPOT,
      x: 485,
      y: 275,
      width: 286,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 833,
      y: 287,
      width: 256,
      height: 171,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1168,
      y: 273,
      width: 262,
      height: 171,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 485,
      y: 455,
      width: 286,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 833,
      y: 455,
      width: 256,
      height: 181,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    },
    {
      type: WidgetTypes.HOTSPOT,
      x: 1148,
      y: 455,
      width: 286,
      height: 197,
      config: {
        zone: "expo",
        type: "BUTTON",
        buttonType: "HOTSPOT",
        withinZone: 'b9d5d074-9f99-4cb1-97d0-a06820369b91',
        buttonLinkType: "internal"
      },
    }
  ],
};
export const ManualLayoutConfig = {
  ZUDDL_HEXGL,
  ZUDDL_ASTRAY,

  DEMO_LOBBY: DEMO1_CONFIG,
  EXPO_BOOTH: EXPO1_CONFIG,
  DEMO2_LOBBY: DEMO2_CONFIG,
  EXPO2_BOOTH: EXPO2_CONFIG,
  DEMO_DAIMLER: DEMO_DAIMLER,
  EXPO_DAIMLER: EXPO_DAIMLER,
  DELHI_CAPITALS_LOBBY,
  MICROSOFT_LOBBY,
  MICROSOFT_EXPO,
  REIMAGINE_LOBBY,
  REIMAGINE_EXPO,
  IMPOYZ_LOBBY,
  TAPASYA_LOBBY,
  DELOITTE_LOBBY,
  AMAZON_LOBBY,
  IMPOYZ_UNBRANDED_LOBBY,
  AVIGAN_LOBBY,
  AVIGAN_BOOTH1,
  AVIGAN_BOOTH2,
  AVIGAN_PHOTOBOOTH1,
  AVIGAN_PHOTOBOOTH2,
  EXPO_GENERIC,
  REALPAGE_PHOTOBOOTH,
  REALPAGE_LOBBY,
  SUNFEAST_LOBBY,
  SUNFEAST_PHOTOBOOTH,
  DERMA_LOBBY,
  BRITANNIA_LOBBY,
  BRITANNIA_PURE_MAGIC,
  BRITANNIA_CAKE,
  BRITANNIA_CHEESE,
  BRITANNIA_GOOD_DAY,
  BRITANNIA_MILK_BIKIS,
  BRITANNIA_NUTRICHOICE,
  BRITANNIA_TIGER,
  BRITANNIA_TREAT,
  BRITANNIA_BOOTH1,
  BRITANNIA_BOOTH2,
  BRITANNIA_BOOTH3,
  PHARMACO_PHOTOBOOTH,
  PHARMACO_LOBBY,
  DERMA_DEMO_BOOTH1,
  DERMA_DEMO_BOOTH2,
  NASSCOM_LOBBY,
  MICROSOFT_WINDOWS10,
  MICROSOFT_OFFICE,
  MICROSOFT_SERVER,
  MICROSOFT_HP,
  MICROSOFT_PANASONIC,
  MICROSOFT_LENOVO,
  MICROSOFT_TATA,
  MICROSOFT_SATYAMEV,
  MICROSOFT_FINAL_LOBBY,
  DERMA_ANTOXID,
  DERMA_ATARAX,
  DERMA_EBERNET,
  DERMA_MEDI_ASSIST,
  DERMA_IMPOYZ,
  DERMA_REVIBRA,
  DERMA_MINTOP,
  DERMA_REVILUS,
  DERMA_XYZAL,
  DERMA_NEXRET,
  DERMA_VENUSIA,
  DERMA_VENUSIA_DERM,
  DERMA_PHOTOBOOTH,
  DERMA_CERTIFICATE,
  COLGATE_FINAL_LOBBY,
  COLGATE_GAME,
  DRGFM_LOBBY,
  DRGFM_PHOTOBOOTH,
  SYNCHRONY_LOBBY,
  SYNCHRONY_PHOTOBOOTH1,
  SYNCHRONY_PHOTOBOOTH2,
  SYNCHRONY_PHOTOBOOTH3,
  SYNCHRONY_PHOTOBOOTH4,
  NASSCOM_GOVT_OF_KARNATAKA,
  NASSCOM_MTDC,
  NASSCOM_BOSCH,
  NASSCOM_CAPGEMIN,
  NASSCOM_CYIENT,
  NASSCOM_HCL,
  NASSCOM_INFOSYS,
  NASSCOM_LT,
  NASSCOM_QUESTGLOBAL,
  NASSCOM_WIPRO,
  NASSCOM_CARRIER,
  NASSCOM_SIEMENS,
  NASSCOM_TCS,
  NASSCOM_TECHMAHINDRA,
  NASSCOM_BAVARIA,
  NASSCOM_SATVEN,
  MOCK_LOBBY,
  SEP29DEMO_LOBBY,
  MMA_LOBBY,
  SPEAKERDRYRUNS_LOBBY,
  MICROSOFTGLEAMINDIA_LOBBY,
  BANTERFEST_LOBBY,
  APQF_LOBBY,
  APQF_BOOTH1,
  CIPLA_LOBBY,
  SYNOPSIS_LOBBY,
  HEXAGON_LOBBY,
  CIPLA_ACNE_BOOTH1,
  CIPLA_HAIR_BOOTH2,
  CIPLA_FUNGAL_BOOTH3,
  CIPLA_PROTECTIVES_BOOTH4,
  CIPLA_DIGIRECORDS_BOOTH5,
  CIPLA_MEDISURE_BOOTH6,
  CIPLA_DASI_BOOTH7,
  CIPLA_CIPLAMED_BOOTH8,
  CIPLA_PUBLICATIONGALLERY_BOOTH9,
  CIPLA_QUIZ_BOOTH10,
  MMAPROD_LOBBY,
  MMA_INMOBI_BOOTH1,
  MMA_EPICGAME_BOOTH2,
  MMA_GOOGLE_BOOTH3,
  MMA_ORACLE_BOOTH4,
  CIPLA_NEW_PHOTOBOOTH1,
  MMA_EXPO_OUTER,
  CIPLA_NEW_LOBBY,
  APPOLLO_LOBBY,
  APPOLLO_PHOTOBOOTH1,
  APPOLLO_BOOTH2,
  MMA_EXPO_OUTER_CONFIG,
  BIRCLAYS_LOBBY,
  APOLLO_AR_BOOTH1,
  APOLLO_AR_BOOTH2,
  APOLLO_AR_BOOTH3,
  APOLLO_FOUR_WHEELER_BOOTH3,
  APOLLO_TWO_WHEELER_BOOTH4,
  PMSYMPOSIUM_LOBBY,
  PMSYMPOSIUM_PHOTOBOOTH1,
  PMSYMPOSIUM_BOOTH2,
  BIRLASOFTGMM_LOBBY,
  PMSYMPOSIUM_BOOTH3,
  PMSYMPOSIUM_BOOTH4,
  PMSYMPOSIUM_BOOTH5,
  ADOBE_LOBBY,
  HEXAGONNEW_LOBBY,
  PEPSICO_LOBBY,
  DRREDDYSRZAN_LOBBY,
  PEPSICO_BOOTH_1,
  PEPSICO_BOOTH_2,
  PEPSICO_BOOTH_3,
  PEPSICO_BOOTH_4,
  PEPSICO_BOOTH_5,
  PEPSICO_BOOTH_6,
  PEPSICO_BOOTH_7,
  PEPSICO_PHOTOBOOTH_1,
  PEPSICO_EXPO_OUTER,
  ADOBE_BOOTH_1,
  ADOBE_BOOTH_2,
  ADOBE_BOOTH_3,
  ADOBE_BOOTH_4,
  ADOBE_BOOTH_5,
  DRREDDYSRZAN_PHOTOBOOTH_1,
  ADOBE_PHOTOBOOTH_1,
  APP_PHOTOBOOTH,
  HEXAGON_BOOTH_1,
  HEXAGON_BOOTH_2,
  HEXAGON_BOOTH_3,
  HEXAGON_BOOTH_4,
  HEXAGON_BOOTH_5,
  HEXAGON_BOOTH_6,
  HEXAGON_BOOTH_7,
  HEXAGON_BOOTH_8,
  HEXAGON_BOOTH_9,
  HEXAGON_BOOTH_10,
  HEXAGON_BOOTH_11,
  HEXAGON_BOOTH_12,
  MMAINDONESIAIMPACT_LOBBY,
  MMAINDONESIAIMPACT_EXPO_OUTER,
  DEFAULT_EXPO_CONFIG,
  DEFAULT_GRID_CONFIG,
  MAGNIFIQUE_PHOTOBOOTH_1,
  MAGNIFIQUE_PHOTOBOOTH_2,
  IAA_PHOTOBOOTH_1,
  MMAINDIASMARTIES_LOBBY,
  MAGNIFIQUE_AR_BOOTH,
  SPEAKERDRYRUNS_PHOTOBOOTH_1,
  MMASMARTIESINDONESIA_LOBBY,
  PEPSICOTM_PHOTOBOOTH_1,
  MMASMARTIESAPAC_LOBBY,
  PEPSICOTMSC_LOBBY,
  MMASMARTIEEMEA_LOBBY,
  CLEARTAX_BOOTH_1,
  CLEARTAX_BOOTH_2,
  CLEARTAX_BOOTH_3,
  CLEARTAX_BOOTH_4,
  CLEARTAX_BOOTH_5,
  CLEARTAX_BOOTH_6,
  ZUDDLMOCK_PHOTOBOOTH_1,
  ZUDDLMOCK_BOOTH_1,
  MICROSOFT_DIGITAL_BOOTH_1,
  VIRTUAL_OLYMPIC_GAMES,
  MICROSOFT_ED_OFFSITE,
  MICROSOFT_TRANSCEND_BOOTH1,
  MICROSOFT_TRANSCEND_BOOTH2,
  MICROSOFT_DIGITAL_BOOTH_2,
  MICROSOFT_GLEAM_PHOTOBOOTH_1,
  ELITE_EXCELLENCE_PHOTOBOOTH_1,
  ESSILOR_IGNITE_PHOTOBOOTH,
  MMARI2021_LOBBY,
  WHITEHAT_JS_PHOTOBOOTH,
  ZUDDL_VO_PHOTOBOOTH,
  ASPENTECH_OUTER_EXPO,
  WHITE_HAT_JR_PHOTOBOOTH,
  TIK_TOK_PHOTOBOOTH,
  SAAS_INSIDER_PHOTOBOOTH,
  PHOENIX_LIVE_PHOTOBOOTH,
  ZUDDL_VO_PHOTOBOOTH2,
  QUALCOMM_PHOTOBOOTH,
  CHANGE_HEALTHCARE_PHOTOBOOTH,
  BOMBALIVE_PHOTOBOOTH,
  INDIAMOBILECONGRESS_EXPO_OUTER,
  ADOBE_ALCHEMY_PHOTOBOOTH,
  AOP_PHOTOBOOTH,
  AOP_PHOTOBOOTH2,
  AOP_PHOTOBOOTH3,
  SHIFT_PHOTOBOOTH,
  DSCI_PHOTOBOOTH,
  MYTHOLOGY_SHOW_PRESS_CONFERENCE_PHOTOBOOTH,
  ZWELLNESS_PHOTOBOOTH1,
  ZWELLNESS_PHOTOBOOTH2,
  ETP_PHOTOBOOTH,
  PCMA_RE_UNITE_PHOTOBOOTH,
  INDIAMOBILECONGRESS_EXPO_OUTER1,
  TESCO_PHOTOBOOTH,
  AISS2021_PHOTOBOOTH,
  ZUDDL_PHOTOBOOTH10,
  PSAWARDS_PHOTOBOOTH,
  OTICONNEXT_2022,
  DELOITTESB_PHOTOBOOTH,
  UBERSB_PHOTOBOOTH,
  BACARDISB_PHOTOBOOTH,
  MeeshoSB_PHOTOBOOTH,
  Bayer_Expo,
  Finsec2022_Expo,
  GoogleSB_PHOTOBOOTH1,
  GoogleSB_PHOTOBOOTH2,
  GoogleSB_Expo,
  UECE_PHOTOBOOTH,
  VODAFONESB_PHOTOBOOTH,
  REDHATSB_PHOTOBOOTH,
  RockwellAutomationSB_Expo,
  Cipla2022_PHOTOBOOTH,
  WORLDBANK_EXPO_OUTER1,
  ManchesterSB_PHOTOBOOTH,
  AnnualEventSB_PHOTOBOOTH,
  NewHireSB_PHOTOBOOTH,
  RidersMusicFestivalSB_PHOTOBOOTH,
  SalesMeetSB_PHOTOBOOTH,
  DSCIFinsec_PHOTOBOOTH,
  TallyDigiSabha_PHOTOBOOTH,
  RealMeB1_PHOTOBOOTH,
  RealMeB2_PHOTOBOOTH,
  LuvPalooza_PHOTOBOOTH,
  DeliciousExperiencesSB_PHOTOBOOTH,
  SandboxMS_Expo,
  SandboxMS_PHOTOBOOTH,
  Veeco1_Expo,
  Veeco2_Expo,
  Veeco3_Expo,
  Veeco4_Expo,
  OTRGlobal1_PHOTOBOOTH,
  SandboxMS2_Expo,
  UnileverSB_PHOTOBOOTH,
  AccentureSB_PHOTOBOOTH,
  DUSIPM1_PHOTOBOOTH,
  DUSIPM2_PHOTOBOOTH,
  DUSIPM3_PHOTOBOOTH,
  DUSIPM4_PHOTOBOOTH,
  DUSIPM5_PHOTOBOOTH,
  Adobe2022_PHOTOBOOTH,
  Juneteenth_Expo,
  DubaiHolding_PHOTOBOOTH,
  Fractal1729_PHOTOBOOTH,
  Lambdatest_PHOTOBOOTH,
  Cyberfrat_PHOTOBOOTH,
  Legasis_PHOTOBOOTH,
  Fractal1729_2_PHOTOBOOTH,
  Fractal1729_3_PHOTOBOOTH,
  Legasis2_PHOTOBOOTH,
  FractalGWR_1_PHOTOBOOTH,
  FractalGWR_2_PHOTOBOOTH,
  CH_FamilyDay_PHOTOBOOTH,
  PolygonSB_PHOTOBOOTH,
  TidalSB_PHOTOBOOTH,
  Tidal_EXPO_OUTER1,
  ChargebeeSB_PHOTOBOOTH,
  GTIPCAug22_PHOTOBOOTH,
  GTIPCAug22_Expo,
  Zuddl_HR_PHOTOBOOTH,
  SATAIRSB_EXPO,
  SATAIRSB_PHOTOBOOTH,
  BPM2022_PHOTOBOOTH,
  ARTSGROWSCSB_EXPO,
  SHRMSB_PHOTOBOOTH,
  BPM2022_Expo,
  EYSB_PHOTOBOOTH,
  TinymagiqSB_PHOTOBOOTH,
  AlphaWaveSB_EXPO,
  SenseHawkSB_PHOTOBOOTH,
  PolygonSB2_PHOTOBOOTH,
  Bayer_Global_PHOTOBOOTH,
  PreSaleBPM2022Copy_Expo,
  CONNECTSB_PHOTOBOOTH,
  PresaleFinsec2022Copy_Expo,
  MinimalismTechSB_PHOTOBOOTH,
  ServiceNow_PHOTOBOOTH,
  ElevateUC22_PHOTOBOOTH,
  AIDEASB_PHOTOBOOTH,
  serviceNow_Expo,
  INDIAMOBILECONGRESS_EXPO_OUTER2,
  NeomSB_Expo,
  IMEX_PHOTOBOOTH,
  Revenera_Expo,
  NeomRenewed_Expo,
  GT8th_PHOTOBOOTH,
  Chargefly_PHOTOBOOTH,
  Everafter_PHOTOBOOTH,
  QS_PHOTOBOOTH,
  AFCONS_PHOTOBOOTH,
  KPMGCH2023_PHOTOBOOTH,
  PROPEL2023_PHOTOBOOTH,
  KPMGCH2023_Expo,
  HUX24_PHOTOBOOTH,
  VeecoLL2_Expo,
  FINALTEMP_PHOTOBOOTH,
  ROCKETLANE_PHOTOBOOTH1,
  QS_PHOTOBOOTH1,
  QS_PHOTOBOOTH2,
  POSTSCRIPT_1,
  //SURAJ_MASTER_PHOTOBOOTH,
  
};
