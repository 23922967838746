import * as React from 'react';
import classnames from 'classnames';
import Loader from '../Loader';

import styles from './styles.module.scss';

const FullScreenLoader = ({ className = "" }) => {
  return <div className={classnames(styles.loaderWrapper, styles[className])}>
    <Loader />
  </div>
}

export default FullScreenLoader;