export interface IChatBoxProps {
  chatBoxType: IChatBoxTypes;
  messages: any;
  onMessageSubmit: any;
  attendeeChat?: Boolean;
  chatSelected?: any;
  setChatSelected?: any;
  channelConfig?: any;
  showChannelAttendeesOnly?: any;
  defaultSearchText?: string;
  addAttendeeMessage?: any;
  isHost?: Boolean;
  isBooth?: Boolean;
  openApproveModel?: any;
  isOrganizer?: boolean;
  isEventChat?: boolean;
  isStage?: boolean;
  isBackstage?: boolean;
  isRoom?: boolean;
  hasChatModerate?: boolean;
  eventChannelSettings?: any;
  showProfanityWarning?: boolean;
  setShowProfanityWarning?: any;
  pinnedMessageList?: any;
  isLiveEventOrganizer?: boolean;
  hasChatEmoji?: boolean;
  hasGif?: boolean;
  hasBanner?: boolean;
  children?: React.ReactNode;
  onSearchChange: (text: string) => void;
  isStudioStage?: boolean;
  updateReactions?: (reaction: any, messageId: string) => void;
  attendeeChannel?: any;
  allowMessageReactions?: boolean;
}

export enum IChatBoxTypes {
  PRIVATE = 'private',
  PUBLIC = 'public',
  ATTENDEE = 'attendee',
}

export enum IChatBoxParent {
  PINNED_MESSAGE = 'pinnedMessage',
  CHAT = 'chat',
}
