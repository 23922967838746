import { Dispatch, SetStateAction, useEffect } from 'react';
import { Reaction } from '@/api/reactions';

interface ChatListenerProps {
  channel: any;
  messageId: number;
  currentUserAccountId: string;
  setReactionsListData?: Dispatch<SetStateAction<Record<string, Reaction[]>>>;
  pusherEvent: string;
  updateReactions?: any;
  reactionsListData?: Record<string, Reaction[]>;
  currentMessageId?: any;
  messages?: any;
}

const handleReactionUpdate = (
  updatedReaction: {
    messageId: string;
    action: string;
    reaction: Reaction;
  },
  data: Record<string, Reaction[]> | undefined,
) => {
  const currentReactions = { ...data };

  // Ensure each entry is a mutable array
  Object.keys(currentReactions).forEach(key => {
    currentReactions[key] = [...currentReactions[key]];
  });

  const { eH, aId } = updatedReaction.reaction;
  if (updatedReaction.action === 'ADDED') {
    if (!currentReactions[eH]) {
      currentReactions[eH] = [];
    }
    const existingIndex = currentReactions[eH].findIndex(
      reaction => reaction.aId === aId,
    );
    if (existingIndex !== -1) {
      currentReactions[eH][existingIndex] = updatedReaction.reaction;
    } else {
      currentReactions[eH].push(updatedReaction.reaction);
    }
  } else {
    currentReactions[eH] = (currentReactions[eH] || []).filter(
      reaction => reaction.aId !== aId,
    );
  }
  return currentReactions;
};

const useReactionListener = ({
  channel,
  messageId,
  currentUserAccountId,
  setReactionsListData,
  pusherEvent,
  updateReactions,
  reactionsListData,
  currentMessageId,
  messages,
}: ChatListenerProps) => {
  useEffect(() => {
    if (!channel || !currentUserAccountId || !messageId) {
      return undefined;
    }
    const reactionListener = (data: string) => {
      try {
        const updatedReaction = JSON.parse(data) as {
          messageId: string;
          action: string;
          reaction: Reaction;
        };
        const currentMessageReactions = messages?.find(
          message => message.messageId === updatedReaction.messageId,
        )?.reactions;

        if (messages) {
          const newReactionsData = handleReactionUpdate(
            updatedReaction,
            currentMessageReactions,
          );

          updateReactions?.(newReactionsData, updatedReaction.messageId);
        } else if (
          parseInt(updatedReaction.messageId, 10) === messageId &&
          setReactionsListData
        ) {
          const newReactionsData = handleReactionUpdate(
            updatedReaction,
            reactionsListData,
          );
          setReactionsListData(newReactionsData);
          updateReactions(newReactionsData, currentMessageId);
        }
      } catch (error) {
        console.error('Error parsing reaction data:', error);
      }
    };

    channel.bind(pusherEvent, reactionListener);

    return () => {
      channel.unbind(pusherEvent, reactionListener);
    };
  }, [
    channel,
    messageId,
    currentUserAccountId,
    setReactionsListData,
    updateReactions,
    pusherEvent,
    reactionsListData,
    messages,
    currentMessageId,
  ]);
};

export default useReactionListener;
