import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useLocation, useParams } from 'react-router';
import { _isNull } from '@/utils/jsHelpers/obj';
// components
import Widget from '@/components/custom/widgets/Widget';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
// configs + types
import customConfig from '@/custom-config';
import { IUIIcons } from '@/types';
import { IManualLayoutParents } from '../types';
// hooks
import useDimensions from '@/hooks/use-dimensions';
import useImageDimensions from '@/hooks/use-image-dimensions';
// styles
import classnames from 'classnames';
import styles from './styles.module.scss';
import { makeSelectFeatureFlagConfigById } from '@/models/event';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
import { useMediaQuery } from 'react-responsive';
import OverLay from './Overlay';
import { Loader } from 'react-feather';
import {
  getImageUrlWithSize,
  getSrcSetByImgHeight,
  getSrcSetForLobbyMobileView,
  prefixPicUrlIfNeeded,
} from '@/utils/helpers';
import FullScreenLoader from '@/components/ui/FullScreenLoader';
import isBoolean from 'lodash/isBoolean';
import { useNavbarVisibility } from '@/hooks/use-navbar-visibility';

const { VIDEO, FILES, CAMERA, KEYNOTE } = IUIIcons;
let mounted = false;
let lastParent = '';
const NormalManualLayout = ({
  isChatOpen,
  config: CONFIG,
  parent = IManualLayoutParents.OTHER,
  widgetDetails,
  layoutDetails,
  handleShowBoothOwners,
  showContactUsWidget,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const bgImageUrl = useMemo(() => {
    if (isMobile && layoutDetails && layoutDetails.hasMobViewEnabled) {
      return layoutDetails.mobBgUrl
        ? layoutDetails.mobBgUrl
        : 'https://phoenixlive.imgix.net/dbad7b7d-c56c-43b8-b538-fc90c6b8ce11'; // default black background image with 1080X1920
    }
    return layoutDetails && layoutDetails.bgUrl
      ? layoutDetails.bgUrl
      : CONFIG.backgroundUrl;
  }, [layoutDetails, isMobile, CONFIG]);

  const backgroundFillType = layoutDetails.fillType;
  let backgroundImageBlurred =
    bgImageUrl && `${bgImageUrl}?auto=format,compress&q=75&sharp=10&blur=80`;
  const bgImageScaled = bgImageUrl && `${bgImageUrl}`;

  const { eventId = '', boothId } = useParams();
  const eventConfig = customConfig[eventId] || customConfig.default;
  const location = useLocation();
  const isLobby = location.pathname.includes('lobby');
  const isBooth = location.pathname.includes('booth');
  const isExpo = location.pathname.includes('/expo');
  
  const { showNavbar } = useNavbarVisibility()

  const isMobileVerticalView =
    isMobile && (isLobby || isBooth) && layoutDetails?.hasMobViewEnabled;

  const [mainContainerCallbackRef, mainContainerDims] = useDimensions([
    isMobileVerticalView ? false : isChatOpen,
  ]);
  const [imageCallbackRef, imageDims] = useImageDimensions(
    [isMobileVerticalView ? false : isChatOpen],
    isMobileVerticalView,
  );
  let { useOldLobbyStageLayout, disableBoothScroll } = useMemoizedSelector(
    makeSelectFeatureFlagConfigById,
    eventId,
  );
  //For booths, disableBoothScroll takes precedence if it is set.
  if (isBooth && isBoolean(disableBoothScroll)) {
    useOldLobbyStageLayout = disableBoothScroll;
  }

  const [sizeStyles, setSizeStyles] = useState({
    width: '100%',
    height: '100%',
    widthScaleFactor: 1,
    heightScaleFactor: 1,
  });
  const [isScrollEnd, setIsScrollEnd] = useState(false);
  const [firstDims, setFirstDims] = useState();
  const [showStopOverlay, setStopOverlay] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const leftScrollRef = useRef();
  const rightScrollRef = useRef();

  useEffect(() => {
    const { width, height } = sizeStyles;
    if (!firstDims) {
      setFirstDims({
        width,
        height,
      });
    }
  }, [sizeStyles]);

  useEffect(() => {
    if (!mainContainerDims || !imageDims) {
      return;
    }
    const {
      width: containerWidth,
      height: containerHeight,
    } = mainContainerDims;
    const containerRatio = containerWidth / containerHeight;
    const { naturalWidth: imageWidth, naturalHeight: imageHeight } = imageDims;
    const imageRatio = imageWidth / imageHeight;

    const [scaledWidth, scaledHeight] = (() => {
      if (isMobileVerticalView) {
        return [containerWidth, (containerWidth * imageHeight) / imageWidth];
      }
      if (isMobile && (isLobby || isBooth || isExpo)) {
        return [containerHeight * (imageWidth / imageHeight), containerHeight];
      }
      if (!useOldLobbyStageLayout) {
        return [containerWidth, (containerWidth * imageHeight) / imageWidth];
      }
      if (containerRatio === imageRatio) {
        return [containerWidth, containerHeight];
      }
      if (containerRatio > imageRatio) {
        return [containerHeight * imageRatio, containerHeight];
      }
      return [containerWidth, (containerWidth * imageHeight) / imageWidth];
    })();

    const [refWidth, refHeight] = (() => {
      if (isMobileVerticalView) {
        return [1080, 1920];
      }
      return [1920, 1080];
    })();

    setSizeStyles({
      width: scaledWidth,
      height: scaledHeight,
      widthScaleFactor: scaledWidth / refWidth,
      heightScaleFactor: scaledHeight / refHeight,
    });
  }, [mainContainerDims, imageDims, layoutDetails]);

  const { width, height, heightScaleFactor, widthScaleFactor } = sizeStyles;

  const draggableScaleFactor = firstDims ? width / firstDims.width : 1;

  const ready = mainContainerDims && imageDims;

  const showButtons =
    eventConfig.showStageButton &&
    CONFIG.zone !== 'booth' &&
    parent !== IManualLayoutParents.EXPO;

  const boothShowTime = new Date() > new Date('09/12/2020 5:30:00 PM');
  const showBoothButton = (() => {
    if (eventId === 'aed1f318-3da4-461e-a119-a282d2973d3d') {
      return boothShowTime;
    }

    return true;
  })();

  const [widgetsList, setWidgetsList] = useState(widgetDetails);
  useEffect(() => {
    if (layoutDetails.bgUrl != null) {
      setWidgetsList(widgetDetails);
    } else {
      setWidgetsList(CONFIG && CONFIG.widgets);
    }
  }, [layoutDetails, widgetDetails]);

  const showFullWidth = !useOldLobbyStageLayout;

  const autoScrollRight = () => {
    const rightScrollInterval = setInterval(() => {
      if (mainContainerCallbackRef?.current?.scrollLeft !== 0) {
        mainContainerCallbackRef?.current?.scrollTo(
          mainContainerCallbackRef?.current?.scrollLeft - 2,
          0,
        );
      }
      // eslint-disable-next-line radix
      if (parseInt(mainContainerCallbackRef?.current?.scrollLeft) === 0) {
        clearInterval(rightScrollInterval);
        setStopOverlay(false);
        setIsScrollEnd(true);
        mounted = true;
      }
    }, 15);
    rightScrollRef.current = rightScrollInterval;
  };

  const autoScrollLeft = maxScroll => {
    setStopOverlay(true);
    const leftScrollInterval = setInterval(() => {
      if (mainContainerCallbackRef?.current?.scrollLeft !== maxScroll) {
        mainContainerCallbackRef?.current?.scrollTo(
          mainContainerCallbackRef?.current?.scrollLeft + 2,
          0,
        );
      }
      if (
        Math.abs(
          Math.ceil(mainContainerCallbackRef?.current?.scrollLeft) -
            Math.ceil(maxScroll),
        ) <= 1
      ) {
        clearInterval(leftScrollInterval);
        autoScrollRight();
      }
    }, 15);
    leftScrollRef.current = leftScrollInterval;
  };

  useEffect(() => {
    if (lastParent !== parent) {
      lastParent = parent;
      mounted = false;
    }
  }, [mainContainerCallbackRef?.current?.scrollWidth, parent]);

  const onImageLoad = () => {
    setIsImageLoaded(true);
    if (
      mainContainerCallbackRef?.current?.scrollWidth &&
      !mounted &&
      isMobile
    ) {
      if (
        mainContainerCallbackRef?.current?.scrollWidth >
        mainContainerCallbackRef?.current?.clientWidth
      ) {
        var maxScroll =
          mainContainerCallbackRef?.current?.scrollWidth -
          mainContainerCallbackRef?.current?.clientWidth;
        if (
          isLobby &&
          localStorage.getItem(`is-lobby-item-displayed-${eventId}`)
        ) {
          return;
        }
        if (
          isBooth &&
          localStorage.getItem(`is-expo-item-displayed-${eventId}`)
        ) {
          return;
        }
        autoScrollLeft(maxScroll);
      }
    }
  };

  const stopScrollAnimation = () => {
    clearInterval(rightScrollRef.current);
    clearInterval(leftScrollRef.current);
    setStopOverlay(false);
  };

  useEffect(() => {
    if (!isMobile) return;
    if (!imageCallbackRef || !imageCallbackRef.current) {
      return;
    }
    imageCallbackRef.current.onload = onImageLoad;
  }, [imageCallbackRef]);

  const isImageUploaded =
    Boolean(bgImageUrl?.split('https://phoenixlive.imgix.net/')[1]) || false;

  const [imagesLoading, setImagesLoading] = useState({
    fullImage: !showFullWidth && !!backgroundImageBlurred,
    bgImageScaled: !!bgImageScaled,
  });

  const handleImageLoadStatus = (key, value) => {
    setImagesLoading(prev => ({ ...prev, [key]: value }));
  };

  const backgroundImageDetails = useMemo(() => {
    if (!isMobile) {
      return {
        src: prefixPicUrlIfNeeded(bgImageScaled),
        srcSet: getSrcSetByImgHeight(
          prefixPicUrlIfNeeded(bgImageScaled),
          height,
        ),
      };
    }

    if (isMobileVerticalView) {
      // Below settings are important for image quality on mobile view. Please don't change
      const imageUrlWithResize = `${getImageUrlWithSize(
        bgImageScaled,
        width,
        0,
        'crop',
      )}&auto=enhance`;
      return {
        src: imageUrlWithResize,
        srcSet: getSrcSetForLobbyMobileView(imageUrlWithResize),
      };
    }
    return {
      src: getImageUrlWithSize(bgImageScaled, 0, height),
      srcSet: getSrcSetByImgHeight(
        getImageUrlWithSize(bgImageScaled, 0, height),
        height,
      ),
    };
  }, [isMobile, layoutDetails, bgImageScaled, isLobby, height, width]);

  return (
    <div
      ref={mainContainerCallbackRef}
      className={classnames({
        [styles.container]: !showFullWidth,
        [styles.lobbyContainer]: showFullWidth,
        [styles.containerOverflow]: isMobile,
        [styles.containerForMobile]: isMobileVerticalView,
      })}
      id="normalScrollContainer"
      style={{
        height: isMobileVerticalView && isBooth ? `${height}px` : '100%',
        justifyContent: showNavbar ? 'center' : 'unset',
      }}
    >
      {isMobile && showStopOverlay && !isMobileVerticalView && (
          <div
            className={styles.stopScrollOverlay}
            onClick={stopScrollAnimation}
          ></div>
        )}
      {isMobile && !isMobileVerticalView && isScrollEnd && (
        <OverLay
          onClose={() => setIsScrollEnd(false)}
          eventId={eventId}
          isLobby={isLobby}
          isBooth={isBooth}
        />
      )}
      {isMobile && !isImageLoaded && isImageUploaded && (
        <Loader className={styles.loaderIcon} size={40} />
      )}
      {imagesLoading?.fullImage ||
        (imagesLoading?.bgImageScaled && <FullScreenLoader />)}
      {!showFullWidth && (
        <img
          className={classnames(styles.bgImageFull, {
            [styles.transparent]: !ready,
          })}
          src={backgroundImageBlurred + (isMobile ? `&h=${height}` : '')}
          onLoad={e => {
            handleImageLoadStatus('fullImage', false);
          }}
          onError={e => {
            handleImageLoadStatus('fullImage', false);
          }}
        />
      )}
      <div
        style={{
          width: isMobile && !isMobileVerticalView ? '100%' : `${width}px`,
          height: `${height}px`,
          maxHeight: "100%"
        }}
        className={classnames({
          [styles.transparent]: !ready,
          [styles.lobbyMainContainer]: showFullWidth && !isMobile,
          [styles.mainContainer]: !isMobile,
        })}
      >
        <img
          alt="Background"
          ref={imageCallbackRef}
          className={styles.bgImageScaled}
          src={backgroundImageDetails.src}
          srcSet={backgroundImageDetails.srcSet}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
          onLoad={e => {
            handleImageLoadStatus('bgImageScaled', false);
          }}
          onError={e => {
            handleImageLoadStatus('bgImageScaled', false);
          }}
        />
        {ready &&
          widgetsList.map(
            (
              {
                widgetId,
                x,
                y,
                width,
                height,
                widgetType,
                colorScheme,
                config,
                type,
                orderId
              },
              idx,
            ) => (
              <Widget
                moveable={false}
                key={idx}
                widgetId={widgetId}
                type={type || widgetType}
                colorScheme={colorScheme}
                initialPosition={{
                  x: Number(x) * widthScaleFactor,
                  y: Number(y) * heightScaleFactor,
                  width: Number(width) * widthScaleFactor,
                  height: Number(height) * heightScaleFactor,
                }}
                scale={draggableScaleFactor}
                config={
                  typeof config === 'string' ? JSON.parse(config) : config
                }
                handleShowBoothOwners={handleShowBoothOwners}
                showContactUsWidget={showContactUsWidget}
                sizeStyles={sizeStyles}
                orderId={orderId}
              />
            ),
          )}
        <div
          className={classnames(styles.stageButton, {
            [styles.singleStageButton]: !showBoothButton,
          })}
        >
          {showButtons && (
            <>
              {eventId === '51d5ce08-800d-40ec-b5aa-11fe976ab12c' ? (
                <Link
                  style={{ marginRight: '20px' }}
                  to={`/l/event/${eventId}/stages`}
                >
                  <IconTextButton
                    activeIcon={VIDEO}
                    defaultIcon={VIDEO}
                    activeLabel="Go to Pitch"
                    defaultLabel="Go to Pitch"
                    showDefault={true}
                    buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                  />
                </Link>
              ) : (
                <Link
                  style={{ marginRight: '20px' }}
                  to={`/l/event/${eventId}/stages`}
                >
                  <IconTextButton
                    activeIcon={VIDEO}
                    defaultIcon={VIDEO}
                    activeLabel="Go to Stage"
                    defaultLabel="Go to Stage"
                    showDefault={true}
                    buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                  />
                </Link>
              )}
              {showBoothButton && (
                <Link to={`/l/event/${eventId}/expo`}>
                  <IconTextButton
                    activeIcon={CAMERA}
                    defaultIcon={CAMERA}
                    activeLabel="Visit Expo"
                    defaultLabel="Visit Expo"
                    showDefault={true}
                    buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                  />
                </Link>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NormalManualLayout;
export { IManualLayoutParents };
