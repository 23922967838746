export interface IChatBoxProps {
  chatBoxType: IChatBoxTypes;
  messages: any;
  onMessageSubmit: any;
  attendeeChat?: Boolean;
  chatSelected?: any;
  setChatSelected?: any;
  channelConfig: any;
  showChannelAttendeesOnly: any;
  defaultSearchText?: string;
  addAttendeeMessage?: any;
  isHost?:Boolean;
  isBooth?:Boolean;
  openApproveModel?: any;
}

export enum IChatBoxTypes {
  PRIVATE = 'private',
  PUBLIC = 'public',
  ATTENDEE = 'attendee',
}
