import moment from 'moment';

export const getMessageTime = (timeString: string) => {
  const dateObj = new Date(timeString);
  const readableTime = moment(dateObj).calendar({
    sameDay: '[Today -] LT',
    lastDay: '[Yesterday -] LT',
    lastWeek: 'dddd [-] LT',
    sameElse: 'dddd DD MMMM [-] LT',
  });

  return readableTime;
};

export const INVALID_CHAT_ID = -1;
