import React, { useState, useEffect } from 'react';
// styles
import styles from './styles.module.scss';
// types
import { WidgetTypes } from '../Widget/types';
import { IMAGE_URL, STATIC_ZUDDL_URL } from '@/config';

const FileWidget = ({ type, fileSrc, sourceType, imageUrl, height, width }) => {
  const [fileURL, setFileURL] = useState<string | null>(null);
  useEffect(() => {
    if (!fileSrc) return;
    if (typeof fileSrc !== 'string') return;

    if (sourceType === 'upload' && !fileSrc.startsWith(STATIC_ZUDDL_URL)) {
      setFileURL(IMAGE_URL + fileSrc);
    } else {
      setFileURL(fileSrc);
    }
  }, [fileSrc, sourceType, imageUrl]);

  if (WidgetTypes.FILE === type && !imageUrl) {
    return <a className={styles.hotspot} target="_blank" href={fileURL}></a>;
  } else if (type === WidgetTypes.FILE && imageUrl) {
    return (
      <a className={styles.hotspot} target="_blank" href={fileURL}>
        <img
          src={`${IMAGE_URL}${imageUrl}`}
          height={height}
          width={width}
          className={styles.imageButton}
        />
      </a>
    );
  }
  return <div></div>;
};

export default FileWidget;
