import { IWidgetProps, IWidgetSettingsProps } from '../types';

export enum MediaWidgetTypes {
  GIF = 'GIF',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  IMAGE_CAROUSEL_NEW = 'IMAGE_CAROUSEL_NEW',
  IMAGE_CAROUSEL = 'IMAGE_CAROUSEL',
  REDIRECT_IMAGE_CAROUSEL = 'REDIRECT_IMAGE_CAROUSEL',
  BUTTON = 'BUTTON',
}

export interface IMediaWidgetBaseProps {
  type: MediaWidgetTypes;
  src: string;
}

export interface IMediaWidgetProps
  extends IWidgetProps,
  IMediaWidgetBaseProps { }

export interface IMediaWidgetSettingsProps
  extends IWidgetSettingsProps,
  IMediaWidgetBaseProps {
  text: string;
}
