// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__loaderContainer___3UKfW{padding:16px 72px;background:rgba(0,0,0,0.5);display:inline-block;border-radius:5px}.styles-module__loader___2GFMB{color:official;display:inline-block;position:relative;width:80px;height:80px}.styles-module__loader___2GFMB div{transform-origin:40px 40px;animation:styles-module__lds-spinner___39KC- 1.2s linear infinite}.styles-module__loader___2GFMB div:after{content:\" \";display:block;position:absolute;top:3px;left:37px;width:6px;height:18px;border-radius:20%;background:#fff}.styles-module__loader___2GFMB div:nth-child(1){transform:rotate(0deg);animation-delay:-1.1s}.styles-module__loader___2GFMB div:nth-child(2){transform:rotate(30deg);animation-delay:-1s}.styles-module__loader___2GFMB div:nth-child(3){transform:rotate(60deg);animation-delay:-0.9s}.styles-module__loader___2GFMB div:nth-child(4){transform:rotate(90deg);animation-delay:-0.8s}.styles-module__loader___2GFMB div:nth-child(5){transform:rotate(120deg);animation-delay:-0.7s}.styles-module__loader___2GFMB div:nth-child(6){transform:rotate(150deg);animation-delay:-0.6s}.styles-module__loader___2GFMB div:nth-child(7){transform:rotate(180deg);animation-delay:-0.5s}.styles-module__loader___2GFMB div:nth-child(8){transform:rotate(210deg);animation-delay:-0.4s}.styles-module__loader___2GFMB div:nth-child(9){transform:rotate(240deg);animation-delay:-0.3s}.styles-module__loader___2GFMB div:nth-child(10){transform:rotate(270deg);animation-delay:-0.2s}.styles-module__loader___2GFMB div:nth-child(11){transform:rotate(300deg);animation-delay:-0.1s}.styles-module__loader___2GFMB div:nth-child(12){transform:rotate(330deg);animation-delay:0s}@keyframes styles-module__lds-spinner___39KC-{0%{opacity:1}100%{opacity:0}}\n", ""]);
// Exports
exports.locals = {
	"loaderContainer": "styles-module__loaderContainer___3UKfW",
	"loader": "styles-module__loader___2GFMB",
	"lds-spinner": "styles-module__lds-spinner___39KC-"
};
module.exports = exports;
