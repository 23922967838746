import React from 'react';
// components
import Modal from '@/components/ui/modals/Modal';
import Button, {
  IButtonTypes,
  IButtonMargins,
} from '@/components/ui/buttons/Button';
import { ITextStyles } from '@/components/ui/content/Text';
// Styles
import styles from './styles.module.scss';
import { X } from 'react-feather';
import { getCssVar } from '@/utils/cssVars';
import { IContentColors } from '../ui/ExpandableContent';

const StreamsLimitExceedPopup = props => {
  const { handleClosePopup, errorType } = props;
  return (
    <Modal
      id="streams-limit-exceed-model"
      noHeader={true}
      disableClose={true}
      autoTrigger={true}
      noBodyPadding
      modalBodyClass={styles.modalBody}
      render={({ setShow }) => (
        <div className={styles.modalContainer}>
          <div className={styles.modalHeaderLayout}>
            <div className={styles.headerTitle}>Max speaker limit exceeded</div>
            <X
              className={styles.modalCloseIcon}
              size={16}
              color={getCssVar(IContentColors.MONOCHROME_CONTRAST)}
              onClick={() => {
                handleClosePopup(setShow);
              }}
            />
          </div>
          <div className={styles.modalText}>
            {`You have reached the maximum limit for speaker on ${errorType}, to add more please remove existing speaker from the ${errorType}.`}
          </div>
          <div className={styles.modalButtonRow}>
            <Button
              label="Ok"
              styleClass={IButtonTypes.PRIMARY}
              margin={IButtonMargins.MEDIUM_MARGIN_LEFT}
              type="button"
              onClick={() => {
                handleClosePopup(setShow);
              }}
              textStyle={ITextStyles.DEFAULT}
            />
          </div>
        </div>
      )}
    />
  );
};

export default StreamsLimitExceedPopup;
