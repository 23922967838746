// EmojiHandler.tsx
import loadable from '@/utils/loadable';
import Loader from '@/components/ui/Loader';
import React from 'react';
import styles from './styles.module.scss';
import { Reaction, ReactionsListData } from '@/api/reactions';
import { selectCurrentUser } from '@/models/account';
import { useSelector } from 'react-redux';

interface EmojiHandlerProps {
  eventId: string;
  addEmojiReactions: (payload: any) => void;
  reactionsListData?: ReactionsListData;
  setReactionsListData?: React.Dispatch<
    React.SetStateAction<ReactionsListData>
  >;
  handleClosePicker: () => void;
}

const PickerLoader = (
  <div className={styles.pickerLoader}>
    <Loader />
  </div>
);

const EmojiPicker = loadable(() => import('../../../ui/EmojiPicker'), {
  fallback: PickerLoader,
});

const EmojiHandler: React.FC<EmojiHandlerProps> = ({
  eventId,
  addEmojiReactions,
  reactionsListData,
  setReactionsListData,
  handleClosePicker,
}) => {
  const currentUser = useSelector(selectCurrentUser);

  const handleEmojiInput = emoji => {
    let currentUserReactionExists = false;
    if (reactionsListData) {
      Object.values(reactionsListData).forEach((reactions: Reaction[]) => {
        if (
          reactions.some(
            reaction =>
              reaction.aId === currentUser.accountId &&
              reaction.eH === emoji.native,
          )
        ) {
          currentUserReactionExists = true;
        }
      });
    }
    if (currentUserReactionExists) {
      handleClosePicker();
      return;
    }
    if (reactionsListData && setReactionsListData) {
      const updatedReactionsListData = {
        ...reactionsListData,
        [emoji.native]: [
          ...(reactionsListData[emoji.native] || []),
          {
            id: 'opt_reaction_id',
            eU: emoji.unified,
            eH: emoji.native,
            aId: currentUser.accountId,
            fN: 'Current User First Name',
            lN: 'Current User Last Name',
            pU: null,
          },
        ],
      };

      setReactionsListData(updatedReactionsListData);
    }
    const payload = {
      eventId: eventId,
      accountId: currentUser.accountId,
      emojiUnicode: emoji.unified,
      emojiHtml: emoji.native,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      picUrl: currentUser.picUrl,
    };
    handleClosePicker();
    addEmojiReactions(payload);
  };

  return <EmojiPicker onEmojiSelect={handleEmojiInput} />;
};

export default EmojiHandler;
