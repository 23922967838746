import React from 'react';
export const CloseIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.865 3.78406L8.64906 0L9.73 1.08094L5.94594 4.865L9.73 8.64906L8.64906 9.73L4.865 5.94594L1.08094 9.73L0 8.64906L3.78406 4.865L0 1.08094L1.08094 0L4.865 3.78406Z"
      fill="white"
      fillOpacity="0.75"
    />
  </svg>
);
