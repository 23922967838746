/*
 *
 * Form
 *
 * this component formats a bunch of Form components into a full form
 */

import React from 'react';
// components
import FormSectionRow from './FormSectionRow/index';
// styles + types
import { IFormSectionProps } from './types';
import styles from './styles.module.scss';

const FormSection = (props: IFormSectionProps) => {
  const {
    formData,
    readOnly = false,
    styleClass = '',
    onClick,
    isSponsorLandingPage = false,
    isEditProfileSection = false,
  } = props;

  return (
    <div
      className={`${styles.formSectionContainer} ${styles[styleClass]}`}
      onClick={onClick}
      onFocus={onClick}
      onBlur={onClick}
    >
      {formData.map((formFieldRow, index) => {
        const { fields } = formFieldRow;
        return (
          <FormSectionRow
            isSponsorLandingPage={isSponsorLandingPage}
            isEditProfileSection={isEditProfileSection}
            key={index}
            fields={fields}
            readOnly={readOnly}
            styleClass={styleClass}
          />
        );
      })}
    </div>
  );
};

export default FormSection;
