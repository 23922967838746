import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import {IContentBGColorTypes, IContentTextColorTypes, IContentColors, IUIIcons} from '@/types';

import styles from './styles.module.scss';
import {IExpandableContentProps} from './types';
import Text, { ITextSizes } from '../content/Text';
import { ChevronDown, ChevronRight } from 'react-feather';
import { getIcon } from '../buttons/IconButton/config';
import RefreshCircle from '../new-icons/RefreshCircle';
import ReactTooltip from 'react-tooltip';

const ExpandableContent = (props: IExpandableContentProps) => {
    const {
        icon, title, subtitle, open = false, setOpen, defaultOpen = false,
        bgColor = IContentBGColorTypes.NEUTRAL_LIGHT_3,
        titleIconColor = IContentColors.MONOCHROME_CONTRAST,
        titleColor = IContentTextColorTypes.MONOCHROME_CONTRAST,
        subtitleColor = IContentTextColorTypes.NEUTRAL_MEDIUM,
        expandIconColor = IContentColors.NEUTRAL_MID_3,
        hideDivider = false,
        classNames = {},
        children,
        toggleCompleteExpand = null,
        noToggle = false,
        refreshContent,
        strict = false,
    } = props;

    const [_isExpanded, _setIsExpanded] = useState(defaultOpen);
    const isExpanded = strict ? open : _isExpanded;
    const setIsExpanded = strict ? setOpen : _setIsExpanded;

    return (
        <div className={classnames(styles.container, styles[bgColor], {[styles.maxH]: (!isExpanded || !children)}, classNames.container)}>
            <div
                className={classnames(styles.header, {[styles.marginHeader]: (isExpanded && children)}, classNames.header)}
                onClick={()=> {
                    if (noToggle) return;
                    setIsExpanded(!isExpanded);
                    !!toggleCompleteExpand && toggleCompleteExpand();
                }}
            >
                <div className={classnames(styles.headingWrapper, classNames.headingWrap)}>
                    <div className={classnames(styles.headingWrapper, classNames.headingWrap)} onClick={()=> {
                    if (noToggle) return;
                    setIsExpanded(!isExpanded);
                    !!toggleCompleteExpand && toggleCompleteExpand();
                }}>
                        <div className={classnames(styles.iconWrapper, classNames.headingIconWrap)}>
                            {icon && getIcon(icon, 12, titleIconColor, {}, {})}
                        </div>
                        <div className={classnames(styles.titleWrapper, classNames.headingTitleWrap)}>
                            <Text text={title} textColor={titleColor} textSize={ITextSizes.XSMALL} textLineHeight='15px' clickable={true} />
                            {
                                subtitle && <Text text={subtitle} textColor={subtitleColor} textSize={ITextSizes.XXSMALL} textLineHeight='12px' clickable={true} />
                            }
                        </div>
                        <div className={classnames(styles.expandIconWrapper, classNames.headingExpandIconWrap)}>
                        {isExpanded ? <ChevronDown className={styles[`stroke-${expandIconColor}`]} size={14} /> : <ChevronRight className={styles[`stroke-${expandIconColor}`]} size={14} />}
                        </div>
                    </div>
                    {isExpanded && refreshContent &&
                    <>
                      <div data-tip data-for="tootltip_content_refresh" onClick={e => {e.stopPropagation(); refreshContent()}}> <RefreshCircle size={14} color={IContentColors.WHITE} /> </div>
                        <ReactTooltip id="tootltip_content_refresh" place="right" effect="solid">
                        Refresh
                       </ReactTooltip>
                    </>
                    }
                </div>
                {!hideDivider && <hr className={classnames(styles.divider, classNames.divider)} />}
            </div>
            {
                isExpanded && <div className={classnames(styles.expandedContent, classNames.contentWrap)}>
                    {children}
                </div>
            }
        </div>
    )
}

export {IContentBGColorTypes as IBGColors, IContentTextColorTypes as ITextColors, IContentColors, IUIIcons}
export default ExpandableContent;