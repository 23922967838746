// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".styles-module__hotspot___3dCNX{width:100%;height:100%;cursor:pointer}.styles-module__fullScreenImageBG___2ODTN{position:absolute;width:100vw;height:100vh;top:0;left:0;display:flex;flex-direction:column;justify-content:center;align-items:center;z-index:400;background-color:black;overflow:hidden}.styles-module__fullScreenImageBG___2ODTN .image-gallery{width:100vw;overflow-x:auto}.styles-module__fullScreenImageBG___2ODTN .image-gallery-thumbnails{overflow-x:auto}.styles-module__fullScreenImageContainer___2IOt7{height:80%;z-index:101;position:relative}.styles-module__fullScreenImage___3kGUD{height:100%}.styles-module__fullScreenImageBGExitIcon___3hUiu{position:absolute;cursor:pointer;top:1%;right:1%;z-index:4000;transform:translate3d(0, 0, 0)}\n", ""]);
// Exports
exports.locals = {
	"hotspot": "styles-module__hotspot___3dCNX",
	"fullScreenImageBG": "styles-module__fullScreenImageBG___2ODTN",
	"fullScreenImageContainer": "styles-module__fullScreenImageContainer___2IOt7",
	"fullScreenImage": "styles-module__fullScreenImage___3kGUD",
	"fullScreenImageBGExitIcon": "styles-module__fullScreenImageBGExitIcon___3hUiu"
};
module.exports = exports;
