import React, { useEffect, useState } from 'react';
// components
import AttendeeMessage from '@/components/chat/AttendeeMessage';
// helpers + hooks + models
import { _isEmpty } from '@/utils/jsHelpers/array';
// styles + types
import { IChatBoxTypes } from './types';
import styles from './styles.module.scss';
import { IChatBoxParent } from '../AttendeeMessage/types';
import api from '@/api';
import ChatInputField from '@/components/ui/fields/ChatInputField';
import { openReactionsListPopupAtom } from '../ChatMessageV2/atoms';
import { useAtomValue } from 'jotai';
import ReactionsListPopup from '../ReactionsListPopup';

const OneToOneChat = props => {
  let {
    onMessageSubmit,
    messages,
    chatSelected,
    setChatSelected,
    addAttendeeMessage,
    showRequestMeetingView = false,
    parent = IChatBoxParent.INBOX,
    meetingsShownMode = false,
    updateReactions,
  } = props;
  const [accountDetails, setAccountDetails] = useState({});
  const [eventChannelSettings, setEventChannelSettings] = useState({});
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showGifPicker, setShowGifPicker] = useState(false);
  const openReactionListPopup = useAtomValue(openReactionsListPopupAtom);

  useEffect(() => {
    if (!chatSelected) return;
    setAccountDetails({
      ...chatSelected,
    });
  }, [chatSelected]);

  const eventId = chatSelected?.eventId;

  const initEventChannelSettings = async (eventId: string) => {
    const { data: eventConfig } = await api.channel.getConfigByRef(eventId);
    const {
      data: channelSettings,
    } = await api.channel.getChannelSettingsByChannelId(eventConfig.channelId);
    setEventChannelSettings(channelSettings || {});
  };

  useEffect(() => {
    if (!eventId) {
      return;
    }
    initEventChannelSettings(eventId);
  }, [eventId]);

  const [requestMeeting, setRequestMeeting] = useState(showRequestMeetingView);

  const { hasGif, hasChatEmoji } = eventChannelSettings || {};

  const [meetingSetupOpen, setMeetingSetupOpen] = useState(false);

  return (
    <div className={styles.chatBoxContainer}>
      <AttendeeMessage
        attendeeDetails={accountDetails}
        setChatSelected={setChatSelected}
        requestMeeting={requestMeeting}
        setRequestMeeting={setRequestMeeting}
        messages={messages}
        addAttendeeMessage={addAttendeeMessage}
        parent={parent}
        showEmojiPicker={showEmojiPicker}
        showGifPicker={showGifPicker}
        meetingsShownMode={meetingsShownMode}
        setMeetingSetupOpen={setMeetingSetupOpen}
        meetingSetupOpen={meetingSetupOpen}
        updateReactions={updateReactions}
      />
      {!requestMeeting && (
        <ChatInputField
          onMessageSubmit={onMessageSubmit}
          placeholder={`Message ${chatSelected.firstName}`}
          showProfanityWarning={false}
          hasGifPicker={hasGif}
          hasEmojiPicker={hasChatEmoji}
          showEmojiPicker={showEmojiPicker}
          setShowEmojiPicker={setShowEmojiPicker}
          showGifPicker={showGifPicker}
          setShowGifPicker={setShowGifPicker}
          parent={parent}
        />
      )}
      {openReactionListPopup && <ReactionsListPopup />}
    </div>
  );
};

export { IChatBoxTypes };

export default OneToOneChat;
