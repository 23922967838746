import React from 'react';

export const LeftArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_5)">
        <path
          d="M12 0C5.37258 0 0 5.15768 0 11.52C0 17.8823 5.37258 23.04 12 23.04C18.6274 23.04 24 17.8823 24 11.52C24 5.15768 18.6274 0 12 0Z"
          fill="#843DF5"
        />
        <path
          d="M14 6.72031L9 11.5203L14 16.3203L15 15.3603L11 11.5203L15 7.68031L14 6.72031Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_5">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RightArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_2)">
        <path
          d="M12 24C18.6274 24 24 18.8423 24 12.48C24 6.11769 18.6274 0.960007 12 0.960007C5.37258 0.960007 0 6.11769 0 12.48C0 18.8423 5.37258 24 12 24Z"
          fill="#843DF5"
        />
        <path
          d="M10 17.2797L15 12.4797L10 7.67969L9 8.63969L13 12.4797L9 16.3197L10 17.2797Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
