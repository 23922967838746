import React, { useMemo, useState } from 'react';
import classnames from 'classnames';
import Text, { ITextColors, ITextSizes } from '@/components/ui/content/Text';
import { MEDIA_TYPE } from '@/components/ui/fields/ChatInputField/config';
import GifPlayer from '@/components/ui/GifPlayer';

import styles from './styles.module.scss';
import { getMessageTime } from '../ChatMessageV2/config';
import { Smile } from '@styled-icons/boxicons-solid/Smile';
import { useAtom, useSetAtom } from 'jotai';
import {
  isOpenPickerAtom,
  messageIdAtom,
  openReactionsListPopupAtom,
} from '../ChatMessageV2/atoms';
import { CrossIcon } from '@/layouts/OrganizerChecklist/icons';
import EmojiHandler from '../ChatMessageV2/EmojiHandler';
import { useParams } from 'react-router-dom';
import { Reaction, useReactionMutation } from '@/api/reactions';
import { IPopupUser } from '../ChatMessageV2/types';
import isEmpty from 'lodash/isEmpty';
import { useUnmount } from 'react-use';
import { selectCurrentUser } from '@/models/account';
import { useSelector } from 'react-redux';

const Conversation = ({
  message,
  showMessageDay,
  allowMessageReactions,
  reactionsListData,
  currentUserAccountId,
}) => {
  const { mediaContent, createdAt, accountId, mediaType, messageId } = message;
  const currentUser = useSelector(selectCurrentUser);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isOpenPicker, setIsOpenPicker] = useAtom(isOpenPickerAtom);
  const [popupUsers, setPopupUsers] = useState<IPopupUser[]>([]);
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [openReactionListPopup, setOpenReactionListPopup] = useAtom(
    openReactionsListPopupAtom,
  );
  const setMessageId = useSetAtom(messageIdAtom);
  const { eventId = '' } = useParams<{
    eventId: string;
  }>();

  const { mutate: addEmojiReactions } = useReactionMutation.addEmojiReactions(
    messageId,
  );

  const {
    mutate: removeEmojiReaction,
  } = useReactionMutation.removeEmojiReaction(messageId);

  const isMyMessage = accountId === currentUserAccountId;
  const isMedia = mediaType === MEDIA_TYPE.gif;

  const Message = isMedia ? (
    <GifPlayer gifId={mediaContent} />
  ) : (
    <Text
      text={mediaContent}
      textColor={ITextColors.MONOCHROME_CONTRAST}
      textSize={ITextSizes.SMALL}
      textLineHeight={1.5}
      linkify
      linkColorSameAsText
      underlineLinks
    />
  );

  const formatMessageTime = createdAt => {
    const temp = getMessageTime(createdAt).split(' - ');
    return temp[temp.length - 1];
  };

  const MessageTime = (
    <Text
      text={formatMessageTime(createdAt)}
      textColor={
        isMyMessage ? ITextColors.NEUTRAL_MEDIUM : ITextColors.NEUTRAL_MEDIUM_2
      }
      textSize={ITextSizes.XXSMALL}
    />
  );

  const getOrdinalDisplay = number => {
    const parsedDay = parseInt(number);
    const b = parsedDay % 10;
    const output =
      ~~((parsedDay % 100) / 10) === 1
        ? 'th'
        : b === 1
        ? 'st'
        : b === 2
        ? 'nd'
        : b === 3
        ? 'rd'
        : 'th';
    return (
      <>
        {parsedDay}
        <sup>{output}</sup>
      </>
    );
  };

  const formatDayTime = (() => {
    if (!showMessageDay) return null;
    const splitWords = showMessageDay.split(' ');
    if (splitWords.length > 1) {
      return (
        <div className={styles.messageDayBig}>
          <div className={styles.messageDayText}>
            {splitWords[0]}, {getOrdinalDisplay(splitWords[1])} {splitWords[2]}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.messageDay}>
        <div className={styles.messageDayText}>{showMessageDay}</div>
      </div>
    );
  })();

  let reactionTimer: ReturnType<typeof setTimeout>;
  const handleTouchStart = () => {
    setMessageId(messageId);
    reactionTimer = setTimeout(() => {
      setShowEmojiPicker(true);
    }, 500);
  };

  const handleTouchEnd = () => {
    clearTimeout(reactionTimer);
  };

  const togglePicker = (media: string) => {
    setMessageId(messageId);
    setIsOpenPicker(media);
  };

  const handleClosePicker = () => {
    setIsOpenPicker('');
  };

  const populateReactionCountsForMessageId = (reactionsData: {
    [key: string]: Reaction[];
  }) => {
    if (!reactionsData) {
      return null;
    }

    const reactionsArray = Object.entries(reactionsData);

    reactionsArray.sort(
      ([, reactionsA], [, reactionsB]) => reactionsB.length - reactionsA.length,
    );

    // Filter out reactions with count 0
    const filteredReactionsArray = reactionsArray.filter(
      ([, reactions]) => reactions.length > 0,
    );

    const sortedReactionCounts = filteredReactionsArray.reduce(
      (acc, [emoji, reactions]) => {
        acc[emoji] = reactions.length;
        return acc;
      },
      {},
    );
    return sortedReactionCounts;
  };

  const reactionCounts = useMemo(
    () => populateReactionCountsForMessageId(reactionsListData),
    [reactionsListData],
  );
  const handleEmojiHover = (emoji: string) => {
    const users = reactionsListData[emoji].map(reaction => ({
      firstName: reaction.fN,
      accountId: reaction.aId,
    }));

    setPopupUsers(users);
    setSelectedEmoji(emoji);
  };

  const handleEmojiLeave = () => {
    setPopupUsers([]);
    setSelectedEmoji(null);
  };

  const handleEmojiTab = emoji => {
    const currentUserReaction = reactionsListData[emoji].find(
      reaction => reaction.aId === currentUserAccountId,
    );
    const emojiData = reactionsListData[emoji].find(
      reaction => reaction.eH === emoji,
    );
    setMessageId(messageId);
    if (currentUserReaction) {
      removeEmojiReaction(currentUserReaction.id);
    } else {
      const payload = {
        eventId: eventId,
        accountId: currentUser.accountId,
        emojiUnicode: emojiData.eU,
        emojiHtml: emojiData.eH,
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        picUrl: currentUser.picUrl,
      };
      addEmojiReactions(payload);
    }
  };

  const handleReactionListPopup = () => {
    setOpenReactionListPopup(!openReactionListPopup);
    setMessageId(messageId);
  };

  useUnmount(() => {
    setIsOpenPicker('');
    setOpenReactionListPopup(false);
  });

  return (
    <>
      {showMessageDay && (
        <div className={styles.messageDayContainer}>{formatDayTime}</div>
      )}
      <div
        className={classnames(styles.container, {
          [styles.floatLeft]: !isMyMessage,
          [styles.floatRight]: isMyMessage,
          [styles.contentClickable]: allowMessageReactions,
        })}
        onMouseEnter={() => setShowEmojiPicker(true)}
        onMouseLeave={() => setShowEmojiPicker(false)}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
      >
        <div
          aria-hidden
          className={classnames(styles.contentWrapper, {
            [styles.ColorLeft]: !isMyMessage,
            [styles.ColorRight]: isMyMessage,
            [styles.textChatWidth]: !isMedia,
            [styles.mediaChatWidth]: isMedia,
          })}
          onClick={allowMessageReactions ? handleReactionListPopup : undefined}
        >
          <div className={styles.content}>{Message}</div>
          <div className={styles.time}>{MessageTime}</div>
        </div>
        {allowMessageReactions && showEmojiPicker && (
          <div className={styles.reactionEmoji}>
            <Smile onClick={() => togglePicker(mediaContent)} />
          </div>
        )}
      </div>
      {allowMessageReactions && !!reactionCounts && !isEmpty(reactionCounts) && (
        <div
          className={classnames(styles.emojiSelected, {
            [styles.currentUserEmoji]: isMyMessage,
          })}
        >
          <>
            {Object.keys(reactionCounts).map(emoji => (
              <div
                aria-hidden
                className={classnames(styles.countTab, {
                  [styles.currentUserTab]: reactionsListData[emoji].some(
                    reaction => reaction.aId === currentUserAccountId,
                  ),
                })}
                onMouseEnter={() => handleEmojiHover(emoji)}
                onMouseLeave={handleEmojiLeave}
                onClick={() => handleEmojiTab(emoji)}
              >
                {emoji}
                <span>
                  {reactionCounts[emoji]} {'   '}
                </span>
              </div>
            ))}
            {popupUsers.length > 0 && (
              <div className={styles.hoverPop}>
                <div className={styles.emojiIcon}>{selectedEmoji}</div>
                <p>
                  {popupUsers.length > 7 ? (
                    <>
                      {popupUsers
                        .slice(0, 7)
                        .map(user =>
                          user.accountId === currentUserAccountId
                            ? 'you'
                            : user.firstName,
                        )
                        .join(', ')}{' '}
                      &{' '}
                      <a href="#" onClick={handleReactionListPopup}>
                        {popupUsers.length - 7} others
                      </a>
                    </>
                  ) : (
                    popupUsers
                      .map(user =>
                        user.accountId === currentUserAccountId
                          ? 'you'
                          : user.firstName,
                      )
                      .join(', ')
                  )}
                </p>
              </div>
            )}
          </>
        </div>
      )}
      {isOpenPicker === mediaContent && (
        <div className={styles.emojiPicker}>
          <div className={styles.popupContainer}>
            <div className={styles.popupHeader}>
              <span className={styles.popupTitle}>Reactions</span>
              <div onClick={handleClosePicker}>
                <CrossIcon />
              </div>
            </div>
          </div>
          <EmojiHandler
            eventId={eventId}
            handleClosePicker={handleClosePicker}
            addEmojiReactions={addEmojiReactions}
          />
        </div>
      )}
    </>
  );
};

export default Conversation;
