import { IChatBoxParent, IChatBoxTypes } from '@/components/chat/ChatBox/types';

export interface IChatMessageProps {
  message: any;
  chatBoxType: IChatBoxTypes;
  showMessageDay: any;
  showMenu: boolean;
  pinDisabled: boolean;
  isEmbedded?: boolean;
  isRoom?: boolean;
  parent?: IChatBoxParent;
  updateReactions?: (reaction: any, messageId: string) => void;
  allowMessageReactions?: boolean;
  containerClassName?: string;
  textClassName?: string;
}

export interface IPopupUser {
  firstName: string;
  accountId: string;
};


export enum IChatMessageSender {
  ATTENDEE = 'attendee',
  ORGANIZER = 'organizer',
  SPEAKER = 'speaker',
  MODERATOR = 'moderator',
  THEM = 'their',
  YOU = 'your',
}

export const PIN_MESSAGE_LEN_LIMIT = 4000;
