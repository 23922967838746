import React, { useEffect, useState } from 'react';
// components
import Image from '@/components/ui/media/Image';
import { Link } from 'react-router-dom';
import ImageCarousel from '@/components/ui/media/ImageCarousel';
import { useParams } from 'react-router';
import Video from '@/components/ui/media/Video';
import { Carousel } from 'react-responsive-carousel';
import { getInternalRedirectionPath, getExternalRedirectionPath } from '../WidgetsRedirection';
// utils
import { getZoneSetUpObj } from '@/utils/helpers';
// api + models
import api from '@/api';
// styles + types + settings
import styles from './styles.module.scss';
import MediaWidgetSettings from './settings';
import { MediaWidgetTypes } from './types';
import { ILinkType } from '@/components/forms/WidgetForms/ButtonForm/types';
// configs
import { fileWithURL } from '@/utils/helpers';

const MediaWidget = ({
  type,
  src,
  muted,
  loop,
  controls,
  fullScreenOnClick,
  fullScreenUrl,
  imagesList = [],
  zone,
  subZone,
  redirectURL,
  buttonLinkType,
  height,
  width,
  autoplay,
}) => {
  const shouldLoop = loop === undefined || loop === null ? true : loop;
  const { eventId = '' } = useParams() as any;
  const [zoneSetup, setZoneSetup] = useState({});
  useEffect(() => {
    api.event.getZoneSetUp(eventId).then(({ data }) => {
      setZoneSetup(getZoneSetUpObj(data));
    });
  }, [eventId]);

  const carouselContainer = {
    width: `${width}px`,
    height: `${height}px`,
  };

  const imageWidget = <Image
    styleClass="fullParentContain"
    deletable={false}
    readOnly={true}
    src={src}
    fullScreenOnClick={fullScreenOnClick}
    fullScreenUrl={fullScreenUrl}
  />

  const imageCarouselWidget = ((idx, widgetSrc) => {
    return (
      <div key={`${idx}ImageCarouselNew`}>
        <img
          className={styles.fullParentContain}
          style={carouselContainer}
          width="100%"
          height="100%"
          alt="image"
          src={widgetSrc || ''}
        />
      </div>
    )
  });

  return (
    <>
      {type === MediaWidgetTypes.IMAGE && (
        <>
          {!buttonLinkType && imageWidget}
          {buttonLinkType === ILinkType.EXTERNAL &&
            <a target="_blank" href={getExternalRedirectionPath(redirectURL)}>
              {imageWidget}
            </a>
          }
          {buttonLinkType === ILinkType.INTERNAL &&
            <Link to={getInternalRedirectionPath(zoneSetup, zone, subZone, eventId)}>
              {imageWidget}
            </Link>
          }
        </>
      )}
      {type === MediaWidgetTypes.IMAGE_CAROUSEL_NEW && (
        <div
          style={carouselContainer}
          className={styles.imageCarouselWidgetContainer}
        >
          <Carousel autoPlay showThumbs={false} showStatus={false} infiniteLoop>
            {imagesList.map((item, index) => {
              const { zone, subZone, buttonLinkType, redirectURL, src: imgCarouselNewSrc, url: externalImageUrl } = item;
              const imageUrl = imgCarouselNewSrc?.length > 0 ? imgCarouselNewSrc : externalImageUrl;
              return (
                <>
                  {!buttonLinkType && imageCarouselWidget(index, imageUrl)}
                  {buttonLinkType === ILinkType.EXTERNAL &&
                    <a target="_blank" href={getExternalRedirectionPath(redirectURL)}>
                      {imageCarouselWidget(index, imageUrl)}
                    </a>
                  }
                  {buttonLinkType === ILinkType.INTERNAL &&
                    <Link to={getInternalRedirectionPath(zoneSetup, zone, subZone, eventId)}>
                      {imageCarouselWidget(index, imageUrl)}
                    </Link>
                  }
                </>
              );
            })}
          </Carousel>
        </div>
      )}
      {type === MediaWidgetTypes.GIF && (
        <Image deletable={false} readOnly={true} src={src} />
      )}
      {type === MediaWidgetTypes.VIDEO && (
        <Video
          loop={shouldLoop}
          controls={controls}
          url={fileWithURL(src)}
          muted={muted}
          autoplay={autoplay}
        />
      )}
      {type === MediaWidgetTypes.IMAGE_CAROUSEL && (
        <ImageCarousel
          styleClass="fullParentContain"
          deletable={false}
          readOnly={true}
          src={src}
        />
      )}
    </>
  );
};

MediaWidget.Settings = MediaWidgetSettings;
export default MediaWidget;
