import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// components
import { getInternalRedirectionPath, getExternalRedirectionPath } from '../WidgetsRedirection';
// types
import { ILinkType } from '@/components/forms/WidgetForms/ButtonForm/types';
import { IHotspotWidgetProps } from './types';
// utils
import { getZoneSetUpObj } from '@/utils/helpers';
// api + models
import api from '@/api';
// styles
import styles from './styles.module.scss';
import "react-image-gallery/styles/css/image-gallery.css";

const HotspotWidget = ({
  redirectURL,
  zone,
  zoneRefId,
  buttonLinkType,
  withinZone,
}: IHotspotWidgetProps) => {

  const { eventId } = useParams() as any;
  const [zoneSetup, setZoneSetup] = useState({});
  // Removed this temporarily for event: e08619f6-af53-4031-a357-570550e1f011
  // useEffect(() => {
  //   api.event.getZoneSetUp(eventId).then(({ data }) => {
  //     setZoneSetup(getZoneSetUpObj(data));
  //   });
  // }, [eventId]);

  return (
    <>
      {redirectURL && buttonLinkType === ILinkType.EXTERNAL && (
        <a className={styles.hotspot} target="_blank" href={getExternalRedirectionPath(redirectURL)}></a>
      )}
      {buttonLinkType === ILinkType.INTERNAL && (
        <Link className={styles.hotspot} to={getInternalRedirectionPath(zoneSetup, zone, withinZone, eventId)}></Link>
      )}
    </>
  );
};

export default HotspotWidget;
