export const getInternalRedirectionPath = (zoneSetup, zone, withinZone, eventId) => {

  const withinZoneRedirect = withinZone && withinZone.trim();

  if (zone && !withinZoneRedirect) {
    return `/l/event/${eventId}/${zone}`;
  } else {
    let entityName = zone;
    // Removed this temporarily for event: e08619f6-af53-4031-a357-570550e1f011
    // if (zoneSetup && !zoneSetup[zone]) {
    //   return;
    // }
    if (zone === 'expo') {
      entityName = 'booth';
    }
    return `/l/event/${eventId}/${entityName}/${withinZoneRedirect}`;
  }
}

export const getExternalRedirectionPath = (externalLink) => {
  if(!externalLink) return externalLink;
  return externalLink.toLowerCase().trim().startsWith('http') ? externalLink : "http://"+externalLink;
}