/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import shortid from 'shortid';
// components
import Option from './Option';
// styles + types
import styles from './styles.module.scss';
import { keyboardAccessible } from '@/utils/keyboard accessible';

const Dropdown = props => {
  const {
    options,
    dropdownRef,
    onClick,
    hasMsgCharLimitReachedForPinning,
  } = props;
  const hasSingleOption = options.length === 1;

  return (
    <div
      ref={dropdownRef}
      className={`${styles.dropdownContainer} ${
        styles[`dropdownContainerHeight__${options.length}`]
      }`}
      onClick={onClick}
      tabIndex={0}
      role="menu"
      aria-label="dropdown"
      onKeyDown={(e: any) => keyboardAccessible(e, onClick)}
    >
      {options.map((option, index) => (
        <Option
          key={shortid.generate()}
          option={option}
          index={index}
          isDisabled={option.isDisabled}
          hasMsgCharLimitReachedForPinning={hasMsgCharLimitReachedForPinning}
        />
      ))}
    </div>
  );
};

export default Dropdown;
