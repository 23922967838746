import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
// styles + types
import styles from './styles.module.scss';
import { IEmojiTypes } from './types';
import { useParams } from 'react-router';

export const EmojiSymbols = {
  [IEmojiTypes.LIKE]: '👏',
  [IEmojiTypes.LOVE]: '💡',
  [IEmojiTypes.LAUGH]: '⭐',
  [IEmojiTypes.WOW]: '🎤',
  [IEmojiTypes.CONFUSED]: '👍',
};

export const CustomEmojiSymbos = {
  [IEmojiTypes.LIKE]: '🏆',
  [IEmojiTypes.LOVE]: '🎖',
  [IEmojiTypes.LAUGH]: '😃',
  [IEmojiTypes.WOW]: '👏🏻',
  [IEmojiTypes.CONFUSED]: '🌟',
};

const Emoji = ({ type: emojiType, className, onClick, emojiId }) => {
  const emojiElRef = useRef();
  const { eventId } = useParams();

  const EMOJI_MAP = eventId === 'd6e0235d-470a-40ba-887c-3af5bd5bdd2e' ? CustomEmojiSymbos : EmojiSymbols;
  useEffect(() => {
    if (!emojiElRef) {
      return;
    }

    const emojiClick = fromEvent(emojiElRef.current, 'click')
      .pipe(throttleTime(5000))
      .subscribe(() => {
        onClick(emojiId);
      });

    return () => emojiClick.unsubscribe();
  }, [emojiElRef, emojiId, onClick]);

  return (
    <span
      ref={emojiElRef}
      className={classnames(className, styles.emoji)}
      tabIndex={0}
      aria-label={`${emojiType}-emoji`}
    >
      {EMOJI_MAP[emojiType] || emojiType}
    </span >
  );
};
export default Emoji;
export { IEmojiTypes };
