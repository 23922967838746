import React, { memo, useEffect, useState, useRef } from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import styles from './styles.module.scss';
import { GIPHY_WEB_SDK_KEY } from '@/config';
import { IGif } from './types';

const gf = new GiphyFetch(GIPHY_WEB_SDK_KEY);

const GifPlayer = memo(({ gifId }: { gifId: string }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [gif, setGif] = useState<IGif>();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    gf.gif(gifId).then(({ data }) => {
      setGif({
        src: data.images.fixed_height_small.mp4,
        poster: data.images.fixed_height_small_still.url,
      });
    });
  }, [gifId]);

  if (!gif) {
    return <div className={styles.emptyLoaderBox} />;
  }

  const handleVideoPlay = () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    video.play();
    setShowButton(false);
  };

  const handleVideoEnd = () => {
    const video = videoRef.current;
    if (!video) {
      return;
    }
    video.currentTime = 0;
    video.pause();
    setShowButton(true);
  };

  // https://developers.giphy.com/docs/optional-settings/#renditions-glossary
  // Height set to 100px, variable width, to fit in preview grids and other smaller applications.
  return (
    <div className={styles.gifContainer} aria-hidden>
      <video
        src={gif.src}
        ref={videoRef}
        playsInline
        autoPlay
        muted
        preload="metadata"
        className={styles.gif}
        onEnded={handleVideoEnd}
        poster={gif.poster}
      />
      {showButton && (
        <div
          className={styles.playerButton}
          onClick={handleVideoPlay}
          aria-hidden
        >
          GIF
        </div>
      )}
    </div>
  );
});
GifPlayer.displayName = 'GifPlayer';

export default GifPlayer;
