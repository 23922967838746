import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import classnames from 'classnames';
// components
import Loader from '@/components/ui/Loader';
import IconTextButton, { IIconTextButtonMarginTypes } from '@/components/ui/buttons/IconTextButton';

import { IUIIcons } from '@/types';
const {
  CAMERA,
  DOWNLOAD
} = IUIIcons;

// styles + types
import styles from './styles.module.scss';
import { IButtonTypes } from '@/components/ui/buttons/Button';
import useDimensions from '@/hooks/use-dimensions';
import { PhotoboothLayerTypes, PhotoboothPreviewFrameTypes } from './types';

const PhotoBooth = (props) => {
  const { width, height, layers } = props;
  const [imgDims, setImgDims] = useState({});
  const frameSrc = layers.length > 0 && layers[0] && layers[0].src;

  const previewLayerArr = layers.filter(layer => [PhotoboothLayerTypes.PREVIEW, PhotoboothLayerTypes.DEEP_AR].includes(layer.type));
  const { baseWidth, baseHeight, offsetTop, offsetLeft, frame = PhotoboothPreviewFrameTypes.RECTANGLE } = previewLayerArr && previewLayerArr.length > 0 && previewLayerArr[0];

  const imageRef = useRef();
  useEffect(() => {
    if (!imageRef || !imageRef.current) {
      return;
    }

    imageRef.current.onload = () => {
      if (!imageRef || !imageRef.current) {
        return;
      }

      const node = imageRef.current;
      setImgDims({
        height: node.naturalHeight,
        width: node.naturalWidth
      });
    };
  }, [imageRef]);

  const [parentContainerCallbackRef, parentDims] = useDimensions([width, height]);

  const canvasDims = useMemo(() => {
    if (!imgDims.height && !parentDims.height) {
      return;
    }

    const parentRatio = parentDims.width / parentDims.height;
    const imageRatio = imgDims.width / imgDims.height;

    if (parentRatio > imageRatio) {
      // container is wider
      const height = parentDims.height;
      const width = height * imageRatio;
      const scaleFactor = height / imgDims.height;
      return {
        height,
        width,
        scaleFactor
      };
    } else if (imageRatio > parentRatio) {
      // image is wider
      const width = parentDims.width;
      const height = width * imgDims.height / imgDims.width;
      const scaleFactor = width / imgDims.width;

      return {
        height,
        width,
        scaleFactor
      };
    } else {
      return {
        ...imgDims,
        scaleFactor: 1
      };
    }
  }, [imgDims, parentDims]);

  const [dataUri, setDataUri] = useState();
  const handleTakePhotoAnimationDone = (dataUri) => {
    setDataUri(dataUri);
  };

  const [framedImage, setFramedImage] = useState();
  const canvasRef = useRef();
  const photoRef = useRef();
  useEffect(() => {
    if (!canvasRef || !imgDims || !canvasDims || !photoRef || !dataUri) {
      return;
    }

    const canvasNode = canvasRef.current;
    if (!canvasNode) {
      return;
    }

    if (!photoRef.current) {
      return;
    }

    const { width: canvasWidth, height: canvasHeight, scaleFactor } = canvasDims;
    const ctx = canvasNode.getContext('2d');

    const imageNode = document.getElementById(`layer-${0}`);
    if (!imageNode && framedImage) {
      ctx.restore();
      const img = new Image();
      img.onload = function () {
        ctx.drawImage(img, 0, 0);
      };
      img.src = framedImage;
      return;
    }

    layers.forEach(({ type }, i) => {
      if (type === PhotoboothLayerTypes.IMAGE) {
        const imageNode = document.getElementById(`layer-${i}`);
        ctx.drawImage(imageNode, 0, 0, imgDims.width, imgDims.height, 0, 0, canvasDims.width, canvasDims.height);
      } else if (type === PhotoboothLayerTypes.PREVIEW) {
        if (frame === PhotoboothPreviewFrameTypes.CIRCLE) {
          ctx.beginPath();
          ctx.arc(canvasWidth / 2, canvasHeight / 2, canvasWidth / 2, 0, Math.PI * 2, true);
          ctx.clip();

          // TODO: position the circle clearly based on the offset
          ctx.drawImage(photoRef.current, 0, 0);
        } else {
          ctx.drawImage(photoRef.current, offsetLeft * scaleFactor, offsetTop * scaleFactor);
        }
      }
    });

    setFramedImage(canvasNode.toDataURL('image/png'));
  }, [canvasRef, imgDims, canvasDims, photoRef, dataUri, framedImage]);

  const photoBaseDims = {
    width: baseWidth,
    height: baseHeight
  };
  const canvasPhotoDims = useMemo(() => {
    if (!canvasDims) {
      return null;
    }
    const { scaleFactor } = canvasDims;
    return {
      width: Math.round(photoBaseDims.width * scaleFactor),
      height: Math.round(photoBaseDims.height * scaleFactor),
    };
  }, [canvasDims]);

  return (
    <div className={styles.photooboothContainer}>
      <img ref={imageRef} crossorigin="anonymous" src={frameSrc} className={styles.hidden} />
      <div ref={parentContainerCallbackRef} className={styles.containerStyle} >
        <div className={styles.canvasContainer}>
          {
            canvasDims ? (
              <div style={{ position: 'relative', width: canvasDims.width, height: canvasDims.height }}>
                <canvas className={classnames({
                  [styles.hidden]: !framedImage
                })} ref={canvasRef} width={canvasDims.width} height={canvasDims.height} />
                {
                  layers.map(({ type, src, circleStyles }, i) => {
                    if (type === PhotoboothLayerTypes.IMAGE && !framedImage) {
                      return <img crossorigin="anonymous" id={`layer-${i}`} style={{ position: 'absolute', top: 0, left: 0, width: canvasDims.width, height: canvasDims.height }} src={src} />
                    } else if (type === PhotoboothLayerTypes.PREVIEW) {
                      return dataUri ?
                        <div
                          style={{
                            position: 'absolute',
                            top: `${offsetTop * canvasDims.scaleFactor}px`,
                            left: `${offsetLeft * canvasDims.scaleFactor}px`,
                            width: canvasPhotoDims.width,
                            height: canvasPhotoDims.height
                          }}
                          className={
                            classnames(
                              styles.cameraContainer,
                              styles.downloadContainer,
                              {
                                [styles.circleFrameContainer]: frame === PhotoboothPreviewFrameTypes.CIRCLE
                              }
                            )}>
                          <img ref={photoRef} src={dataUri} className={styles.hidden} />
                        </div> :
                        <div
                          style={{
                            position: 'absolute',
                            top: `${offsetTop * canvasDims.scaleFactor}px`,
                            left: `${offsetLeft * canvasDims.scaleFactor}px`,
                            width: canvasPhotoDims.width,
                            height: canvasPhotoDims.height
                          }}
                          className={
                            classnames(
                              styles.cameraContainer,
                              {
                                [styles.offsetCircles]: circleStyles,
                                [styles.circleFrameContainer]: frame === PhotoboothPreviewFrameTypes.CIRCLE
                              }
                            )}>
                          <Camera
                            idealFacingMode={FACING_MODES.USER}
                            idealResolution={canvasPhotoDims}
                            onTakePhotoAnimationDone={handleTakePhotoAnimationDone} />
                        </div>
                    }
                  })
                }
                {dataUri && (
                  <div className={styles.actionBar}>
                    <IconTextButton
                      activeIcon={CAMERA}
                      activeLabel="Retake"
                      defaultLabel="Retake"
                      defaultIcon={CAMERA}
                      showDefault={true}
                      onClick={() => {
                        setDataUri(null);
                        setFramedImage(null);

                        const canvasNode = canvasRef.current;
                        if (!canvasNode) {
                          return;
                        }
                        const ctx = canvasNode.getContext('2d');
                        ctx.clearRect(0, 0, canvasDims.width, canvasDims.height);
                      }}
                      buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
                      buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
                    />
                    <a download="photobooth.png" href={framedImage}>
                      <IconTextButton
                        activeIcon={DOWNLOAD}
                        activeLabel="Download"
                        defaultLabel="Download"
                        defaultIcon={DOWNLOAD}
                        showDefault={true}
                        buttonType={IButtonTypes.PRIMARY_SQUARE_FULL}
                        buttonMargin={IIconTextButtonMarginTypes.MEDIUM_MARGIN_BOTTOM}
                      />
                    </a>
                  </div>
                )}
              </div>
            ) : <Loader />
          }
        </div>
      </div>
    </div >
  );
};

export default PhotoBooth;
