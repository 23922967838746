import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { _isNull } from '@/utils/jsHelpers/obj';
import classnames from 'classnames';
import styles from './styles.module.scss';
// components
import Widget from '@/components/custom/widgets/Widget';
import IconTextButton, {
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { EyeIcon, LeftIcon, RightIcon, DownIcon, BackIcon } from "../icons";
import { makeSelectEventById } from '@/models/event';
// configs + types
import customConfig from '@/custom-config';
import { IUIIcons } from '@/types';
import { IManualLayoutParents } from '../types';
import { ILiveEventColorThemes } from '@/types';
import { WidgetTypes } from '@/components/custom/widgets/Widget/types';
//hooks
import useDimensions from '@/hooks/use-dimensions';
import useImageDimensions from '@/hooks/use-image-dimensions';
import { useMemoizedSelector } from '@/hooks/use-memoized-selector';
// styles
import { TimelineLite, Power2 } from 'gsap';
import { v4 as uuidv4 } from 'uuid';
import FullScreenLoader from '@/components/ui/FullScreenLoader';

const { VIDEO, FILES, CAMERA, KEYNOTE } = IUIIcons;
const DISPLAY_STATES = {
  COVER: "COVER",
  ZOOMED: "ZOOMED"
}
const ZOOMABLE_WIDGET_TYPES = [WidgetTypes.MEDIA];

const ImmersiveManualLayout = ({
  isChatOpen,
  config: CONFIG,
  parent = IManualLayoutParents.OTHER,
  widgetDetails,
  layoutDetails,
  zoomedIn,
  setZoomedIn,
  handleShowBoothOwners,
  showContactUsWidget,
}) => {
  const bgImageUrl = layoutDetails && layoutDetails.bgUrl ? layoutDetails.bgUrl : CONFIG.backgroundUrl;
  const boothBGImageURL = layoutDetails.boothBackgroundUrl;
  const bgImageScaled = bgImageUrl && `${bgImageUrl}`;

  const { eventId = '', boothId } = useParams();
  const eventConfig = customConfig[eventId] || customConfig.default;

  const [mainContainerCallbackRef, mainContainerDims] = useDimensions([isChatOpen]);
  const [imageCallbackRef, imageDims] = useImageDimensions([isChatOpen]);
  const event = useMemoizedSelector(makeSelectEventById, eventId);

  const [sizeStyles, setSizeStyles] = useState({
    width: '100%',
    height: '100%',
    widthScaleFactor: 1,
    heightScaleFactor: 1,
  });

  const tl = new TimelineLite();
  const colorTheme = (() => {
    if (event && event.colorTheme) {
      return ILiveEventColorThemes[event.colorTheme];
    }
    return ILiveEventColorThemes.LIGHT;
  })();
  const [iconColor, setIconColor] = useState(null);
  useEffect(() => {
    console.log(colorTheme, event.colorTheme);
    const color = colorTheme === ILiveEventColorThemes.LIGHT ? "#17345F" : "#FFFFFF";
    setIconColor(color);
  }, [colorTheme])

  let imageRef = useRef(null);
  const [currentWidget, setCurrentWidget] = useState(null);
  const [displayState, setDisplayState] = useState(DISPLAY_STATES.COVER);

  const [firstDims, setFirstDims] = useState();
  useEffect(() => {
    const { width, height } = sizeStyles;
    if (!firstDims) {
      setFirstDims({
        width,
        height,
      });
    }
  }, [sizeStyles]);

  const [innerContainerDims, setInnerContainerDims] = useState();
  useEffect(() => {
    if (!mainContainerDims) {
      return;
    }

    const {
      width: containerWidth,
      height: containerHeight,
    } = mainContainerDims;

    const leftClearance = 270;        // Accounting for cards on the left
    const topClearance = 10 + 80;     // Accounting for peopleicons on the top
    const bottomClearance = 25 + 100; // Accounting for controls on the bottom

    setInnerContainerDims({
      width: containerWidth - 2 * leftClearance,
      height: containerHeight - topClearance - bottomClearance
    });
  }, [mainContainerDims]);

  useEffect(() => {
    if (!innerContainerDims || !imageDims) {
      return;
    }
    const { width: containerWidth, height: containerHeight } = innerContainerDims;
    const containerRatio = containerWidth / containerHeight;

    const { naturalWidth: imageWidth, naturalHeight: imageHeight } = imageDims;
    const imageRatio = imageWidth / imageHeight;

    const [scaledWidth, scaledHeight] = (() => {
      if (containerRatio == imageRatio) {
        return [containerWidth, containerHeight];
      } else if (containerRatio > imageRatio) {
        return [containerHeight * imageRatio, containerHeight];
      } else {
        return [containerWidth, (containerWidth * imageHeight) / imageWidth];
      }
    })();

    setSizeStyles({
      width: Number(scaledWidth),
      height: Number(scaledHeight),
      widthScaleFactor: Number(scaledWidth) / CONFIG.bgWidth,
      heightScaleFactor: Number(scaledHeight) / CONFIG.bgHeight,
    });
  }, [innerContainerDims, imageDims]);

  const { width, height, heightScaleFactor, widthScaleFactor } = sizeStyles;
  const draggableScaleFactor = firstDims ? width / firstDims.width : 1;

  const ready = mainContainerDims && imageDims;

  const showButtons =
    eventConfig.showStageButton &&
    CONFIG.zone !== 'booth' &&
    parent !== IManualLayoutParents.EXPO;

  const boothShowTime = new Date() > new Date('09/12/2020 5:30:00 PM');
  const showBoothButton = (() => {
    if (eventId === 'aed1f318-3da4-461e-a119-a282d2973d3d') {
      return boothShowTime;
    }

    return true;
  })();

  // Runs through all the config widgets and sets up common variables like ID and widgetType.
  // Does not affect widgets configured in backend.
  const formatConfigWidgets = (configWidgets) => {
    if (!configWidgets) return [];

    return configWidgets.map((widget) => {
      if (!widget.widgetType) {
        widget.widgetType = widget.type;
      }
      if (!widget.widgetId) {
        widget.widgetId = `TEMP-${uuidv4()}`;
      }
      return widget;
    })
  }

  const [widgetsList, setWidgetsList] = useState(widgetDetails);
  useEffect(() => {
    if (layoutDetails.bgUrl != null) {
      setWidgetsList(formatConfigWidgets(widgetDetails));
    } else {
      setWidgetsList(CONFIG && formatConfigWidgets(CONFIG.widgets));
    }
  }, [layoutDetails, widgetDetails]);

  const [zoomableWidgets, setZoomableWidgets] = useState([]);
  useEffect(() => {
    if (!widgetsList) return;

    const zoomableWidgetsList = widgetsList.filter(
      ({ widgetType }) => ZOOMABLE_WIDGET_TYPES.includes(widgetType)
    );
    setZoomableWidgets(zoomableWidgetsList)
  }, [widgetsList])


  const randomInRange = (min, max) => {
    return Math.random() * (max - min) + min;
  }

  const zoomWidget = (widget) => {
    if (!widget) return;

    const { width: cw, height: ch } = innerContainerDims;
    const { width: scaledWidth, height: scaledHeight, widthScaleFactor, heightScaleFactor } = sizeStyles;

    // const randomScaleFactor = randomInRange(0.6, 0.9);
    const randomScaleFactor = 0.9;
    const occupiableWidgetHeight = randomScaleFactor * Number(height);
    const occupiableWidgetWidth = randomScaleFactor * Number(width);
    const occupiableRatio = occupiableWidgetWidth / occupiableWidgetHeight;

    const { x, y, width: widgetWidth, height: widgetHeight } = widget;
    const widgetRatio = Number(widgetWidth) / Number(widgetHeight);

    const [scaledWidgetWidth, scaledWidgetHeight, limitingFactor] = (() => {
      if (occupiableRatio == widgetRatio) {
        return [occupiableWidgetWidth, occupiableWidgetHeight, 'both'];
      } else if (occupiableRatio > widgetRatio) {
        return [occupiableWidgetHeight * widgetRatio, occupiableWidgetHeight, 'height'];
      } else {
        return [occupiableWidgetWidth, occupiableWidgetWidth / widgetRatio, 'width'];
      }
    })();

    const zoomScaleFactor = scaledWidgetWidth / (widgetWidth * widthScaleFactor);

    // if height is limiting, there is space to move along the width
    // if width is limiting there is space to move along the height

    const posX = Number(x) * widthScaleFactor;
    const posY = Number(y) * heightScaleFactor;

    const heightAdjustmentAvailable = Number(ch) - scaledWidgetHeight;
    const widthAdjustmentAvailable = Number(cw) - scaledWidgetWidth;

    // const randomHeightPadding = Math.floor(randomInRange(heightAdjustmentAvailable/2, heightAdjustmentAvailable));
    // const randomWidthPadding = Math.floor(randomInRange(widthAdjustmentAvailable/3, widthAdjustmentAvailable));
    const heightPadding = Math.floor(heightAdjustmentAvailable / 2);
    const widthPadding = Math.floor(widthAdjustmentAvailable / 2);

    const transformX = -posX - ((cw - scaledWidth) / 2) + widthPadding;
    const transformY = -posY - ((ch - scaledHeight) / 2) + heightPadding;

    tl.set(imageRef, { transformOrigin: `${posX}px ${posY}px` }, 0.1)
      .from(imageRef, 0, { x: 0, y: 0 })
      .to(imageRef, 0.4, { x: transformX, y: transformY, ease: Power2.easeOut })
      .to(imageRef, 0.4, { scale: zoomScaleFactor, ease: Power2.easeOut, delay: -0.3 });
    setDisplayState(DISPLAY_STATES.ZOOMED);
    setCurrentWidget(widget);
    setZoomedIn(true);
  }

  const [hasNextWidget, setHasNextWidget] = useState(true);
  const [hasPrevWidget, setHasPrevWidget] = useState(true);
  useEffect(() => {
    if (!currentWidget) return;

    for (const [index, widget] of zoomableWidgets.entries()) {
      if (widget.widgetId === currentWidget.widgetId) {
        const nextWidget = zoomableWidgets[index + 1];
        const prevWidget = zoomableWidgets[index - 1];
        setHasNextWidget(!!nextWidget);
        setHasPrevWidget(!!prevWidget);
        break;
      }
    }
  }, [currentWidget, zoomableWidgets])

  useEffect(() => {
    setCurrentWidget(null);
  }, [boothId])

  useEffect(() => {
    if (!zoomedIn) return;

    if (!currentWidget) {
      const firstWidget = zoomableWidgets[0];
      zoomWidget(firstWidget);
    } else {
      zoomWidget(currentWidget);
    }
  }, [zoomedIn])

  const zoomNextWidget = () => {
    if (!currentWidget) {
      return;
    }

    let nextWidget = null;
    for (const [index, widget] of zoomableWidgets.entries()) {
      if (widget.x === currentWidget.x && widget.y === currentWidget.y) {
        nextWidget = zoomableWidgets[index + 1];
        break;
      }
    }

    if (nextWidget) {
      zoomWidget(nextWidget)
    }
  }

  const zoomPrevWidget = () => {
    if (!currentWidget) {
      return;
    }

    let prevWidget = null;
    for (const [index, widget] of zoomableWidgets.entries()) {
      if (widget.x === currentWidget.x && widget.y === currentWidget.y) {
        prevWidget = zoomableWidgets[index - 1];
        break;
      }
    }

    if (prevWidget) {
      zoomWidget(prevWidget)
    }
  }

  const zoomOut = () => {
    tl
      .to(imageRef, 0.3, { x: 0, y: 0, left: 0, top: 0, scale: 1, ease: Power2.easeOut })
      .set(imageRef, { transformOrigin: `0px 0px` });
    setDisplayState(DISPLAY_STATES.COVER)
    setCurrentWidget(null);
    setZoomedIn(false);
  }

  const [imagesLoading, setImagesLoading] = useState({
    fullImage: true,
    bgImageScaled: true
  })

  const handleImageLoadStatus = (key, value) => {
    setImagesLoading(prev => ({ ...prev, [key]: value }))
  }

  return (
    <>
      {
        (imagesLoading?.fullImage || imagesLoading?.bgImageScaled) && <FullScreenLoader />
      }
      <div ref={mainContainerCallbackRef} className={styles.container}>
        <img
          className={classnames(styles.bgImageFull, {
            [styles.transparent]: !ready,
          })}
          src={boothBGImageURL}
          onLoad={e => handleImageLoadStatus('fullImage', false)}
          onError={e => handleImageLoadStatus('fullImage', false)}
        />
        {
          innerContainerDims &&  
            <div
              style={{
                width: `${width}px`,
                height: `${height}px`,
              }}
              className={classnames(styles.mainContainer, {
                [styles.transparent]: !ready,
              })}
              ref={(el) => imageRef = el}
            >
              <img
                ref={imageCallbackRef}
                className={styles.bgImageScaled}
                src={bgImageScaled}
                onLoad={e => handleImageLoadStatus('bgImageScaled', false)}
                onError={e => handleImageLoadStatus('bgImageScaled', false)}
              />
              {
                ready && widgetsList.map((widget, idx) => {
                  const { widgetId, x, y, width, height, widgetType, colorScheme, config, type } = widget;
                  const zoomableWidget = ZOOMABLE_WIDGET_TYPES.includes(widgetType);
                  return (
                    <div key={widgetId} id={`w-${widgetId || idx}`} className={styles.widgetElements}>
                      <Widget
                        moveable={false}
                        widgetId={widgetId}
                        key={idx}
                        type={type || widgetType}
                        colorScheme={colorScheme}
                        initialPosition={{
                          x: Number(x) * widthScaleFactor,
                          y: Number(y) * heightScaleFactor,
                          width: Number(width) * widthScaleFactor,
                          height: Number(height) * heightScaleFactor,
                        }}
                        scale={draggableScaleFactor}
                        config={typeof config === 'string' ? JSON.parse(config) : config}
                        containerClassName={styles.widgetContainer}
                        handleShowBoothOwners={handleShowBoothOwners}
                        showContactUsWidget={showContactUsWidget}
                        sizeStyles={sizeStyles}
                      >
                        {
                          zoomableWidget && (
                            <div className={styles.viewIcon}
                              onClick={() => {
                                zoomedIn ? zoomOut() : zoomWidget(widget);
                              }}
                            >
                              {zoomedIn ? <BackIcon /> : <EyeIcon />}
                              <span className={styles.viewTip}>
                                {zoomedIn ? "BACK" : "VIEW"}
                              </span>
                            </div>
                          )
                        }
                      </Widget>
                    </div>
                  )
                })
              }
              <div
                className={classnames(styles.stageButton, {
                  [styles.singleStageButton]: !showBoothButton,
                })}
              >
                {showButtons && (
                  <>
                    {eventId === '51d5ce08-800d-40ec-b5aa-11fe976ab12c' ? (
                      <Link
                        style={{ marginRight: '20px' }}
                        to={`/l/event/${eventId}/stages`}
                      >
                        <IconTextButton
                          activeIcon={VIDEO}
                          defaultIcon={VIDEO}
                          activeLabel="Go to Pitch"
                          defaultLabel="Go to Pitch"
                          showDefault={true}
                          buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                        />
                      </Link>
                    ) : (
                        <Link
                          style={{ marginRight: '20px' }}
                          to={`/l/event/${eventId}/stages`}
                        >
                          <IconTextButton
                            activeIcon={VIDEO}
                            defaultIcon={VIDEO}
                            activeLabel="Go to Stage"
                            defaultLabel="Go to Stage"
                            showDefault={true}
                            buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                          />
                        </Link>
                      )}
                    {showBoothButton && (
                      <Link to={`/l/event/${eventId}/expo`}>
                        <IconTextButton
                          activeIcon={CAMERA}
                          defaultIcon={CAMERA}
                          activeLabel="Visit Expo"
                          defaultLabel="Visit Expo"
                          showDefault={true}
                          buttonType={IIconTextButtonTypes.PRIMARY_SQUARE_FULL}
                        />
                      </Link>
                    )}
                  </>
                )}
            </div>
          </div>
        }
        {
          zoomedIn && (
            <div className={styles.boothControlsContainer}>
              <div
                className={classnames(styles.control, {
                  [styles.disabled]: !hasPrevWidget,
                })}
              >
                <div className={styles.prev} onClick={zoomPrevWidget}>
                  <LeftIcon color={iconColor} />
                </div>
                <span>PREVIOUS</span>
              </div>
              <div className={styles.control}>
                <div className={styles.main} onClick={zoomOut}>
                  <DownIcon color={iconColor} />
                </div>
                <span>ZOOM OUT</span>
              </div>
              <div
                className={classnames(styles.control, {
                  [styles.disabled]: !hasNextWidget,
                })}
              >
                <div className={styles.next} onClick={zoomNextWidget}>
                  <RightIcon color={iconColor} />
                </div>
                <span>NEXT</span>
              </div>
            </div>
          )
        }
      </div >
    </>
  );
};

export default ImmersiveManualLayout;
export { IManualLayoutParents };
