import React from 'react';
import { useFormState } from 'react-final-form';
// components
import FormField from '@/components/ui/fields/FormField';
// styles + types
import styles from './styles.module.scss';
import { IFormSectionRowProps } from './types';

const FormSectionRow = (props: IFormSectionRowProps) => {
  const {
    fields,
    readOnly,
    styleClass = '',
    isSponsorLandingPage = false,
    isEditProfileSection = false,
  } = props;
  const { values: formValues } = useFormState();

  const totalLength = fields.length;

  return (
    <div className={`${styles.formSectionRowContainer} ${styles[styleClass]}`}>
      {fields.map((field, index) => (
        <FormField
          field={field}
          formValues={formValues}
          index={index}
          key={index}
          readOnly={readOnly || field.readOnly}
          totalLength={totalLength}
          isSponsorLandingPage={isSponsorLandingPage}
          isEditProfileSection={isEditProfileSection}
        />
      ))}
    </div>
  );
};

export default React.memo(FormSectionRow);
