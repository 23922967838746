import React from 'react';
// components
import StreamWidgetSettings from './settings';
import { IStreamWidgetProps } from './types';
import ChannelStreamsContainer from '@/components/ChannelStreamsContainer';
import ErrorBoundaryModal from '@/components/ui/modals/ErrorBoundaryModal';
import { IStageParent } from '@/components/Stage/types';
import DailyCall from '@/components/DailyCall';
import DyteCall from '@/components/DyteMeetingEmbed';

const StreamWidget = (props: IStreamWidgetProps) => {
  const { parent, refId } = props;

  const dailyRoomName = (() => {
    if (parent !== IStageParent.LIVE_DISCUSSION) {
      return;
    }

    const roomMapping = {
      '9f8ca0e4-4234-4d49-93c7-fe94b72c2b68': 'testdaily',
      'fb8e9d10-cf91-4af3-9fd1-7bc2ab002ce0': 'big-room4',
      '5367e70f-743e-451a-96cd-ca32f73c62e6': 'staging-zuddl-banter',

      '38948879-e8b0-40ab-882e-f7f96ba908f4': 'big-room',
      '1879d72b-bcec-42b5-a61a-0c46501adaa7': 'big-room2',
      '8cecd86d-7791-44a0-9b8e-9f253e617811': 'big-room3',
      '7464ab5d-6bff-4bc0-909b-98df037f83ed': 'big-room4'
    };

    return roomMapping[refId];
  })();

  const [dyteMeetingId, dyteRoomName] = (() => {
    if (parent !== IStageParent.LIVE_DISCUSSION) {
      return [];
    }

    const refIdMeetingIdMapping = {
      // For Hotels Demo
      '4cebc887-54e2-496b-8b4a-dba7b2940d9a': '343fe38d-ee76-4ec5-84aa-863aa38023fa',
      // For Deloitte
      '4f94a8af-e17e-48da-bc2a-6428cfec9f1c': '61b7bf8f-a8f1-4b19-b66e-d6f227f2ffd2',
      '81ca4791-e569-4ebf-9e40-484acbe838e0': 'e8440d4b-bdcc-45f2-ac8c-2c8687248247',
      '41305ee8-c675-4e95-8b82-0b09cb482fe1': 'c86d5756-4281-4912-9728-9994224bff6f',
      '428471c9-ac97-4850-9a01-9355a0d2b679': '179975d4-690c-4eec-9d75-0ee3480a3b15',
      'a40c9d68-43fa-4599-af48-952a7096229e': '2b68dd46-ebb8-4568-a127-e07021e92e87',
      '6ef53544-7dcd-4dd3-8c63-5adaeddabd58': '766cf45c-e921-46b5-b096-523a635dc1f9',
      'aef4ea92-541a-4dc9-a34d-6dfab49356f1': '43ebefaf-035d-42fd-b84b-2b7861250f1c',
      '4fab3ac7-2d00-42e9-a7c4-a359adc46e0b': '9acea8ce-d665-4ae5-876b-76a72d933df2',
      '7cffcf5a-493b-4b50-8267-133e5a831950': 'b3fab763-e9a5-4d54-9891-05452d1d6be3',
      '1c659588-fbc4-4051-bf43-5fca86fad7fb': '1083d557-31ee-4c87-9829-d1898b139733',
      // For ETP Hilton Breakout Rooms
      'be56fb5c-d5d0-460a-a5b1-7244a9458aff': '8d3f4b3d-3369-4d5e-964a-7c0d9307613d',
      'daf7d09b-bc4a-401e-af0d-6d8470501f38': '0544fee3-3d5c-4fcc-85fa-8f97c312a55d',
      '25a5fa75-1331-448c-a69e-b39717235acd': '9a73b8be-8a0c-4c46-952d-2f5c980d3ee9',
      // Sandbox Event - CS 461
      '584eff96-1f21-42b2-a5d4-e56e72bbd373': '798aa2ea-cc40-4bf4-8477-fd116691fc6a',
      'f2c3cfa6-b45e-477e-8d74-ce06e4b8a39f': 'c549e2ff-a391-459c-8887-d5ef222f9c6b',
      '5b891b89-4b0c-4c73-a6ff-ca4ee3a1a51b': '06c1241b-4476-4ea7-a534-8496e2e393b1',
      '78882e6d-2a5e-406c-8e0c-29573d37742f': '37c22a5e-25b1-4b23-ad1d-96904458b256',
      // For CS-453
      '957025e2-6f9e-4cb6-bcb7-6156c71c8e3e': '5952ee81-c09a-4cb6-ae51-00189ec22aca',
      // Sandbox Event - CS 480
      '88acbc3f-8a21-42e3-84f2-fcff9787ac9f': 'a8c3a83c-874b-4ba2-8c0b-977c15d520b8',
      'd78ba4e8-5ac7-4326-a7e5-1a942fa3d472': '4dc1aa98-26cf-410f-bac8-0386b64a4e6e',
      // Sandbox Uber - CS 508
      'cc21e047-5bbf-4770-afe1-bdc45ad37c3d': '0d84d51c-23d5-45f3-881f-36eee98c9509',
      // Sandbox BCG - CS 524
      '06558304-1527-4003-97c3-af40c27869e7': 'f5a17037-1012-4464-bcbc-ae3145cc687e',
      // Staging - Zuddl Banter
      '5367e70f-743e-451a-96cd-ca32f73c62e6': 'fa4c690a-8bf1-423f-a6e9-80f4d3567ab6',
      // Time Share Hilton - CS 536
      '36c40abd-2e20-4196-a5fc-c7e723dd490b': '14656916-1395-42fe-af3f-08bc052dec2e',
      // Google Demo - CS-569
      '888460cf-f00c-4cdc-bbdd-48d134ec86f1': 'd74f440a-f8ea-4c6d-a86b-318c15dc5bf7',
      '9512ff54-8253-49cc-af65-302fec1d3f49': '96820023-d4e2-4d63-8421-fd4f91044ef0',
      '403acce8-aec5-459c-8edb-ec77fa5c7aef': '5df3dc5a-c25f-4f3e-a6da-080037cab539',
      // Vodafone Demo - CS-579
      '9934bbef-bff6-48f4-bbf3-4bf6200ef34c': 'd057f7ce-1a99-473a-a58b-6517e3164262',
      'e94dbebc-e649-44f3-a1ca-ff423225e9d6': '051d83b6-841f-4a87-8a35-8e23bba291d3',
      // Summer Internship Program 2022 - Deloitte
      '182fd927-4658-435f-94fe-30824463d30f': 'cc8f6428-1444-4374-bbb7-82323391b99a',
      // Wipro - Sandbox
      '4e45a396-82de-4b48-8c26-a8647034fb8f': '66b0a5dc-8958-47be-bf8f-d1ce14cca83f',
      //Sandbox-Cargill
      'f843b0e1-36c6-40fb-ad80-02cbf8de252a': 'da35a0d6-911a-4900-9d13-81b13757cbb0',
      '7841ac58-252d-4759-a70a-bd7ab4ef26ae': '7e280a22-89c8-4e95-a8a6-4f003708ecea',
      '2b5a85f4-bf42-4f55-84ba-fe68a434905b': '6815bd28-37f6-49c0-93ef-d1d95fe4e6fc',
      'f63602ad-d491-48f4-bc1a-ae3e0ae4f267': '16248adf-8b91-4d30-bbb7-1fa48d7875fe',
      '046e92b8-0e08-4775-9085-b4b545adcf07': 'e6c91f2f-7f29-4dd2-865d-19a34e9ebec4',
      //CS-743 Clevertap
      '65d59795-801b-49d6-93d2-4a0a5b877f8e': 'fa0db4ab-bb89-431f-bd2d-367efcfdd7c5',
      //CS-829
      '24c7256a-3f2b-40ca-bea9-d1a90c985352': 'a40a1b6a-5268-4044-a650-ac588f2c3086',

    };

    const meetingId = refIdMeetingIdMapping[refId];

    const meetingIdRoomNameMapping = {
      '343fe38d-ee76-4ec5-84aa-863aa38023fa': 'nkamdi-ogadwl',
      '61b7bf8f-a8f1-4b19-b66e-d6f227f2ffd2': 'uymdzt-kitshg',
      'e8440d4b-bdcc-45f2-ac8c-2c8687248247': 'djsnln-itwgct',
      'c86d5756-4281-4912-9728-9994224bff6f': 'kynllu-cjtnkl',
      '179975d4-690c-4eec-9d75-0ee3480a3b15': 'wiomgz-gqsxrv',
      '2b68dd46-ebb8-4568-a127-e07021e92e87': 'cytcub-ylkrdf',
      '766cf45c-e921-46b5-b096-523a635dc1f9': 'vzhykf-tyaboy',
      '43ebefaf-035d-42fd-b84b-2b7861250f1c': 'tjoaud-rkilsx',
      '9acea8ce-d665-4ae5-876b-76a72d933df2': 'qprhak-wxqkdu',
      'b3fab763-e9a5-4d54-9891-05452d1d6be3': 'zvclum-pdzhyg',
      '1083d557-31ee-4c87-9829-d1898b139733': 'tcgpdr-jgfhaw',
      '8d3f4b3d-3369-4d5e-964a-7c0d9307613d': 'qezhgo-icjxml',
      '0544fee3-3d5c-4fcc-85fa-8f97c312a55d': 'hsmxgh-xcphqt',
      '9a73b8be-8a0c-4c46-952d-2f5c980d3ee9': 'tzhbzd-myxcej',
      '798aa2ea-cc40-4bf4-8477-fd116691fc6a': 'nxgsjl-bnfhfd',
      'c549e2ff-a391-459c-8887-d5ef222f9c6b': 'gapkcm-zaethl',
      '06c1241b-4476-4ea7-a534-8496e2e393b1': 'mexiqx-pjhlvv',
      '37c22a5e-25b1-4b23-ad1d-96904458b256': 'vlohnq-kkmwpz',
      '5952ee81-c09a-4cb6-ae51-00189ec22aca': 'uytysz-hipyza',
      'a8c3a83c-874b-4ba2-8c0b-977c15d520b8': 'jybtzj-bzyuyc',
      '4dc1aa98-26cf-410f-bac8-0386b64a4e6e': 'msnvyn-aruejq',
      '0d84d51c-23d5-45f3-881f-36eee98c9509': 'xiwcbp-wugjrp',
      'f5a17037-1012-4464-bcbc-ae3145cc687e': 'jytfko-vsxafk',
      'fa4c690a-8bf1-423f-a6e9-80f4d3567ab6': 'vgrwfk-dyoygv',
      '14656916-1395-42fe-af3f-08bc052dec2e': 'nbywpi-yndzwq',
      'd74f440a-f8ea-4c6d-a86b-318c15dc5bf7': 'jfllzw-ppyoex',
      '96820023-d4e2-4d63-8421-fd4f91044ef0': 'xawzpp-bduava',
      '5df3dc5a-c25f-4f3e-a6da-080037cab539': 'mkilfk-cdttsi',
      'd057f7ce-1a99-473a-a58b-6517e3164262': 'evavyy-ltjyfu',
      '051d83b6-841f-4a87-8a35-8e23bba291d3': 'wbppck-dhxcgu',
      'cc8f6428-1444-4374-bbb7-82323391b99a': 'nnboky-vacchn',
      '66b0a5dc-8958-47be-bf8f-d1ce14cca83f': 'ctbzsd-inzamv',
      'da35a0d6-911a-4900-9d13-81b13757cbb0': 'xlokvv-iehrhi',
      '7e280a22-89c8-4e95-a8a6-4f003708ecea': 'jnnbyg-inapvo',
      '6815bd28-37f6-49c0-93ef-d1d95fe4e6fc': 'mjgqav-jkyffn',
      '16248adf-8b91-4d30-bbb7-1fa48d7875fe': 'pbiuba-ibowjd',
      'e6c91f2f-7f29-4dd2-865d-19a34e9ebec4': 'yydkxf-fgkwxz',
      'fa0db4ab-bb89-431f-bd2d-367efcfdd7c5': 'exblff-sraaph',
      'a40a1b6a-5268-4044-a650-ac588f2c3086': 'bceohu-vxljbg',
    };

    const roomName = meetingIdRoomNameMapping[meetingId];

    return [meetingId, roomName];
  })();

  if (dyteMeetingId && dyteRoomName) {
    return (
      <ErrorBoundaryModal>
        <DyteCall meetingId={dyteMeetingId} roomName={dyteRoomName} />
      </ErrorBoundaryModal>
    );
  }

  return (
    <ErrorBoundaryModal >
      {
        dailyRoomName ?
          <DailyCall key={dailyRoomName} roomName={dailyRoomName} /> :
          <ChannelStreamsContainer {...props} />
      }
    </ErrorBoundaryModal>
  );
};

StreamWidget.Settings = StreamWidgetSettings;
export default StreamWidget;
