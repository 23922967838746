import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';
import { CrossIcon } from '@/layouts/OrganizerChecklist/icons';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  messageIdAtom,
  openReactionsListPopupAtom,
} from '../ChatMessageV2/atoms';
import { LeftArrow, RightArrow } from './icons';
import ProfilePicture from '@/components/ui/ProfilePicture';
import { getImageUrlWithSize, prefixPicUrlIfNeeded } from '@/utils/helpers';
import { IGeneralSizeTypes } from '@/types';
import { useReactionMutation, useReactionQuery } from '@/api/reactions';
import { selectCurrentUser } from '@/models/account';
import { useSelector } from 'react-redux';

const ReactionsListPopup = () => {
  const [activeTab, setActiveTab] = useState<string>('');
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const messageId = useAtomValue(messageIdAtom);

  const {
    data: reactionsListData,
    refetch: refetchReactions,
  } = useReactionQuery.getReactionsList(messageId);

  const {
    mutate: removeEmojiReaction,
    isSuccess: isRemoveEmojiReactionSuccessFul,
  } = useReactionMutation.removeEmojiReaction(messageId);

  const currentUser = useSelector(selectCurrentUser);
  const { accountId } = currentUser;
  const tabsRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const setOpenReactionListPopup = useSetAtom(openReactionsListPopupAtom);

  const handlePopupClose = () => {
    setOpenReactionListPopup(false);
  };

  const updateArrowVisibility = () => {
    // Check if the tabs container is overflowing to determine whether to show arrows
    if (tabsRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tabsRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth - 2);
    }
  };

  const handleLeftArrowClick = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft -= 100;
      updateArrowVisibility();
    }
  };

  const handleRightArrowClick = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft += 100;
      updateArrowVisibility();
    }
  };

  const handleRemoveReaction = reactionId => {
    removeEmojiReaction(reactionId);
  };

  useEffect(() => {
    updateArrowVisibility();
  }, [activeTab]);

  useEffect(() => {
    if (reactionsListData) {
      const sortedReactions = Object.keys(reactionsListData).sort(
        (a, b) => reactionsListData[b].length - reactionsListData[a].length,
      );
      if (sortedReactions.length > 0) {
        setActiveTab(sortedReactions[0]);
      }
    }
  }, [reactionsListData]);

  useEffect(() => {
    if (isRemoveEmojiReactionSuccessFul) {
      refetchReactions();
    }
  }, [isRemoveEmojiReactionSuccessFul, refetchReactions]);

  return (
    <div className={styles.popupContainer}>
      <div className={styles.popupHeader}>
        <span className={styles.popupTitle}>Message reactions</span>
        <div onClick={handlePopupClose}>
          <CrossIcon />
        </div>
      </div>
      <div className={styles.popupTabs}>
        {showLeftArrow && (
          <div className={styles.arrowIcon} onClick={handleLeftArrowClick}>
            <LeftArrow />
          </div>
        )}
        <div className={styles.popupTabsInner} ref={tabsRef}>
          {reactionsListData &&
            Object.keys(reactionsListData)
              .sort(
                (a, b) =>
                  reactionsListData[b].length - reactionsListData[a].length,
              )
              .map(emoji => (
                <button
                  type="button"
                  key={emoji}
                  className={classnames(styles.popupTab, {
                    [styles.active]: activeTab === emoji,
                  })}
                  onClick={() => handleTabClick(emoji)}
                >
                  {emoji} {reactionsListData[emoji].length}{' '}
                </button>
              ))}
        </div>
        {showRightArrow && (
          <div className={styles.arrowIcon} onClick={handleRightArrowClick}>
            <RightArrow />
          </div>
        )}
      </div>
      <div className={styles.popupContent}>
        {activeTab &&
          reactionsListData &&
          reactionsListData[activeTab]?.map(reaction => (
            <div key={reaction.id} className={styles.reactionItem}>
              <div>
                <div className={styles.pictureWrapper}>
                  <ProfilePicture
                    imageUrl={getImageUrlWithSize(
                      prefixPicUrlIfNeeded(reaction.pU || ''),
                      20,
                      20,
                      'clip',
                    )}
                    name={`${reaction.fN} ${reaction.lN}`}
                    styleClass="chatProfilePic"
                    hexColor={null}
                    disableTooltip={true}
                    iconSize={IGeneralSizeTypes.XXSMALL}
                  />
                </div>
                <span>
                  {reaction.aId === accountId ? (
                    <>
                      You
                      <p
                        className={styles.removeText}
                        onClick={() => handleRemoveReaction(reaction.id)}
                      >
                        Click to remove
                      </p>
                    </>
                  ) : (
                    `${reaction.fN} ${reaction.lN}`
                  )}
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ReactionsListPopup;
