/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import ReactTooltip from 'react-tooltip';
// styles + types
import styles from './styles.module.scss';

const DropdownOption = props => {
  const {
    option: { label, onClick, icon },
    isDisabled,
    hasMsgCharLimitReachedForPinning,
  } = props;

  const handleOnLinkClick = e => {
    // if there's no relative url don't do anything.
    if (onClick && !isDisabled) {
      onClick();
    }
  };

  let labelClass = styles.dropdownOption;
  if (label === 'Delete') {
    labelClass = styles.deleteDropdownOption;
  } else if (isDisabled) {
    labelClass = styles.disabledDropdownOption;
  }

  return (
    <div
      className={labelClass}
      onClick={handleOnLinkClick}
      data-tip
      data-for={`disable-pin-chat-${label}`}
      tabIndex={0}
      role='menuitem'
    >
      {icon} <label>{label}</label>
      {isDisabled && label === 'Pin Message' && (
        <ReactTooltip
          id={`disable-pin-chat-${label}`}
          place="left"
          effect="solid"
        >
          {hasMsgCharLimitReachedForPinning
            ? "Message is too long. Can't be pinned"
            : 'Only 3 messages can be pinned at one time'}
        </ReactTooltip>
      )}
    </div>
  );
};

export default DropdownOption;
