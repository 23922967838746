/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
// components
// styles
import styles from './styles.module.scss';
// utils
import { getCouponExpiryDate, getImageUrlWithSize } from '@/utils/helpers';
// api + models
// types
import { ICouponWidgetProps } from './types';
// configs
import moment from 'moment';
import {
  CrossIcon,
  GiftIcon,
} from '@/pages/live-event/BoothPage/BoothPageInner/icons';
import NewLabel, {
  INewLabelColors,
  INewLabelMargins,
  INewLabelSizes,
  INewLabelStyles,
} from '@/components/ui/content/NewLabel';
import { CouponTicketIcon } from '../icons';
import Modal from '@/components/ui/modals/Modal';
import { IModalTypes } from '@/components/ui/modals/Modal/types';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import api from '@/api';
import { useParams } from 'react-router-dom';
import { makeSelectBoothListByEventId } from '@/models/event';
import { IMAGE_URL } from '@/config';

const CouponWidget = ({
  code,
  expiryDateTime,
  offer,
  backgroundColor,
  titleColor,
  description,
  title,
  enableClaimCoupon = true,
}: ICouponWidgetProps) => {
  const { boothId = '', eventId = '' } = useParams<{
    boothId: string;
    eventId: string;
  }>();
  const [booth, setCurrentBooth] = useState(null);
  const selectBoothsByEventId = useMemo(
    () => makeSelectBoothListByEventId(eventId),
    [],
  );
  const allBooths = useSelector(selectBoothsByEventId);
  useEffect(() => {
    let currentBooth = null;
    if (allBooths && allBooths.length > 0) {
      currentBooth = allBooths.find(item => item.boothId === boothId);
    }
    if (currentBooth) {
      setCurrentBooth(currentBooth);
      return;
    }
    (async () => {
      const { data } = await api.booth.getBoothDetails(boothId);
      setCurrentBooth(data);
    })();
  }, [boothId, allBooths]);

  const dispatch = useDispatch();
  const copyCouponCode = () => {
    const copyText = document.getElementById('boothCouponCodeDiv');
    if (!copyText) return;

    let range = document.createRange();
    range.setStart(copyText, 0);
    range.setEnd(copyText, 1);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    /* Copy the text inside the text field */
    document.execCommand('copy');
    selection.removeAllRanges();
    dispatch({
      type: 'global/addSuccessToast',
      payload: { description: 'Coupon Code Copied' },
    });
  };

  const registerBoothCouponClaim = setShow => {
    api.booth
      .registerCouponClaim(boothId)
      .then(response => {
        setShow(true);
        dispatch({
          type: 'global/addSuccessToast',
          payload: { description: response.data },
        });
      })
      .catch(() => {
        setShow(true);
        dispatch({
          type: 'global/addDangerToast',
          payload: { description: 'Register Coupon Code Copied Failed' },
        });
      });
  };

  const isBoothBrandLogoUrl =
    booth?.brandLogoUrl && booth?.brandLogoUrl !== IMAGE_URL;

  return (
    <>
      <div className={styles.widgetContainer}>
        <div className={styles.topContainer}>
          <CouponTicketIcon />
          <div className={styles.couponTicketInfo}>
            <div
              className={
                isBoothBrandLogoUrl
                  ? styles.infoContainer
                  : styles.infoContainerWithoutLogo
              }
            >
              {isBoothBrandLogoUrl && (
                <img
                  className={styles.boothImage}
                  src={getImageUrlWithSize(booth.brandLogoUrl, 42, 42)}
                />
              )}
              <div>
                <div className={styles.couponCode} id="boothCouponCodeDiv">
                  {code}
                </div>
                <div className={styles.expiry}>
                  Expires :{' '}
                  {expiryDateTime
                    ? getCouponExpiryDate(moment(expiryDateTime))
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.offerLabel}>OFFER</div>
        <div className={styles.couponTitle}>{offer}</div>
        <Modal
          styleClass={IModalTypes.NEUTRAL_LIGHTEST}
          className={styles.boothModalStyles}
          noHeader={true}
          trigger={({ setShow }) => (
            <div
              className={styles.actionButton}
              style={{ backgroundColor: backgroundColor, color: titleColor }}
              onClick={() => {
                enableClaimCoupon && registerBoothCouponClaim(setShow);
              }}
            >
              <GiftIcon color={titleColor} />
              <NewLabel
                text="CLAIM COUPON"
                labelColor={INewLabelColors.SECONDARY}
                labelSize={INewLabelSizes.SMALL}
                labelStyle={INewLabelStyles.SEMI_BOLDED}
                labelMargin={INewLabelMargins.SMALL_MARGIN_LEFT}
                clickable={true}
              />
            </div>
          )}
          render={({ setShow }) => (
            <div className={styles.couponModal}>
              <div
                className={styles.modalCloseButton}
                onClick={() => {
                  setShow(false);
                }}
              >
                <CrossIcon />
              </div>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
              <div className={styles.topContainer}>
                <CouponTicketIcon />
                <div className={styles.couponTicketInfo}>
                  <div
                    className={
                      isBoothBrandLogoUrl
                        ? styles.infoContainer
                        : styles.infoContainerWithoutLogo
                    }
                  >
                    {isBoothBrandLogoUrl && (
                        <img
                          className={styles.boothImage}
                          src={getImageUrlWithSize(booth.brandLogoUrl, 42, 42)}
                        />
                      )}
                    <div>
                      <div className={styles.couponCode}>{code}</div>
                      <div className={styles.expiry}>
                        Expires :{' '}
                        {expiryDateTime
                          ? getCouponExpiryDate(moment(expiryDateTime))
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.offerLabel}>OFFER</div>
              <div className={styles.couponTitle}>{offer}</div>
              <div className={styles.btnMenu}>
                <Button
                  className={styles.modalActionButton}
                  label="Thanks! I'll Take it"
                  onClick={() => {
                    setShow(false);
                  }}
                  styleClass={IButtonTypes.CONTRAST_PRIMARY}
                  type="button"
                />
                <div
                  className={styles.copyCouponCodeButton}
                  onClick={() => copyCouponCode()}
                >
                  Copy Coupon Code
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default CouponWidget;
