export enum WidgetTypes {
  CERTIFICATE = 'CERTIFICATE',
  MEDIA = 'MEDIA',
  PHOTOBOOTH = 'PHOTOBOOTH',
  AR_PHOTOBOOTH = 'AR_PHOTOBOOTH',
  RICH_TEXT = 'RICH_TEXT',
  SPONSOR = 'SPONSOR',
  STREAM = 'STREAM',
  TEXT = 'TEXT',
  BUTTON = 'BUTTON',
  HOTSPOT = 'HOTSPOT',
  TICKER = 'TICKER',
  FILE = 'FILE',
  REDIRECT_BOOTH_HOTSPOT = 'REDIRECT_BOOTH_HOTSPOT',
  REDIRECT_IMAGE_CAROUSEL = 'REDIRECT_IMAGE_CAROUSEL',
  IFRAME = 'IFRAME',
  BOOTH_OWNER_BUTTON = "BOOTH_OWNER_BUTTON",
  COUPON_WIDGET = "COUPON_WIDGET",
}

export enum WidgetColorSchemeTypes {
  DARK_BG = 'DARK_BG',
  DARK_TEXT = 'DARK',
  LIGHT_BG = 'LIGHT_BG',
  LIGHT_TEXT = 'LIGHT',
}
