import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
// components
import {
  getInternalRedirectionPath,
  getExternalRedirectionPath,
} from '../WidgetsRedirection';
import Button, { IButtonTypes } from '@/components/ui/buttons/Button';
// styles
import styles from './styles.module.scss';
// utils
import { getZoneSetUpObj, prefixPicUrlIfNeeded } from '@/utils/helpers';
// api + models
// types
import { IButtonWidgetProps } from './types';
import { ILinkType } from '@/components/forms/WidgetForms/ButtonForm/types';
// configs
import { selectZoneConfig } from '@/models/event';
import { useSelector, useDispatch } from 'react-redux';
import { IWidgetType } from '../types';
import IconTextButton, {
  IUIIcons,
  IIconTextButtonTypes,
} from '@/components/ui/buttons/IconTextButton';
import { PROFILE_ICON_SIZE } from '@/styles/iconSizes';
import ReactTooltip from 'react-tooltip';
import { Info } from 'react-feather';

const ButtonWidget = ({
  type,
  redirectURL,
  buttonText,
  buttonType,
  buttonLinkType,
  imageUrl,
  zone,
  withinZone,
  width,
  height,
  backgroundColor,
  titleColor,
  handleShowBoothOwners,
  showContactUsWidget,
}: IButtonWidgetProps) => {
  const { eventId = '' } = useParams() as any;
  const dispatch = useDispatch();

  const [zoneSetup, setZoneSetup] = useState({});
  const zoneConfig = useSelector(selectZoneConfig);
  const location = useLocation();

  useEffect(() => {
    if (!zoneConfig || zoneConfig.length === 0) {
      dispatch({
        type: 'event/getZoneConfig',
        payload: {
          eventId,
        },
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (!zoneConfig) return;
    setZoneSetup(getZoneSetUpObj(zoneConfig));
  }, [zoneConfig]);

  if (
    type === IWidgetType.BOOTH_OWNER_BUTTON &&
    !location.pathname.endsWith('/widgetEdit') &&
    !showContactUsWidget
  ) {
    return null;
  }

  if (
    type === IWidgetType.BOOTH_OWNER_BUTTON &&
    (showContactUsWidget || location.pathname.endsWith('/widgetEdit'))
  ) {
    return (
      <div
        className={showContactUsWidget ? '' : styles.disableButton}
        data-tip
        data-for={showContactUsWidget ? '' : 'expo-widget-owner-disabled'}
      >
        {!imageUrl && (
          <IconTextButton
            showDefault
            defaultLabel={`${buttonText}`}
            defaultIcon={IUIIcons.CHEVRON_RIGHT}
            buttonType={IIconTextButtonTypes.PRIMARY}
            style={{
              backgroundColor: backgroundColor,
              color: titleColor,
              width,
              height,
            }}
            onClick={() =>
              showContactUsWidget &&
              handleShowBoothOwners &&
              handleShowBoothOwners(buttonText)
            }
            iconLast
            iconSize={PROFILE_ICON_SIZE}
            buttonIconColor={titleColor}
          />
        )}
        {imageUrl && (
          <div
            aria-hidden
            className={styles.buttonWidget}
            onClick={() =>
              showContactUsWidget &&
              handleShowBoothOwners &&
              handleShowBoothOwners(buttonText)
            }
          >
            <img
              alt=""
              src={prefixPicUrlIfNeeded(imageUrl)}
              height={height}
              width={width}
              className={styles.imageButton}
            />
          </div>
        )}
        {!showContactUsWidget && (
          <>
            <div className={styles.infoIcon}>
              <Info className={styles.expoInfoIcon} />
            </div>
            <ReactTooltip
              id="expo-widget-owner-disabled"
              place="top"
              effect="solid"
              className={styles.tooltip}
            >
              Private chat and 1:1 meetings are turned off by the Organizer
            </ReactTooltip>
          </>
        )}
      </div>
    );
  }

  return (
    <>
      {!imageUrl && buttonLinkType === ILinkType.EXTERNAL && (
        <a target="_blank" href={getExternalRedirectionPath(redirectURL)}>
          <Button
            label={buttonText}
            type="button"
            styleClass={IButtonTypes[buttonType]}
            className={styles.buttonWidget}
            style={{
              backgroundColor: backgroundColor,
              color: titleColor,
              width,
              height,
            }}
            hasHover
          />
        </a>
      )}
      {imageUrl && buttonLinkType === ILinkType.EXTERNAL && (
        <a target="_blank" href={getExternalRedirectionPath(redirectURL)}>
          <img
            alt=""
            src={prefixPicUrlIfNeeded(imageUrl)}
            height={height}
            width={width}
            className={styles.imageButton}
          />
        </a>
      )}
      {imageUrl && (buttonLinkType === ILinkType.INTERNAL || !buttonLinkType) && (
        <Link
          to={getInternalRedirectionPath(zoneSetup, zone, withinZone, eventId)}
        >
          <img
            alt=""
            src={prefixPicUrlIfNeeded(imageUrl)}
            height={height}
            width={width}
            className={styles.imageButton}
          />
        </Link>
      )}
      {!imageUrl && (buttonLinkType === ILinkType.INTERNAL || !buttonLinkType) && (
        <Link
          to={getInternalRedirectionPath(zoneSetup, zone, withinZone, eventId)}
        >
          {zoneSetup[zone] && (
            <Button
              label={buttonText}
              type="button"
              styleClass={IButtonTypes[buttonType]}
              className={styles.buttonWidget}
              style={{
                backgroundColor: backgroundColor,
                color: `${titleColor}`,
                width: `${width}px`,
                height: `${height}px`,
              }}
              hasHover
            />
          )}
        </Link>
      )}
    </>
  );
};

export default ButtonWidget;

export const UserStarIcon = props => {
  const { size = 24, color = '#ABABAB' } = props;
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6002 14.01C11.8208 13.999 12 14.1791 12 14.4V21.6C12 21.8209 11.8209 22 11.6 22H4.4C4.17909 22 3.99896 21.8208 4.00999 21.6002C4.10881 19.624 4.93737 17.7489 6.34315 16.3431C7.74892 14.9374 9.62401 14.1088 11.6002 14.01ZM18.1861 21.5979C18.0696 21.5366 17.9304 21.5366 17.8138 21.5979L15.7783 22.6679C15.4849 22.8222 15.1419 22.573 15.1979 22.2463L15.5865 19.9802C15.6087 19.8505 15.5657 19.7181 15.4715 19.6262L13.825 18.0206C13.5877 17.7892 13.7187 17.3861 14.0467 17.3384L16.3229 17.0073C16.4532 16.9883 16.5658 16.9065 16.624 16.7884L17.6414 14.7267C17.7881 14.4294 18.212 14.4293 18.3588 14.7266L19.3769 16.7885C19.4352 16.9065 19.5478 16.9883 19.678 17.0073L21.9534 17.3384C22.2814 17.3861 22.4123 17.7891 22.175 18.0206L20.5285 19.6263C20.4343 19.7181 20.3913 19.8504 20.4135 19.9801L20.8013 22.2464C20.8572 22.5731 20.5143 22.8222 20.2209 22.6679L18.1861 21.5979ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
        fill={color}
      />
    </svg>
  );
};
