import React from 'react';
// styles + types
import styles from './styles.module.scss';
import { IFormValidationErrorTooltipNewProps } from './types';

const FormValidationErrorTooltipNew = (
  props: IFormValidationErrorTooltipNewProps,
) => {
  const { value } = props;

  return (
    <div>
      <div className={styles.fieldValidationErrorTooltip}>
        <p className={styles.fieldValidationErrorText}>{value}</p>
      </div>
    </div>
  );
};

export default FormValidationErrorTooltipNew;
