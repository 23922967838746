import React from 'react';
import { ReadOnlyEditor } from '@/components/ui/RichTextEditor';
import RichTextWidgetSettings from './settings';
import { IRichTextWidgetProps } from './types';
import Widget from '../Widget';

const RichTextWidget = ({ richText }: IRichTextWidgetProps) => {
  const blankSlateValue = [{ type: "paragraph", children: [{ text: "Nothing to see here.." }] }]
  return <ReadOnlyEditor value={richText || blankSlateValue}></ReadOnlyEditor>;
};

RichTextWidget.Settings = RichTextWidgetSettings;
export default RichTextWidget;
