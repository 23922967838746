import React from 'react';
// components
import { Volume2 } from 'react-feather';
import Text, {
  ITextColors,
  ITextSizes,
  ITextStyles,
} from '@/components/ui/content/Text';
import { ICON_SIZE_LARGE } from '@/styles/iconSizes';
import { IContentColors } from '@/types';
// styles + types
import styles from './styles.module.scss';
import { IAutoplayClickOverlayProps } from './types';
import { getCssVar } from '@/utils/cssVars';

const AutoplayClickOverlay = (props: IAutoplayClickOverlayProps) => {
  const { onClick } = props;
  return (
    <button
      type="button"
      className={styles.autoplayClickOverlayContainer}
      onClick={onClick}
      title="Tap to play"
    >
      <div className={styles.autoplayClickIcon}>
        <Volume2 size={ICON_SIZE_LARGE} color={getCssVar(IContentColors.WHITE)} />
      </div>
      <Text
        text="tap to"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.SMALL}
        textStyle={ITextStyles.BOLDED_CAPITALIZED}
      />
      <Text
        text="play"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.XLARGE}
        textStyle={ITextStyles.BOLDED_CAPITALIZED}
      />
      <Text
        text="If you continue to face issues, please visit the Lobby and return to the stage"
        textColor={ITextColors.WHITE}
        textSize={ITextSizes.XXSMALL}
      />
    </button>
  );
};

export default AutoplayClickOverlay;
