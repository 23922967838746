import React from 'react';

export const VideoIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M13.6389 1.77979H3.08957C2.27748 1.77979 1.61914 2.43812 1.61914 3.25022V13.7996C1.61914 14.6117 2.27748 15.27 3.08957 15.27H13.6389C14.451 15.27 15.1093 14.6117 15.1093 13.7996V3.25022C15.1093 2.43812 14.451 1.77979 13.6389 1.77979Z" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4.99268 1.77979V15.27" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.7368 1.77979V15.27" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.61914 8.5249H15.1093" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.61914 5.15234H4.99169" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1.61914 11.8975H4.99169" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.7368 11.8975H15.1094" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.7368 5.15234H15.1094" stroke="#CDCDCD" stroke-width="1.34902" stroke-linecap="round" stroke-linejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16.1882" height="16.1882" fill="white" transform="translate(0.270508 0.430664)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const ImageIcon = ({ fillColor }) => {
  const fill = fillColor || "#CDCDCD";

  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.249 2.08154H3.55737C2.79272 2.08154 2.17285 2.70141 2.17285 3.46606V13.1577C2.17285 13.9224 2.79272 14.5422 3.55737 14.5422H13.249C14.0137 14.5422 14.6335 13.9224 14.6335 13.1577V3.46606C14.6335 2.70141 14.0137 2.08154 13.249 2.08154Z" stroke={fill} stroke-width="1.38452" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.98029 6.92737C6.55377 6.92737 7.01868 6.46246 7.01868 5.88898C7.01868 5.31549 6.55377 4.85059 5.98029 4.85059C5.4068 4.85059 4.94189 5.31549 4.94189 5.88898C4.94189 6.46246 5.4068 6.92737 5.98029 6.92737Z" stroke={fill} stroke-width="1.38452" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.6333 10.3885L11.172 6.92725L3.55713 14.5421" stroke={fill} stroke-width="1.38452" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
}

export const ArrowIcon = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.03926 8.64603L11.0339 14.129L9.21484 14.7912L7.21893 9.30832L4.52588 11.3455L5.48768 1.72168L12.4114 8.47561L9.03926 8.64603Z" fill="white" />
    </svg>
  );
}

export const TextIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path d="M7.55729 10.202H3.22929L1.89596 13.5353H0.459961L4.72663 2.86865H6.05996L10.3266 13.5353H8.89063L7.55729 10.202ZM7.02396 8.86865L5.39329 4.79199L3.76263 8.86865H7.02396ZM14.06 8.55865V8.20199H15.3933V13.5353H14.06V13.1787C13.6546 13.4125 13.1948 13.5355 12.7268 13.5353C12.2588 13.5352 11.7991 13.4118 11.3938 13.1778C10.9886 12.9437 10.6521 12.607 10.4181 12.2017C10.1842 11.7964 10.061 11.3366 10.061 10.8687C10.061 10.4007 10.1842 9.9409 10.4181 9.53558C10.6521 9.13026 10.9886 8.79364 11.3938 8.55955C11.7991 8.32546 12.2588 8.20214 12.7268 8.20199C13.1948 8.20183 13.6546 8.32484 14.06 8.55865ZM12.7266 12.202C13.0802 12.202 13.4194 12.0615 13.6694 11.8115C13.9195 11.5614 14.06 11.2223 14.06 10.8687C14.06 10.515 13.9195 10.1759 13.6694 9.92584C13.4194 9.67579 13.0802 9.53532 12.7266 9.53532C12.373 9.53532 12.0339 9.67579 11.7838 9.92584C11.5338 10.1759 11.3933 10.515 11.3933 10.8687C11.3933 11.2223 11.5338 11.5614 11.7838 11.8115C12.0339 12.0615 12.373 12.202 12.7266 12.202Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" transform="translate(0.0600586 0.202148)" />
        </clipPath>
      </defs>
    </svg>

  );
}

export const FolderDownloadIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.33622 4.07015H14.0602C14.237 4.07015 14.4066 4.14039 14.5316 4.26541C14.6567 4.39044 14.7269 4.56001 14.7269 4.73682V14.0702C14.7269 14.247 14.6567 14.4165 14.5316 14.5416C14.4066 14.6666 14.237 14.7368 14.0602 14.7368H2.06022C1.88341 14.7368 1.71384 14.6666 1.58882 14.5416C1.46379 14.4165 1.39355 14.247 1.39355 14.0702V3.40348C1.39355 3.22667 1.46379 3.0571 1.58882 2.93208C1.71384 2.80705 1.88341 2.73682 2.06022 2.73682H7.00289L8.33622 4.07015ZM2.72689 4.07015V13.4035H13.3936V5.40348H7.78422L6.45089 4.07015H2.72689ZM8.72689 9.40348H10.7269L8.06022 12.0702L5.39355 9.40348H7.39355V6.73682H8.72689V9.40348Z" fill="white" />
    </svg>
  );
}

export const TicketIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.9994 0C64.8834 0 65.7313 0.351189 66.3564 0.976311C66.9815 1.60143 67.3327 2.44928 67.3327 3.33333V21.6667C65.1226 21.6667 63.0029 22.5446 61.4401 24.1074C59.8773 25.6702 58.9994 27.7899 58.9994 30C58.9994 32.2101 59.8773 34.3298 61.4401 35.8926C63.0029 37.4554 65.1226 38.3333 67.3327 38.3333V56.6667C67.3327 57.5507 66.9815 58.3986 66.3564 59.0237C65.7313 59.6488 64.8834 60 63.9994 60H3.99935C3.11529 60 2.26745 59.6488 1.64233 59.0237C1.0172 58.3986 0.666016 57.5507 0.666016 56.6667V38.3333C2.87615 38.3333 4.99577 37.4554 6.55857 35.8926C8.12138 34.3298 8.99935 32.2101 8.99935 30C8.99935 27.7899 8.12138 25.6702 6.55857 24.1074C4.99577 22.5446 2.87615 21.6667 0.666016 21.6667V3.33333C0.666016 2.44928 1.0172 1.60143 1.64233 0.976311C2.26745 0.351189 3.11529 0 3.99935 0H63.9994ZM60.666 6.66667H7.33268V16.56L7.85268 16.83C10.1137 18.0635 12.0191 19.8584 13.3852 22.0419C14.7514 24.2254 15.5323 26.7238 15.6527 29.2967L15.666 30C15.6664 32.6941 14.9411 35.3386 13.5662 37.6555C12.1913 39.9724 10.2176 41.8762 7.85268 43.1667L7.33268 43.44V53.3333H60.666V43.4367L60.146 43.17C57.885 41.9365 55.9796 40.1416 54.6135 37.9581C53.2473 35.7746 52.4664 33.2762 52.346 30.7033L52.3327 30C52.3327 24.32 55.4893 19.3767 60.146 16.8333L60.666 16.5567V6.66667ZM47.3327 20V40H20.666V20H47.3327Z" fill="#CDCDCD" />
    </svg>
  );
}

export const TicketBlackIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 68 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M63.9994 0C64.8834 0 65.7313 0.351189 66.3564 0.976311C66.9815 1.60143 67.3327 2.44928 67.3327 3.33333V21.6667C65.1226 21.6667 63.0029 22.5446 61.4401 24.1074C59.8773 25.6702 58.9994 27.7899 58.9994 30C58.9994 32.2101 59.8773 34.3298 61.4401 35.8926C63.0029 37.4554 65.1226 38.3333 67.3327 38.3333V56.6667C67.3327 57.5507 66.9815 58.3986 66.3564 59.0237C65.7313 59.6488 64.8834 60 63.9994 60H3.99935C3.11529 60 2.26745 59.6488 1.64233 59.0237C1.0172 58.3986 0.666016 57.5507 0.666016 56.6667V38.3333C2.87615 38.3333 4.99577 37.4554 6.55857 35.8926C8.12138 34.3298 8.99935 32.2101 8.99935 30C8.99935 27.7899 8.12138 25.6702 6.55857 24.1074C4.99577 22.5446 2.87615 21.6667 0.666016 21.6667V3.33333C0.666016 2.44928 1.0172 1.60143 1.64233 0.976311C2.26745 0.351189 3.11529 0 3.99935 0H63.9994ZM60.666 6.66667H7.33268V16.56L7.85268 16.83C10.1137 18.0635 12.0191 19.8584 13.3852 22.0419C14.7514 24.2254 15.5323 26.7238 15.6527 29.2967L15.666 30C15.6664 32.6941 14.9411 35.3386 13.5662 37.6555C12.1913 39.9724 10.2176 41.8762 7.85268 43.1667L7.33268 43.44V53.3333H60.666V43.4367L60.146 43.17C57.885 41.9365 55.9796 40.1416 54.6135 37.9581C53.2473 35.7746 52.4664 33.2762 52.346 30.7033L52.3327 30C52.3327 24.32 55.4893 19.3767 60.146 16.8333L60.666 16.5567V6.66667ZM47.3327 20V40H20.666V20H47.3327Z" fill="#000000" />
    </svg>
  );
}

export const CouponTicketIcon = () => {
  return (
    <svg
      width="201"
      height="82"
      viewBox="0 0 201 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.484 0C10.2131 0 10 0.229141 10 0.5C10 4.64214 6.64214 8 2.5 8C1.25623 8 0 8.87789 0 10.1217V71.64C0 72.4204 0.719661 73 1.5 73C5.64214 73 9 76.3579 9 80.5C9 81.2803 9.57965 82 10.36 82H198C199.657 82 201 80.6569 201 79V75.8055C201 74.7704 200.183 73.9415 199.24 73.5157C197.33 72.6536 196 70.7321 196 68.5C196 65.6386 198.185 63.2876 200.978 63.0245C200.99 63.0233 201 63.0127 201 63C201 62.9873 200.99 62.9767 200.978 62.9755C198.185 62.7124 196 60.3614 196 57.5C196 54.6386 198.185 52.2876 200.978 52.0245C200.99 52.0233 201 52.0127 201 52C201 51.9873 200.99 51.9767 200.978 51.9755C198.185 51.7124 196 49.3614 196 46.5C196 43.6386 198.185 41.2876 200.978 41.0245C200.99 41.0233 201 41.0127 201 41C201 40.9873 200.99 40.9767 200.978 40.9755C198.185 40.7124 196 38.3614 196 35.5C196 32.6386 198.185 30.2876 200.978 30.0245C200.99 30.0233 201 30.0127 201 30C201 29.9873 200.99 29.9767 200.978 29.9755C198.185 29.7124 196 27.3614 196 24.5C196 21.6386 198.185 19.2876 200.978 19.0245C200.99 19.0233 201 19.0127 201 19C201 18.9873 200.99 18.9767 200.978 18.9755C198.185 18.7124 196 16.3614 196 13.5C196 11.2679 197.33 9.34642 199.24 8.48429C200.183 8.05855 201 7.22956 201 6.19455V3C201 1.34315 199.657 0 198 0H10.484Z"
        fill="#3D3D3D"
      />
    </svg>
  );
}

export const RegistrationCustomFieldIcon = (props) => {
  return (
    <svg {...props} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6663 18.3334H11.9997V16.6667C11.9997 16.0037 11.7363 15.3678 11.2674 14.8989C10.7986 14.4301 10.1627 14.1667 9.49967 14.1667H4.49967C3.83663 14.1667 3.20075 14.4301 2.73191 14.8989C2.26307 15.3678 1.99967 16.0037 1.99967 16.6667V18.3334H0.333008V16.6667C0.333008 15.5616 0.771995 14.5018 1.5534 13.7204C2.3348 12.939 3.39461 12.5 4.49967 12.5H9.49967C10.6047 12.5 11.6645 12.939 12.446 13.7204C13.2274 14.5018 13.6663 15.5616 13.6663 16.6667V18.3334ZM6.99967 10.8334C6.34306 10.8334 5.69288 10.704 5.08626 10.4528C4.47963 10.2015 3.92843 9.8332 3.46414 9.36891C2.99985 8.90462 2.63155 8.35342 2.38028 7.74679C2.129 7.14016 1.99967 6.48998 1.99967 5.83337C1.99967 5.17676 2.129 4.52659 2.38028 3.91996C2.63155 3.31333 2.99985 2.76213 3.46414 2.29784C3.92843 1.83355 4.47963 1.46525 5.08626 1.21398C5.69288 0.962703 6.34306 0.833374 6.99967 0.833374C8.32576 0.833374 9.59753 1.36016 10.5352 2.29784C11.4729 3.23552 11.9997 4.50729 11.9997 5.83337C11.9997 7.15946 11.4729 8.43123 10.5352 9.36891C9.59753 10.3066 8.32576 10.8334 6.99967 10.8334ZM6.99967 9.16671C7.88373 9.16671 8.73158 8.81552 9.3567 8.1904C9.98182 7.56528 10.333 6.71743 10.333 5.83337C10.333 4.94932 9.98182 4.10147 9.3567 3.47635C8.73158 2.85123 7.88373 2.50004 6.99967 2.50004C6.11562 2.50004 5.26777 2.85123 4.64265 3.47635C4.01753 4.10147 3.66634 4.94932 3.66634 5.83337C3.66634 6.71743 4.01753 7.56528 4.64265 8.1904C5.26777 8.81552 6.11562 9.16671 6.99967 9.16671Z"
        fill="black" />
    </svg>
  );
}

export const CalendarPlus = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.91667 11.8334H10.0833C10.7267 11.8334 11.25 11.3102 11.25 10.6667V2.50008C11.25 1.85666 10.7267 1.33341 10.0833 1.33341H8.91667V0.166748H7.75V1.33341H4.25V0.166748H3.08333V1.33341H1.91667C1.27325 1.33341 0.75 1.85666 0.75 2.50008V10.6667C0.75 11.3102 1.27325 11.8334 1.91667 11.8334ZM8.33333 8.33342H6.58333V10.0834H5.41667V8.33342H3.66667V7.16675H5.41667V5.41675H6.58333V7.16675H8.33333V8.33342ZM1.91667 3.08342H10.0833V4.25008H1.91667V3.08342Z"
        fill="#ABABAB"
      />
    </svg>
  );
}

export const SendChatIcon = ({ fillColor, size }) => {
  const fill = fillColor || '#ABABAB';
  const iconSize = size || 21;
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.946082 7.31501C0.424082 7.14101 0.419082 6.86001 0.956082 6.68101L20.0431 0.31901C20.5721 0.14301 20.8751 0.43901 20.7271 0.95701L15.2731 20.043C15.1231 20.572 14.8181 20.59 14.5941 20.088L11.0001 12L17.0001 4.00001L9.00008 10L0.946082 7.31501Z"
        fill={fill}
      />
    </svg>
  );
}

export const LiveStatusIcon = ({ fillColor, size }) => {
  const fill = fillColor || '#77B903';
  const iconSize = size || 13;
  return (
    <svg
      width={iconSize}
      height={iconSize}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6.5"
        cy="6.5"
        r="5.5"
        fill={fill}
        stroke="#353535"
        stroke-width="2"
      />
    </svg>
  );
}

