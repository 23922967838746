import React from 'react';
// styles + types
import styles from './styles.module.scss';
import classnames from 'classnames';
import { IFormValidationErrorTooltipProps } from './types';

const FormPasswordHintTooltip = (
  props: IFormValidationErrorTooltipProps,
) => {
  const { value } = props;

  // validate lower case
  const validateLowerCase = (() => {
    let lowerCaseReg = /[a-z]/;
    return lowerCaseReg.test(value);
  })();

  // validate upper case
  const validateUpperCase = (() => {
    let upperCaseReg = /[A-Z]/;
    return upperCaseReg.test(value);
  })();

  // validate Number case
  const validateNumberCase = (() => {
    let numberCaseReg = /[0-9]/;
    return numberCaseReg.test(value);
  })();

  // validate special characters
  const validateSpecialCase = (() => {
    let specialCaseReg = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialCaseReg.test(value);
  })();

  return (
    <div className={styles.passwordTipContainer}>
      <div className={styles.fieldValidationErrorTooltip}>
        <div className={styles.heading}>Password must have</div>
        <div className={styles.statusContainer}>
          <div className={classnames(
            styles.statusIcon,
            value.length >= 8 && styles.active
          )} />
          <div className={value.length >= 8 ? styles.activeText : styles.inActiveText}>At least 8 characters in length </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={classnames(
            styles.statusIcon,
            validateUpperCase && styles.active
          )} />
          <div className={validateUpperCase ? styles.activeText : styles.inActiveText}>At least 1 upper case letter </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={classnames(
            styles.statusIcon,
            validateLowerCase && styles.active
          )} />
          <div className={validateLowerCase ? styles.activeText : styles.inActiveText}>At least 1 lower case letter </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={classnames(
            styles.statusIcon,
            validateNumberCase && styles.active
          )} />
          <div className={validateNumberCase ? styles.activeText : styles.inActiveText}>At least 1 Number </div>
        </div>
        <div className={styles.statusContainer}>
          <div className={classnames(
            styles.statusIcon,
            validateSpecialCase && styles.active
          )} />
          <div className={validateSpecialCase ? styles.activeText : styles.inActiveText}>At least 1 special character </div>
        </div>
      </div>
      <div className={styles.fieldValidationErrorTooltipTip}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20"><path d="M24 18h-24l12-12z" fill="#ffffff" /></svg>
      </div>
    </div>
  );
};

export default FormPasswordHintTooltip;
